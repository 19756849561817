import * as React from 'react';
import * as moment from 'moment';

import * as styles from './ReportPage.scss';

import {
    OpacityTransition,
    ResponsiveLayout,
    CentralContent,
    LabeledInput,
    InputType,
    Button_redesign as Button,
} from 'sber-marketing-ui';
import { AdminDashboardPage } from '../common/AdminDashboardPage';

export interface ReportPageProps {
    onButtonClick: () => Promise<void>;
    start: moment.Moment;
    end: moment.Moment;
    showErrors: boolean;
    onStartDateChange: (start: moment.Moment) => void;
    onEndDateChange: (end: moment.Moment) => void;
}

export const ReportPage = ({
    onButtonClick,
    start,
    end,
    showErrors,
    onStartDateChange,
    onEndDateChange,
}: ReportPageProps): JSX.Element => {
    return (
        <AdminDashboardPage>
            <OpacityTransition start={true}>
                <ResponsiveLayout>
                    <CentralContent>
                        <div className={styles.root}>
                            <div className={styles.title}>Отчет об использовании системы</div>
                            <div className={styles.datepickersWrapper}>
                                <div className={styles.datepickerWrapper}>
                                    <LabeledInput
                                        title={'Начало периода'}
                                        type={InputType.DatePicker}
                                        value={start}
                                        onValueChange={onStartDateChange}
                                        errorMessage={'Необходимо выбрать дату начала'}
                                        displayError={!start && showErrors}
                                    />
                                </div>
                                <div className={styles.datepickerWrapper}>
                                    <LabeledInput
                                        title={'Окончание периода'}
                                        type={InputType.DatePicker}
                                        value={end}
                                        onValueChange={onEndDateChange}
                                        errorMessage={'Необходимо выбрать дату окончания'}
                                        displayError={!end && showErrors}
                                    />
                                </div>
                            </div>
                            <div className={styles.buttonWrapper}>
                                <Button onClick={onButtonClick}>Загрузить</Button>
                            </div>
                        </div>
                    </CentralContent>
                </ResponsiveLayout>
            </OpacityTransition>
        </AdminDashboardPage>
    );
};
