import { axios } from '../../lib/axios';

import { UserResponseParams, CreateUserParams, UserParams } from 'sber-marketing-types/backend';

const BASE_URL = '/api/user';

interface GetUserParams {
    ids: number[];
    organizationIds: string[];
    roleIds: number[];
}

export class UserApi {
    public static async getUser(): Promise<UserResponseParams> {
        const res = await axios.get(`${BASE_URL}`);

        return res.data;
    }

    public static async getUserList(params: Partial<GetUserParams>): Promise<UserParams[]> {
        const res = await axios.get(`${BASE_URL}/list`, { params });

        return res.data;
    }

    public static async createUser(user: CreateUserParams): Promise<UserParams> {
        const res = await axios.post(`${BASE_URL}`, { ...user });

        return res.data.user;
    }

    public static async updateUser(user: UserParams): Promise<UserParams> {
        const res = await axios.put(`${BASE_URL}/${user.id}`, { ...user });

        return res.data.user;
    }

    public static async setDepartmentRole(id: number, departmentId: string, role: number): Promise<UserParams> {
        const res = await axios.post(`/api/user/${id}/department/${departmentId}/role/${role}`);

        return res.data;
    }
}
