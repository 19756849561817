import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { combineReducers } from 'redux';
import { includes, without, first, isNil, get, isArray } from 'lodash';
import * as moment from 'moment';
import { EventsRecordsListQuery, EventType, SortOrder, EmbedEntities } from 'sber-marketing-types/system-admin';

import { JournalFiltersState } from './types';
import {
    editDateFrom,
    editDateTill,
    editPageLength,
    editSort,
    addAuthorId,
    removeAuthorId,
    addAuthorRole,
    removeAuthorRole,
    addAuthorEmail,
    removeAuthorEmail,
    addAuthorOrganizationId,
    removeAuthorOrganizationId,
    addEventType,
    removeEventType,
    addEmbed,
    removeEmbed,
} from './actions';
import { AVAILABLE_PAGE_LENGTHS } from './constants';
import { routeJournalPage, RoutePayload } from '../routing';
import { replaceReducer } from '../commonReducers';

const addToArrayState = <T>(state: T[], payload: T) =>
    includes(state, payload) ? state : state.concat([payload]).sort();

const removeFromArrayState = <T>(state: T[], payload: T) =>
    includes(state, payload) ? without(state, payload).sort() : state;

const fromQueryToArray = <T>(value: T | T[]): T[] => (isArray(value) ? value : [value]);

const dateFrom = reducerWithInitialState<moment.Moment | null>(null)
    .case(editDateFrom, replaceReducer)
    .case(routeJournalPage, (state: moment.Moment | null, payload: RoutePayload<EventsRecordsListQuery>) =>
        isNil(get(payload, 'query.dateFrom')) ? null : moment(get(payload, 'query.dateFrom')),
    );

const dateTill = reducerWithInitialState<moment.Moment | null>(null)
    .case(editDateTill, replaceReducer)
    .case(routeJournalPage, (state: moment.Moment | null, payload: RoutePayload<EventsRecordsListQuery>) =>
        isNil(get(payload, 'query.dateTill')) ? null : moment(get(payload, 'query.dateTill')),
    );

const pageLength = reducerWithInitialState<number>(first(AVAILABLE_PAGE_LENGTHS))
    .case(editPageLength, replaceReducer)
    .case(routeJournalPage, (state: number, payload: RoutePayload<EventsRecordsListQuery>) =>
        isNil(get(payload, 'query.pageLength')) ? first(AVAILABLE_PAGE_LENGTHS) : get(payload, 'query.pageLength'),
    );

const sort = reducerWithInitialState<SortOrder | null>(null)
    .case(editSort, replaceReducer)
    .case(
        routeJournalPage,
        (state: SortOrder | null, payload: RoutePayload<EventsRecordsListQuery>) => get(payload, 'query.sort') || null,
    );

const authorIds = reducerWithInitialState<number[]>([])
    .case(addAuthorId, addToArrayState)
    .case(removeAuthorId, removeFromArrayState)
    .case(routeJournalPage, (state: number[], payload: RoutePayload<EventsRecordsListQuery>) =>
        isNil(get(payload, 'query.authorIds')) ? [] : fromQueryToArray<number>(get(payload, 'query.authorIds')),
    );

const authorRoles = reducerWithInitialState<string[]>([])
    .case(addAuthorRole, addToArrayState)
    .case(removeAuthorRole, removeFromArrayState)
    .case(routeJournalPage, (state: string[], payload: RoutePayload<EventsRecordsListQuery>) =>
        isNil(get(payload, 'query.authorRoles')) ? [] : fromQueryToArray<string>(get(payload, 'query.authorRoles')),
    );

const authorEmails = reducerWithInitialState<string[]>([])
    .case(addAuthorEmail, addToArrayState)
    .case(removeAuthorEmail, removeFromArrayState)
    .case(routeJournalPage, (state: string[], payload: RoutePayload<EventsRecordsListQuery>) =>
        isNil(get(payload, 'query.authorEmails')) ? [] : fromQueryToArray<string>(get(payload, 'query.authorEmails')),
    );

const authorOrganizationIds = reducerWithInitialState<string[]>([])
    .case(addAuthorOrganizationId, addToArrayState)
    .case(removeAuthorOrganizationId, removeFromArrayState)
    .case(routeJournalPage, (state: string[], payload: RoutePayload<EventsRecordsListQuery>) =>
        isNil(get(payload, 'query.authorOrganizationIds'))
            ? []
            : fromQueryToArray<string>(get(payload, 'query.authorOrganizationIds')),
    );

const eventTypes = reducerWithInitialState<EventType[]>([])
    .case(addEventType, addToArrayState)
    .case(removeEventType, removeFromArrayState)
    .case(routeJournalPage, (state: string[], payload: RoutePayload<EventsRecordsListQuery>) =>
        isNil(get(payload, 'query.eventTypes')) ? [] : fromQueryToArray<EventType>(get(payload, 'query.eventTypes')),
    );

const embed = reducerWithInitialState<EmbedEntities[]>([EmbedEntities.ORGANIZATIONS, EmbedEntities.USERS])
    .case(addEmbed, addToArrayState)
    .case(removeEmbed, removeFromArrayState)
    .case(routeJournalPage, (state: string[], payload: RoutePayload<EventsRecordsListQuery>) =>
        isNil(get(payload, 'query.embed'))
            ? [EmbedEntities.ORGANIZATIONS, EmbedEntities.USERS]
            : fromQueryToArray<EmbedEntities>(get(payload, 'query.embed')),
    );

export const journalFilters = combineReducers<JournalFiltersState>({
    dateFrom,
    dateTill,
    pageLength,
    sort,
    authorIds,
    authorRoles,
    authorEmails,
    authorOrganizationIds,
    eventTypes,
    embed,
});
