import { ComponentType, createElement, Component as ReactComponent } from 'react';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { omit } from 'lodash';

import { State, ItemLoadingStatus } from '../../../store/types';
import { getLoadingStatus, loadFilterData, getError, getErrorCode } from '../../../store/filterData';

/** Mapped Redux Store state */
interface MappedState {
    /** Loading error HTTP status code */
    errorCode: number | null;
    /** Loading error */
    error: Error | null;
    /** Loading status */
    status: ItemLoadingStatus;
}

/** Mapped Redux Store actions */
interface MappedActions {
    /** Load filter data action dispatching */
    loadFilterData(): void;
}

type Props<P extends {}> = P & MappedState & MappedActions;

const mapStateToProps = (state: State): MappedState => ({
    status: getLoadingStatus(state),
    error: getError(state),
    errorCode: getErrorCode(state),
});

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    loadFilterData: () => dispatch(loadFilterData(null)),
});

/** Additional properties for filter data loading */
export interface WithFilterDataProps {
    /** Filter data loading in progress flag */
    isLoading: boolean;
    /** Error was thrown flag */
    hasError: boolean;
    /** Loading error HTTP status code */
    errorCode: number | null;
    /** Loading error */
    error: Error | null;
    /** Load filter data action dispatching */
    loadFilterData(): void;
}

/**
 * High Order Component (HOC) which adds automatic filter data loading if it's needed and when component did mount.
 * This HOC forwards filter data loading state and action to source component properties.
 */
export function withFilterData<P extends {} = {}>(Component: ComponentType<P & WithFilterDataProps>): ComponentType<P> {
    const withStore = connect<MappedState, MappedActions, P, State>(mapStateToProps, mapDispatchToProps);
    const Wrapped: ComponentType<Props<P>> = class extends ReactComponent<Props<P>> {
        public componentDidMount() {
            const { status, loadFilterData } = this.props;
            if (status === ItemLoadingStatus.NOT_LOADED) {
                loadFilterData();
            }
        }
        public render() {
            return createElement(Component, this.createPropsToRender(), this.props.children);
        }
        private createPropsToRender(): P & WithFilterDataProps {
            const { status } = this.props;
            const isLoading: boolean = status !== ItemLoadingStatus.LOADED && status !== ItemLoadingStatus.ERROR;
            const hasError: boolean = status === ItemLoadingStatus.ERROR;
            return {
                ...(<any>omit(this.props, ['children', 'status'])),
                isLoading,
                hasError,
            };
        }
    };
    return withStore(Wrapped);
}
