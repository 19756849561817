import { SuggestHOC, SuggestHOCInstance, SuggestHOCParams } from 'sber-marketing-ui';
import { includes } from 'lodash';

import { MultipleSelectSuggestEnhancedProps, MultipleSelectSuggestItem } from './types';
import { MultipleSelectSuggestTemplate } from './MultipleSelectSuggestTemplate';

const params: SuggestHOCParams<MultipleSelectSuggestEnhancedProps, MultipleSelectSuggestItem> = {
    getItemsToFilter: ({ rest }) => rest,
    getInitialValue: () => ({ value: '', name: '' }),
    filter: ({ name: valueName }, { name: itemName }) =>
        includes(String(itemName).toLowerCase().trim(), String(valueName).toLowerCase().trim()),
    equals: ({ name: valueName }, { name: itemName }) =>
        String(itemName).toLowerCase().trim() === String(valueName).toLowerCase().trim(),
    disableInputAutoFill: true,
};

const enhancer: SuggestHOCInstance<MultipleSelectSuggestEnhancedProps, MultipleSelectSuggestItem> = SuggestHOC<
    MultipleSelectSuggestEnhancedProps,
    MultipleSelectSuggestItem
>(params);

export const MultipleSelectSuggestEnhanced = enhancer(MultipleSelectSuggestTemplate);
