// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-journal-JournalPage-_JournalPage__root{padding:8px 38px 32px}.src-client-layers-view-journal-JournalPage-_JournalPage__cardWrapper{margin:32px}.src-client-layers-view-journal-JournalPage-_JournalPage__title{margin-top:32px;margin-bottom:20px;margin-left:56px;user-select:none;letter-spacing:0.5px;pointer-events:none;color:#262626;font-family:'Open Sans';font-size:14px;font-weight:400}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/journal/JournalPage/JournalPage.scss"],"names":[],"mappings":"AAAA,+DACI,qBAAsB,CACzB,sEAGG,WAAY,CACf,gEAGG,eAAgB,CAChB,kBAAmB,CACnB,gBAAiB,CAEjB,gBAAiB,CACjB,oBAAqB,CACrB,mBAAoB,CAEpB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB","sourcesContent":[".root {\n    padding: 8px 38px 32px;\n}\n\n.cardWrapper {\n    margin: 32px;\n}\n\n.title {\n    margin-top: 32px;\n    margin-bottom: 20px;\n    margin-left: 56px;\n\n    user-select: none;\n    letter-spacing: 0.5px;\n    pointer-events: none;\n\n    color: #262626;\n\n    font-family: 'Open Sans';\n    font-size: 14px;\n    font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-journal-JournalPage-_JournalPage__root",
	"cardWrapper": "src-client-layers-view-journal-JournalPage-_JournalPage__cardWrapper",
	"title": "src-client-layers-view-journal-JournalPage-_JournalPage__title"
};
export default ___CSS_LOADER_EXPORT___;
