import * as React from 'react';

import { Props as SkeletonProps, Skeleton } from './Skeleton';

type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;
type Props = Omit<SkeletonProps, 'isReady' | 'children'>;

export function StaticSkeleton(props: Props): JSX.Element {
    return (
        <Skeleton {...props} isReady={false}>
            {null}
        </Skeleton>
    );
}
