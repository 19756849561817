import { createSelector } from 'reselect';

import { State } from '../types';
import { RoutingState, CurrentPage } from './types';

const getRoutingState = (state: State): RoutingState => state.routing;

/** Returns current page type */
export const getCurrentPage = createSelector(getRoutingState, ({ currentPage }): CurrentPage | null => currentPage);

/** Returns authorization checking state */
export const getIsAuthChecking = createSelector(getRoutingState, ({ isAuthChecking }): boolean => isAuthChecking);

/** Returns expired session state */
export const getIsSessionExpired = createSelector(getRoutingState, ({ isSessionExpired }): boolean => isSessionExpired);

/** Returns session watching enabled state */
export const getIsSessionWatching = createSelector(
    getRoutingState,
    ({ isSessionWatching }): boolean => isSessionWatching,
);
