import { createSelector } from 'reselect';
import { Moment, isMoment } from 'moment';
import { SortOrder, EventsRecordsListQuery, EventType } from 'sber-marketing-types/system-admin';
import { isEmpty, isNil } from 'lodash';

import { State } from '../types';
import { JournalFiltersState } from './types';
import { getFullState, getNumberId } from '../utils';
import { JournalApi } from '../../api';

export const getJournalFiltersState = createSelector(
    getFullState,
    ({ journalFilters }: State): JournalFiltersState => journalFilters,
);

export const getDateFrom = createSelector(getJournalFiltersState, ({ dateFrom }): Moment | null => dateFrom);

export const getDateTill = createSelector(getJournalFiltersState, ({ dateTill }): Moment | null => dateTill);

export const getPageLength = createSelector(getJournalFiltersState, ({ pageLength }): number => pageLength);

export const getSort = createSelector(getJournalFiltersState, ({ sort }): SortOrder => sort);

export const getAuthorIds = createSelector(getJournalFiltersState, ({ authorIds }): number[] => authorIds);

export const getAuthorRoles = createSelector(getJournalFiltersState, ({ authorRoles }): string[] => authorRoles);

export const getAuthorEmails = createSelector(getJournalFiltersState, ({ authorEmails }): string[] => authorEmails);

export const getAuthorOrganizationIds = createSelector(
    getJournalFiltersState,
    ({ authorOrganizationIds }): string[] => authorOrganizationIds,
);

export const getEventTypes = createSelector(getJournalFiltersState, ({ eventTypes }): EventType[] => eventTypes);

export const getEventsRecordsListQueryWithoutPagination = createSelector(
    getJournalFiltersState,
    (filters: JournalFiltersState): EventsRecordsListQuery => {
        const query: EventsRecordsListQuery = {};

        if (isMoment(filters.dateFrom)) {
            query.dateFrom = filters.dateFrom.toDate().toISOString();
        }
        if (isMoment(filters.dateTill)) {
            query.dateTill = filters.dateTill.toDate().toISOString();
        }
        if (!isNil(filters.sort)) {
            query.sort = filters.sort;
        }
        if (!isEmpty(filters.authorIds)) {
            query.authorIds = filters.authorIds;
        }
        if (!isEmpty(filters.authorRoles)) {
            query.authorRoles = filters.authorRoles;
        }
        if (!isEmpty(filters.authorEmails)) {
            query.authorEmails = filters.authorEmails;
        }
        if (!isEmpty(filters.authorOrganizationIds)) {
            query.authorOrganizationIds = filters.authorOrganizationIds;
        }
        if (!isEmpty(filters.eventTypes)) {
            query.eventTypes = filters.eventTypes;
        }
        if (!isEmpty(filters.embed)) {
            query.embed = filters.embed;
        }

        return query;
    },
);

export const getEventsRecordsListQuery = createSelector(
    [getEventsRecordsListQueryWithoutPagination, getNumberId, getPageLength],
    (query: EventsRecordsListQuery, page: number, pageLength: number): EventsRecordsListQuery => {
        query.page = page;
        query.pageLength = pageLength;
        return query;
    },
);

export const getEventsRecordsCsvDownloadUrl = createSelector(
    getEventsRecordsListQueryWithoutPagination,
    (query: EventsRecordsListQuery): string => JournalApi.getInstance().getListCSVDownloadLink(query),
);
