import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { OrganizationParams } from 'sber-marketing-types/admin';
import { OrganizationsPageState, UpdateOrganizationParams } from './types';
import {
    loadOrganizationsPage,
    setLoadingStatus,
    loadCurrentOrganization,
    updateCurrentOrganization,
    resetCurrentOrganization,
    setCurrentOrganizationId,
    addOrganization,
    updateOrganization,
    removeOrganization,
} from './actions';

export const initialState: OrganizationsPageState = {
    organizations: [],
    currentOrganization: {} as OrganizationParams,
    currentOrganizationId: null,
    isFormOpen: false,
    isLoading: false,
};

export class Reducer {
    public static loadOrganizationsPage(
        state: OrganizationsPageState,
        payload: OrganizationsPageState,
    ): OrganizationsPageState {
        return { ...state, ...payload };
    }

    public static setLoadingStatus(state: OrganizationsPageState, payload: boolean): OrganizationsPageState {
        return { ...state, isLoading: payload };
    }

    public static loadCurrentOrganization(
        state: OrganizationsPageState,
        payload: OrganizationParams,
    ): OrganizationsPageState {
        return { ...state, currentOrganization: payload };
    }

    public static updateCurrentOrganization(
        state: OrganizationsPageState,
        payload: UpdateOrganizationParams,
    ): OrganizationsPageState {
        const currentOrganization = { ...state.currentOrganization, ...payload };

        return { ...state, currentOrganization };
    }

    public static resetCurrentOrganization(state: OrganizationsPageState): OrganizationsPageState {
        return {
            ...state,
            isFormOpen: false,
            currentOrganization: {} as OrganizationParams,
            currentOrganizationId: null,
        };
    }

    public static setCurrentOrganizationId(state: OrganizationsPageState, payload: string): OrganizationsPageState {
        return { ...state, isFormOpen: true, currentOrganizationId: payload };
    }

    public static addOrganization(state: OrganizationsPageState, payload: OrganizationParams): OrganizationsPageState {
        const organizations = [...state.organizations, payload];

        return { ...state, organizations };
    }

    public static updateOrganization(
        state: OrganizationsPageState,
        payload: UpdateOrganizationParams,
    ): OrganizationsPageState {
        const { currentOrganization, currentOrganizationId } = state;
        const { id } = payload;

        const organizations = state.organizations.map((item) => (item.id == id ? { ...item, ...payload } : item));

        return {
            ...state,
            organizations,
            currentOrganization:
                currentOrganizationId == id ? { ...currentOrganization, ...payload } : currentOrganization,
        };
    }

    public static removeOrganization(state: OrganizationsPageState, id: string): OrganizationsPageState {
        const organizations = state.organizations.filter((organization) => organization.id != id);

        return { ...state, organizations };
    }
}

export const organizationsReducer = reducerWithInitialState(initialState)
    .case(loadOrganizationsPage, Reducer.loadOrganizationsPage)
    .case(setLoadingStatus, Reducer.setLoadingStatus)
    .case(loadCurrentOrganization, Reducer.loadCurrentOrganization)
    .case(updateCurrentOrganization, Reducer.updateCurrentOrganization)
    .case(resetCurrentOrganization, Reducer.resetCurrentOrganization)
    .case(setCurrentOrganizationId, Reducer.setCurrentOrganizationId)
    .case(addOrganization, Reducer.addOrganization)
    .case(updateOrganization, Reducer.updateOrganization)
    .case(removeOrganization, Reducer.removeOrganization);
