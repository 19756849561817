import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { get } from 'lodash';

import { State } from './types';

export const queryToPayloadMiddleware: Middleware =
    (store: MiddlewareAPI<Partial<State>>) => (next: Dispatch<State>) => (action: any) => {
        if (get(action, 'meta.location.current.query') && !get(action, 'payload.query')) {
            action.payload.query = action.meta.location.current.query;
        }
        return next(action);
    };
