import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { EventsRecordsListQuery } from 'sber-marketing-types/system-admin';

import { State } from '../../../store/types';
import {
    JournalClearingStatus,
    JournalClearState,
    clearJournal,
    endJournalClear,
    getJournalClearState,
} from '../../../store/journalClear';
import { loadJournalPage, DEFAULT_PAGE } from '../../../store/journal';
import { routeJournalPage, RoutePayload } from '../../../store/routing';
import { MappedActions, ClearJournalModalTemplateProps } from './types';
import { ClearJournalModalTemplate } from './ClearJournalModalTemplate';

const getMessage = (status: JournalClearingStatus): React.ReactNode =>
    status === JournalClearingStatus.WAIT_FOR_SUMBIT ? (
        <React.Fragment>
            Это действие сотрет <em>ВСЕ ЗАПИСИ</em> из журнала. Продолжить?
        </React.Fragment>
    ) : (
        'Журнал успешно очищен'
    );

const mapStateToProps = (state: State): JournalClearState => getJournalClearState(state);

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    clearJournal: () => dispatch(clearJournal(null)),
    endJournalClear: () => dispatch(endJournalClear()),
    routeJournalPage: (payload: RoutePayload<EventsRecordsListQuery>) => dispatch(routeJournalPage(payload)),
    loadJournalPage: (pageNumber: number) => dispatch(loadJournalPage(pageNumber)),
});

const getOnFirstButtonClick = (
    status: JournalClearingStatus,
    { clearJournal, endJournalClear, routeJournalPage, loadJournalPage }: MappedActions,
): (() => void) => {
    if (status === JournalClearingStatus.WAIT_FOR_SUMBIT || status === JournalClearingStatus.ERROR) {
        return clearJournal;
    }
    return () => {
        endJournalClear();
        routeJournalPage({});
        loadJournalPage(DEFAULT_PAGE);
    };
};

const mergeProps = ({ status, error }: JournalClearState, actions: MappedActions): ClearJournalModalTemplateProps => ({
    error,
    onFirstButtonClick: getOnFirstButtonClick(status, actions),
    onSecondButtonClick: actions.endJournalClear,
    showError: status === JournalClearingStatus.ERROR,
    showPreloader: status === JournalClearingStatus.IN_PROGRESS,
    showMessage: status === JournalClearingStatus.WAIT_FOR_SUMBIT || status === JournalClearingStatus.CLEARED,
    message: getMessage(status),
    showMultipleButtons: status !== JournalClearingStatus.CLEARED && status !== JournalClearingStatus.IN_PROGRESS,
    showOneButton: status === JournalClearingStatus.CLEARED,
    firstButtonLabel: status === JournalClearingStatus.WAIT_FOR_SUMBIT ? 'Очистить' : 'Попробовать снова',
    secondButtonLabel:
        status === JournalClearingStatus.WAIT_FOR_SUMBIT || status === JournalClearingStatus.ERROR
            ? 'Отмена'
            : 'Закрыть',
});

const withStore = connect(mapStateToProps, mapDispatchToProps, mergeProps);

/** "ClearJournalModal" store component */
export const ClearJournalModalStore: React.ComponentType = withStore(ClearJournalModalTemplate);
