/** Type of current page */
export const enum CurrentPage {
    /** Organizations page */
    ORGANIZATIONS = 'organizations',
    /** Configuration page */
    CONFIGURATION = 'configuration',
    /** Journal page */
    JOURNAL = 'journal',
    /** Authorization error page */
    AUTHORIZATION_ERROR = 'authorization_error',
    REPORT = 'report',
    ROLES = 'roles',
}

/** Routing state */
export interface RoutingState {
    /** Is authorization checking in progress */
    isAuthChecking: boolean;
    /** Is user's session was expired */
    isSessionExpired: boolean;
    /** Is user's session watch is enabled */
    isSessionWatching: boolean;
    /** Current page */
    currentPage: CurrentPage | null;
}

/** Common route payload signatyre */
export interface RoutePayload<Q extends {} = {}> {
    /** Query */
    query?: Q;
}

/** "ROUTE_AUTHORIZATION_ERROR_PAGE" action parsed query signature */
export interface RouteAuthorizationErrorPageQuery {
    type?: 'not-authorized' | 'no-access' | 'other';
}

/** "ROUTE_AUTHORIZATION_ERROR_PAGE" action payload */
export type RouteAuthorizationErrorPagePayload = RoutePayload<RouteAuthorizationErrorPageQuery>;
