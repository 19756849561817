import * as React from 'react';
import * as classnames from 'classnames';
import autobind from 'autobind-decorator';
import { isNil } from 'lodash';

import * as styles from './SimpleSuggestItem.scss';

/** Simple suggest */
export interface SimpleSuggestItemProps<I> {
    /** Additional CSS class */
    className?: string;
    /** Item to render */
    item: I;
    /** Item render property */
    renderItem(item: I): React.ReactNode;
    /** Suggest item select handler */
    onSuggestItemSelect(item: I): void | Promise<void>;
}

/** Simple suggest item component */
export class SimpleSuggestItem<I> extends React.Component<SimpleSuggestItemProps<I>> {
    /** @override */
    public render(): JSX.Element {
        return (
            <div className={this.rootClassNames()} onClick={this.onClick}>
                {this.renderItem()}
            </div>
        );
    }
    /** Render item */
    protected renderItem() {
        const { item, renderItem } = this.props;
        return renderItem(item);
    }
    /** Returns complete root CSS classes string */
    protected rootClassNames(): string {
        return classnames(styles.root, this.props.className);
    }
    /** Internal click event handler */
    @autobind
    protected onClick(): void {
        const { item, onSuggestItemSelect } = this.props;
        if (!isNil(onSuggestItemSelect)) {
            onSuggestItemSelect(item);
        }
    }
}
