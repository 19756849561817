// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-common-SimpleSuggestItem-_SimpleSuggestItem__root{display:block;box-sizing:border-box;width:100%;min-height:27px;padding:5px 16px;user-select:none;color:#262626;font-size:11px;line-height:1.82}.src-client-layers-view-common-SimpleSuggestItem-_SimpleSuggestItem__root:hover{cursor:pointer;background-color:rgba(219,219,219,0.3)}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/common/SimpleSuggestItem/SimpleSuggestItem.scss"],"names":[],"mappings":"AAAA,0EACI,aAAc,CAEd,qBAAsB,CACtB,UAAW,CACX,eAAgB,CAChB,gBAAiB,CAEjB,gBAAiB,CAEjB,aAAc,CAEd,cAAe,CACf,gBAAiB,CACpB,gFAGG,cAAe,CAEf,sCAAuC","sourcesContent":[".root {\n    display: block;\n\n    box-sizing: border-box;\n    width: 100%;\n    min-height: 27px;\n    padding: 5px 16px;\n\n    user-select: none;\n\n    color: #262626;\n\n    font-size: 11px;\n    line-height: 1.82;\n}\n\n.root:hover {\n    cursor: pointer;\n\n    background-color: rgba(219,219,219,0.3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-common-SimpleSuggestItem-_SimpleSuggestItem__root"
};
export default ___CSS_LOADER_EXPORT___;
