import { createSelector } from 'reselect';

import { UserParams } from 'sber-marketing-types/admin';
import { State as State } from '../types';
import { UsersState } from './types';

export const getOrganizationsPageState = (state: State): UsersState => state.userReducer;

export const getBusinessAdmin = createSelector(
    getOrganizationsPageState,
    (state: State, id: string): string => id,
    (state: UsersState, id: string): UserParams => {
        return (
            state.users.find((item) => !!item.roles.find((role) => role.id == 3) && item.organizationId == id) || null
        );
    },
);

export const getLoadingStatus = createSelector(getOrganizationsPageState, (state: UsersState): boolean => {
    return state.isLoading;
});
