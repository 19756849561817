import * as React from 'react';
import { connect, Dispatch } from 'react-redux';
import autobind from 'autobind-decorator';

import { OrganizationParams } from 'sber-marketing-types/admin';
import { OrganizationsPageState } from '../../../store/organizations/types';

import { OrganizationsPage } from './OrganizationsPage';
import { State as State } from '../../../store/types';
import {
    loadOrganizationsPage,
    setLoadingStatus,
    setCurrentOrganizationId,
    resetCurrentOrganization,
    removeOrganization,
} from '../../../store/organizations/actions';
import { getOrganizationsPageState } from '../../../store/organizations/selectors';
import { OrganizationApi } from '../../../api';

export interface Props extends MapProps, DispatchProps {}

interface MapProps {
    organizations?: OrganizationParams[];
    isLoading?: boolean;
    isFormOpen?: boolean;
    currentId?: string;
}

interface DispatchProps {
    loadOrganizationsPage?: (pageData: OrganizationsPageState) => void;
    setLoadingStatus?: (status: boolean) => void;
    setCurrentOrganizationId?: (id: string) => void;
    resetCurrentOrganization?: () => void;
    removeOrganization?: (id: string) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class OrganizationsPageContainer extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);

        this.props.setLoadingStatus(true);
    }

    public async componentDidMount() {
        const organizations = await OrganizationApi.getList();

        await this.props.loadOrganizationsPage({
            organizations,
        });

        this.props.setLoadingStatus(false);
    }

    public render(): JSX.Element {
        const { organizations, isLoading, isFormOpen, currentId } = this.props;

        return React.createElement(OrganizationsPage, {
            organizations,
            isLoading,
            isFormOpen,
            currentId,
            onNewOrganizationClick: this.onNewOrganizationClick,
            onCardClick: this.onCardClick,
            onDeleteClick: this.onDeleteClick,
            onCloseFormClick: this.onCloseFormClick,
        });
    }

    @autobind
    protected onNewOrganizationClick() {
        this.props.setCurrentOrganizationId(null);
    }

    @autobind
    protected onCardClick(id: string) {
        this.props.setCurrentOrganizationId(id);
    }

    @autobind
    protected onCloseFormClick() {
        this.props.resetCurrentOrganization();
    }

    @autobind
    protected async onDeleteClick(id: string) {
        this.props.removeOrganization(id);

        await OrganizationApi.deleteOrganization(id);
    }
}

function mapStateToProps(state: State): MapProps {
    const { organizations, isLoading, isFormOpen, currentOrganizationId } = getOrganizationsPageState(state);

    return {
        organizations,
        isLoading,
        isFormOpen,
        currentId: currentOrganizationId,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return {
        loadOrganizationsPage: (pageData: OrganizationsPageState) => dispatch(loadOrganizationsPage(pageData)),
        setLoadingStatus: (status: boolean) => dispatch(setLoadingStatus(status)),
        setCurrentOrganizationId: (id: string) => dispatch(setCurrentOrganizationId(id)),
        resetCurrentOrganization: () => dispatch(resetCurrentOrganization()),
        removeOrganization: (id: string) => dispatch(removeOrganization(id)),
    };
}
