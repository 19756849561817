import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../../store/types';
import { SessionControlCommonProps, SessionControlStoreMappedActions, SessionControlStoreMappedState } from './types';
import { SessionControlTemplate } from './SessionControlTemplate';
import { getIsSessionExpired, logout } from '../../../store/routing';

const mapStateToProps = (state: State): SessionControlStoreMappedState => ({
    isSessionExpired: getIsSessionExpired(state),
});

const mapDispatchToProps = (dispatch: Dispatch<State>): SessionControlStoreMappedActions => ({
    logout: () => dispatch(logout()),
});

const withStore = connect<
    SessionControlStoreMappedState,
    SessionControlStoreMappedActions,
    SessionControlCommonProps,
    State
>(mapStateToProps, mapDispatchToProps);

/** "SessionControl" store component */
export const SessionControlStore: ComponentType<SessionControlCommonProps> = withStore(SessionControlTemplate);
