import { actionCreatorFactory } from 'typescript-fsa';

import { UserParams } from 'sber-marketing-types/admin';

const actionCreator = actionCreatorFactory('USER');

const loadUsers = actionCreator<UserParams[]>('LOAD_USERS');
const addUser = actionCreator<UserParams>('ADD_USER');
const updateUser = actionCreator<UserParams>('UPDATE_USER');
const setLoadingStatus = actionCreator<boolean>('SET_LOADING_STATUS');

export { loadUsers, addUser, updateUser, setLoadingStatus };
