import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect, Dispatch } from 'react-redux';
import * as lodash from 'lodash';

import { RolesPage } from './RolesPage';
import { resetPageState } from '../../store/rolesPage/actions';
import { Loader } from './Table/modules';

export interface Props extends DispatchProps {}

interface DispatchProps {
    resetPageState: () => void;
}

interface State {
    preloader: boolean;
}

@(connect(null, mapDispatchToProps) as any)
export class RolesPageContainer extends React.PureComponent<Props, State> {
    private loader: Loader;

    constructor(props: Props) {
        super(props);

        this.state = {
            preloader: true,
        };

        this.loader = Loader.getInstance();
    }

    public async componentDidMount() {
        const organisationId = this.getOrganisationId();

        await this.loader.init(organisationId);

        this.setState({ preloader: false });
    }

    public componentWillUnmount() {
        this.props.resetPageState();
    }

    public render(): JSX.Element {
        const { preloader } = this.state;

        return React.createElement(RolesPage, {
            preloader,
        });
    }

    private getOrganisationId(): string {
        const { pathname } = window.location;

        return lodash.last(pathname.split('/'));
    }
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            resetPageState,
        },
        dispatch,
    );
}
