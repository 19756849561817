// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-common-ErrorHandleForm-_ErrorHandleForm__root{font-family:'Roboto'}.src-client-layers-view-common-ErrorHandleForm-_ErrorHandleForm__title{margin-bottom:8px;font-size:20px}.src-client-layers-view-common-ErrorHandleForm-_ErrorHandleForm__description{margin-bottom:8px}.src-client-layers-view-common-ErrorHandleForm-_ErrorHandleForm__name{font-size:14px}.src-client-layers-view-common-ErrorHandleForm-_ErrorHandleForm__message{margin-left:20px;font-size:8px}.src-client-layers-view-common-ErrorHandleForm-_ErrorHandleForm__retryButton{display:inline-block;width:100%;height:26px;margin-top:8px;text-decoration:none;font-size:14px;font-weight:600}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/common/ErrorHandleForm/ErrorHandleForm.scss"],"names":[],"mappings":"AAAA,sEACI,oBAAqB,CACxB,uEAGG,iBAAkB,CAElB,cAAe,CAClB,6EAGG,iBAAkB,CACrB,sEAGG,cAAe,CAClB,yEAGG,gBAAiB,CAEjB,aAAc,CACjB,6EAGG,oBAAqB,CAErB,UAAW,CACX,WAAY,CACZ,cAAe,CAEf,oBAAqB,CAErB,cAAe,CACf,eAAgB","sourcesContent":[".root {\n    font-family: 'Roboto';\n}\n\n.title {\n    margin-bottom: 8px;\n\n    font-size: 20px;\n}\n\n.description {\n    margin-bottom: 8px;\n}\n\n.name {\n    font-size: 14px;\n}\n\n.message {\n    margin-left: 20px;\n\n    font-size: 8px;\n}\n\n.retryButton {\n    display: inline-block;\n\n    width: 100%;\n    height: 26px;\n    margin-top: 8px;\n\n    text-decoration: none;\n\n    font-size: 14px;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-common-ErrorHandleForm-_ErrorHandleForm__root",
	"title": "src-client-layers-view-common-ErrorHandleForm-_ErrorHandleForm__title",
	"description": "src-client-layers-view-common-ErrorHandleForm-_ErrorHandleForm__description",
	"name": "src-client-layers-view-common-ErrorHandleForm-_ErrorHandleForm__name",
	"message": "src-client-layers-view-common-ErrorHandleForm-_ErrorHandleForm__message",
	"retryButton": "src-client-layers-view-common-ErrorHandleForm-_ErrorHandleForm__retryButton"
};
export default ___CSS_LOADER_EXPORT___;
