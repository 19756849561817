import * as React from 'react';
import * as classnames from 'classnames';
import { LabeledInput, InputType } from 'sber-marketing-ui';

import * as styles from './PeriodEditor.scss';
import { PeriodEditorTemplateProps } from './types';

/** "PeriodEditor" template component */
export const PeriodEditorTemplate: React.StatelessComponent<PeriodEditorTemplateProps> = ({
    className,
    dateFrom,
    dateTill,
    onDateFromChange,
    onDateTillChange,
}: PeriodEditorTemplateProps): JSX.Element => (
    <div className={classnames(styles.root, className)}>
        <div className={styles.datepickerWrapper}>
            <LabeledInput
                title="Начало периода"
                value={dateFrom}
                type={InputType.DatePicker}
                onValueChange={onDateFromChange}
            />
        </div>
        <div className={styles.datepickerWrapper}>
            <LabeledInput
                title="Конец периода"
                value={dateTill}
                type={InputType.DatePicker}
                onValueChange={onDateTillChange}
            />
        </div>
    </div>
);
