// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-journal-EventTypesMultiSelect-_EventTypesMultiSelect__root{display:flex;align-items:center;justify-content:flex-start;flex-wrap:wrap}.src-client-layers-view-journal-EventTypesMultiSelect-_EventTypesMultiSelect__checkbox{width:25%;margin-bottom:4px}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/journal/EventTypesMultiSelect/EventTypesMultiSelect.scss"],"names":[],"mappings":"AAAA,mFACI,YAAa,CAEb,kBAAmB,CACnB,0BAA2B,CAC3B,cAAe,CAClB,uFAGG,SAAU,CACV,iBAAkB","sourcesContent":[".root {\n    display: flex;\n\n    align-items: center;\n    justify-content: flex-start;\n    flex-wrap: wrap;\n}\n\n.checkbox {\n    width: 25%;\n    margin-bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-journal-EventTypesMultiSelect-_EventTypesMultiSelect__root",
	"checkbox": "src-client-layers-view-journal-EventTypesMultiSelect-_EventTypesMultiSelect__checkbox"
};
export default ___CSS_LOADER_EXPORT___;
