import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { isNil } from 'lodash';

import { State } from '../../../store/types';
import { loadJournalPage, getIsLoading, getErrorMessage, getErrorName, getPage } from '../../../store/journal';
import { MappedState, MappedActions, JournalCommonProps } from './types';
import { JournalContainer } from './JournalContainer';

const mapStateToProps = (state: State): MappedState => {
    const isLoading = getIsLoading(state);
    const errorName = getErrorName(state);
    const errorMessage = getErrorMessage(state);
    const error = !isNil(errorName) && !isNil(errorMessage) ? { name: errorName, message: errorMessage } : null;
    const pageNumber = getPage(state);
    return {
        isLoading,
        error,
        pageNumber,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    loadJournalPage: (pageNumber: number) => dispatch(loadJournalPage(pageNumber)),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** "Journal" store component */
export const JournalStore: ComponentType<JournalCommonProps> = withStore(JournalContainer);
