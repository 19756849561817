import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { combineReducers } from 'redux';

import { beginJournalClear, endJournalClear, clearJournal } from './actions';
import { JournalClearingStatus, JournalClearState } from './types';
import { asyncActionErrorReducerFactory, nullReducer } from '../commonReducers';

const error = asyncActionErrorReducerFactory(clearJournal)
    .case(beginJournalClear, nullReducer)
    .case(endJournalClear, nullReducer);

const status = reducerWithInitialState<JournalClearingStatus>(JournalClearingStatus.NOT_CLEARED)
    .case(beginJournalClear, () => JournalClearingStatus.WAIT_FOR_SUMBIT)
    .case(clearJournal.started, () => JournalClearingStatus.IN_PROGRESS)
    .case(clearJournal.failed, () => JournalClearingStatus.ERROR)
    .case(clearJournal.done, () => JournalClearingStatus.CLEARED)
    .case(endJournalClear, () => JournalClearingStatus.NOT_CLEARED);

export const journalClear = combineReducers<JournalClearState>({ error, status });
