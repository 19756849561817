import { combineReducers } from 'redux';
import { Action } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { RoutingState, CurrentPage } from './types';
import {
    routeAuthorizationErrorPage,
    routeOrganizationsPage,
    routeRolesPage,
    routeConfigurationPage,
    routeJournalPage,
    pageTypes,
    setIsAuthChecking,
    setIsSessionExpired,
    setIsSessionWatching,
    routeReportPage,
} from './actions';
import { replaceReducer } from '../commonReducers';

const currentPageReducer = (state: CurrentPage, { type }: Action<any>): CurrentPage => pageTypes[type];

const currentPage = reducerWithInitialState<CurrentPage | null>(null).casesWithAction(
    [
        routeAuthorizationErrorPage,
        routeReportPage,
        routeOrganizationsPage,
        routeRolesPage,
        routeConfigurationPage,
        routeJournalPage,
    ],
    currentPageReducer,
);

const isAuthChecking = reducerWithInitialState<boolean>(false).case(setIsAuthChecking, replaceReducer);

const isSessionExpired = reducerWithInitialState<boolean>(false).case(setIsSessionExpired, replaceReducer);

const isSessionWatching = reducerWithInitialState<boolean>(false).case(setIsSessionWatching, replaceReducer);

export const routingReducer = combineReducers<RoutingState>({
    currentPage,
    isAuthChecking,
    isSessionExpired,
    isSessionWatching,
});
