import * as React from 'react';
import { LabeledSelect, SelectItem } from 'sber-marketing-ui';
import { PasswordTimeout } from 'sber-marketing-types/system-admin';

import { PasswordTimeoutSelectTemplateProps } from './types';

const ITEMS: SelectItem[] = [
    {
        value: PasswordTimeout.ONE_MONTH,
        label: 'Один месяц',
    },
    {
        value: PasswordTimeout.THREE_MONTHS,
        label: 'Три месяца',
    },
    {
        value: PasswordTimeout.SIX_MONTHS,
        label: 'Шесть месяцев',
    },
    {
        value: PasswordTimeout.TWELVE_MONTHS,
        label: 'Двенадцать месяцев',
    },
];

const TITLE = 'Срок истечения пароля';

/** "PasswordTimeoutSelect" template component */
export const PasswordTimeoutSelectTemplate: React.StatelessComponent<PasswordTimeoutSelectTemplateProps> = ({
    className,
    passwordTimeout,
    editPasswordTimeout,
}: PasswordTimeoutSelectTemplateProps): JSX.Element => (
    <div className={className}>
        <LabeledSelect title={TITLE} items={ITEMS} selectedValue={passwordTimeout} onSelection={editPasswordTimeout} />
    </div>
);
