import * as React from 'react';

import * as styles from './ConfigurationPage.scss';
import { AdminDashboardPage } from '../../common/AdminDashboardPage';
import { ConfigurationEditor } from '../ConfigurationEditor';

/** "ConfigurationPage" template component */
export const ConfigurationPageTemplate: React.StatelessComponent = (): JSX.Element => (
    <AdminDashboardPage>
        <div className={styles.root}>
            <ConfigurationEditor className={styles.editor} />
        </div>
    </AdminDashboardPage>
);
