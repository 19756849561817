import * as React from 'react';
import { PagePreloader } from 'sber-marketing-ui';

import * as styles from './GlobalPreloaderPage.scss';

/** "GlobalPreloaderPage" tempalte component */
export const GlobalPreloaderPageTemplate: React.StatelessComponent = (): JSX.Element => (
    <div className={styles.root}>
        <PagePreloader />
    </div>
);
