import { createSelector } from 'reselect';

import { getFullState } from '../utils';
import { JournalClearState, JournalClearingStatus } from './types';

export const getJournalClearState = createSelector(getFullState, ({ journalClear }): JournalClearState => journalClear);

export const getJournalClearingStatus = createSelector(
    getJournalClearState,
    ({ status }): JournalClearingStatus => status,
);

export const getError = createSelector(getJournalClearState, ({ error }): Error | null => error);
