// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-configuration-ConfigurationPage-_ConfigurationPage__root{padding:8px 38px 32px}.src-client-layers-view-configuration-ConfigurationPage-_ConfigurationPage__editor{margin-top:8px}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/configuration/ConfigurationPage/ConfigurationPage.scss"],"names":[],"mappings":"AAAA,iFACI,qBAAsB,CACzB,mFAGG,cAAe","sourcesContent":[".root {\n    padding: 8px 38px 32px;\n}\n\n.editor {\n    margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-configuration-ConfigurationPage-_ConfigurationPage__root",
	"editor": "src-client-layers-view-configuration-ConfigurationPage-_ConfigurationPage__editor"
};
export default ___CSS_LOADER_EXPORT___;
