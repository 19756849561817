import * as React from 'react';

import * as style from './InputCell.scss';

interface Props {
    value: string;
    style?: React.CSSProperties;
}

export const InputCell = ({ value = '—', style: additionalStyle }: Props): JSX.Element => {
    return (
        <div className={style.root} style={additionalStyle}>
            {value}
        </div>
    );
};
