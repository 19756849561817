import * as React from 'react';
import * as Dotdotdot from 'react-dotdotdot';

import * as style from './OrganizationCard.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { Utils } from '../../../common/Utils';

export interface Props {
    name: string;
    userCount: number;
    activityCount: number;
    isMenuOpened: boolean;
    onOpenerClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    onEditClick: () => void;
    onDeleteClick: () => void;
}

export interface MenuParams {
    label: string;
    handler: () => void;
}

export function OrganizationCard({
    name,
    userCount = 0,
    activityCount = 0,
    isMenuOpened,
    onOpenerClick,
    onEditClick,
    onDeleteClick,
}: Props): JSX.Element {
    const userDeclension = Utils.getDeclensionByNumber(userCount, ['пользователь', 'пользователя', 'пользователей']);
    const activityDeclension = Utils.getDeclensionByNumber(userCount, ['активность', 'активности', 'активностей']);

    return (
        <div className={style.root}>
            <div className={style.name}>
                <Dotdotdot clamp={2}>{name}</Dotdotdot>
            </div>

            <div className={style.count}>
                {activityCount} {activityDeclension}
            </div>

            <div className={style.count}>
                {userCount} {userDeclension}
            </div>

            {renderMenuDropdown()}
        </div>
    );

    function renderMenuDropdown(): JSX.Element {
        const menuOptions: MenuParams[] = [
            {
                label: 'Редактировать',
                handler: onEditClick,
            },
        ];

        if (activityCount == 0) {
            menuOptions.push({
                label: 'Удалить',
                handler: onDeleteClick,
            });
        }

        return (
            <React.Fragment>
                <div className={style.kebabWrapper} onClick={onOpenerClick}>
                    <Icon type={IconType.KEBAB_HORIZONTAL} color={isMenuOpened ? '#00b957' : 'defaultColor'} />

                    {isMenuOpened && (
                        <div className={style.dropdownWrapper}>
                            {menuOptions.map((item, index) => (
                                <div className={style.dropdownItem} onClick={item.handler} key={index}>
                                    {item.label}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {isMenuOpened && <div className={style.mask} onClick={onOpenerClick} />}
            </React.Fragment>
        );
    }
}
