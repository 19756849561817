import * as React from 'react';
import autobind from 'autobind-decorator';
import { EventType } from 'sber-marketing-types/system-admin';
import { includes } from 'lodash';

import { EventTypesMultiSelectContainerProps, EventTypesMultiSelectTemplateItem } from './types';
import { EventTypesMultiSelectTemplate } from './EventTypesMultiSelectTemplate';
import { EVENT_TYPE_DISPLAY_NAMES } from '../../../store/journal';

/** "EventTypesMultiSelect" container component */
export class EventTypesMultiSelectContainer extends React.Component<EventTypesMultiSelectContainerProps> {
    /** Available event types */
    private static readonly AVAILABLE_EVENT_TYPES: EventType[] = [
        EventType.CreateUser,
        EventType.UpdateUser,
        EventType.InvalidPassword,
        EventType.ChangeExpireDate,
        EventType.UpdateDivision,
        EventType.CreateDivision,
        EventType.UpdateProduct,
        EventType.CreateProduct,
        EventType.UpdateActivityType,
        EventType.CreateWorkType,
        EventType.UpdateWorkType,
        EventType.UpdateDepartment,
        EventType.CreateDepartment,
        EventType.AddRoleToUser,
        EventType.CreateOrganization,
        EventType.UpdateOrganization,
        EventType.DeleteOrganization,
        EventType.CreateRole,
        EventType.UpdateRole,
        EventType.AddPermissionToRole,
        EventType.CreateActivity,
        EventType.UpdateActivity,
        EventType.DeleteActivity,
        EventType.CreateTask,
        EventType.UpdateTask,
        EventType.ChangedStatusUser,
        EventType.AccountExpired,
        EventType.ShareActivity,
        EventType.DeleteTask,
        EventType.UploadTaskFile,
        EventType.DownloadTaskFile,
        EventType.UpdateTaskExecutor,
        EventType.AddTaskParticipant,
        EventType.RemoveTaskParticipant,
        EventType.AddTaskComment,
        EventType.UpdateTaskComment,
        EventType.DeleteTaskComment,
        EventType.UploadBriefFile,
        EventType.DownloadBriefFile,
        EventType.UpdateSystemConfiguration,
    ];
    /** @override */
    public render(): JSX.Element {
        return <EventTypesMultiSelectTemplate className={this.props.className} items={this.getTemplateItemsList()} />;
    }
    /** Get template items list */
    protected getTemplateItemsList(): EventTypesMultiSelectTemplateItem[] {
        return EventTypesMultiSelectContainer.AVAILABLE_EVENT_TYPES.map(this.getTemplateItem);
    }
    /** Get template item for event type */
    @autobind
    protected getTemplateItem(eventType: EventType): EventTypesMultiSelectTemplateItem {
        const { onAddItem, onRemoveItem, selected } = this.props;
        const isChecked: boolean = includes(selected, eventType);
        return {
            id: eventType,
            props: {
                isChecked,
                title: EVENT_TYPE_DISPLAY_NAMES[eventType],
                onSwitch: isChecked ? () => onRemoveItem(eventType) : () => onAddItem(eventType),
            },
        };
    }
}
