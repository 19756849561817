import { Action } from 'typescript-fsa';

import * as baseActions from './baseActions';
import { CurrentPage } from '../types';

/** Route to target page by it's type */
export const routeByPageType = (currentPage: CurrentPage | null, payload?: any): Action<any> => {
    let result: Action<any>;

    switch (currentPage) {
        case CurrentPage.AUTHORIZATION_ERROR: {
            result = baseActions.routeAuthorizationErrorPage(payload);
            break;
        }
        case CurrentPage.JOURNAL: {
            result = baseActions.routeJournalPage(payload);
            break;
        }
        case CurrentPage.REPORT: {
            result = baseActions.routeReportPage(payload);
            break;
        }
        case CurrentPage.CONFIGURATION: {
            result = baseActions.routeConfigurationPage(payload);
            break;
        }
        case CurrentPage.ROLES: {
            result = baseActions.routeRolesPage(payload);
            break;
        }
        case CurrentPage.ORGANIZATIONS:
        default: {
            result = baseActions.routeOrganizationsPage(payload);
            break;
        }
    }

    return result;
};
