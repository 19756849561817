import { Location } from 'redux-first-router';

import { RoutingState } from './routing';
import { JournalState } from './journal';
import { JournalFiltersState } from './journalFilters';
import { FilterDataState } from './filterData';
import { JournalClearState } from './journalClear';
import { ConfigurationState } from './configuration';
import { OrganizationsPageState } from './organizations/types';
import { UsersState } from './user/types';
import { AuthError } from './authError';
import { PageState as RolesPageState } from './rolesPage/types';

/** Standard loading status for items store */
export const enum ItemLoadingStatus {
    /** Items not loaded */
    NOT_LOADED = 'not_loaded',
    /** Loading in progress */
    LOADING = 'loading',
    /** Successfully loaded */
    LOADED = 'loaded',
    /** Errror thrown during loading */
    ERROR = 'error',
}

/** Application state signature */
export interface State {
    /** "redux-first-router" location state */
    location: Location;
    /** Application routing state */
    routing: RoutingState;
    /** Journal state */
    journal: JournalState;
    /** Journal filters state */
    journalFilters: JournalFiltersState;
    /** Filters data state */
    filterData: FilterDataState;
    /** Journal clearing state */
    journalClear: JournalClearState;
    /** Configuration state */
    configuration: ConfigurationState;
    /** Organizations page state */
    organizationsPage: OrganizationsPageState;
    /** Authorization error page state */
    authError: AuthError;
    userReducer: UsersState;
    rolesPage: RolesPageState;
}
