import { actionCreatorFactory } from 'typescript-fsa';

import { OrganizationParams } from 'sber-marketing-types/admin';
import { OrganizationsPageState, AddOrganizationParams, UpdateOrganizationParams } from './types';

const actionCreator = actionCreatorFactory('ORGANIZATIONS');

const loadOrganizationsPage = actionCreator<OrganizationsPageState>('LOAD_ORGANIZATIONS_PAGE');
const setLoadingStatus = actionCreator<boolean>('SET_LOADING_STATUS');
const loadCurrentOrganization = actionCreator<OrganizationParams>('LOAD_CURRENT_ORGANIZATION');
const updateCurrentOrganization = actionCreator<UpdateOrganizationParams>('UPDATE_CURRENT_ORGANIZATION');
const resetCurrentOrganization = actionCreator('RESET_CURRENT_ORGANIZATION');
const setCurrentOrganizationId = actionCreator<string>('SET_CURRENT_ORGANIZATION_ID');
const addOrganization = actionCreator<AddOrganizationParams>('ADD_ORGANIZATION');
const updateOrganization = actionCreator<UpdateOrganizationParams>('UPDATE_ORGANIZATION');
const removeOrganization = actionCreator<string>('REMOVE_ORGANIZATION');

export {
    loadOrganizationsPage,
    setLoadingStatus,
    loadCurrentOrganization,
    updateCurrentOrganization,
    resetCurrentOrganization,
    setCurrentOrganizationId,
    addOrganization,
    updateOrganization,
    removeOrganization,
};
