import thunk from 'redux-thunk';
import createBrowserHistory from 'history/createBrowserHistory';
import { createStore, combineReducers, Store, applyMiddleware, compose } from 'redux';
import { connectRoutes } from 'redux-first-router';
import { isNil } from 'lodash';

import type { State } from './types';
import { routes, routing } from './routing';
import { journal } from './journal';
import { journalFilters } from './journalFilters';
import { querySerializer } from './querySerializer';
import { queryToPayloadMiddleware } from './queryToPayloadMiddleware';
import { filterData } from './filterData';
import { journalClear } from './journalClear';
import { configuration } from './configuration';
import { organizationsReducer } from './organizations/reducers';
import { userReducer } from './user/reducers';
import { authError } from './authError';
import { rolesPageReducer } from './rolesPage/reducers';

const history = createBrowserHistory();

const {
    middleware,
    enhancer,
    reducer: location,
} = connectRoutes(history, routes, {
    querySerializer,
});

const middlewares = applyMiddleware(thunk, queryToPayloadMiddleware, middleware);

const env = process.env.NODE_ENV;

const composeEnhancers =
    env != 'production' && !isNil(window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'])
        ? window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
        : compose;

export const store: Store<State> = createStore<State>(
    combineReducers({
        routing,
        location,
        journal,
        journalFilters,
        filterData,
        journalClear,
        configuration,
        authError,
        organizationsPage: organizationsReducer,
        rolesPage: rolesPageReducer,
        userReducer,
    }),
    composeEnhancers(<any>enhancer, middlewares),
);

export type { State };
