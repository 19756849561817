import * as React from 'react';
import * as lodash from 'lodash';
import classNames from 'classnames';

import * as style from './Table.scss';

import type { CellEvent, CellPosition, ColumnName, ColumnWidths, LineId } from './types';

import { Icon, IconType } from 'sber-marketing-ui';
// import { FiltersList } from '../FiltersList';
import { TableViewModel } from './TableViewModel';
import { TableView } from './TableView';

interface Props {
    viewModel: TableViewModel;
    tableColumns: ColumnName[];
    leftFixedColumns: ColumnName[];
    rightFixedColumns: ColumnName[];
    readOnlyColumns: ColumnName[];
    tableLineIds: LineId[];
    columnWidths: ColumnWidths;
    newLineCreation: boolean;
    tableRef: (component: TableView) => void;
    onAddButtonClick: () => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
}

export const TableTemplate = ({
    viewModel,
    tableColumns,
    leftFixedColumns,
    rightFixedColumns,
    readOnlyColumns,
    tableLineIds,
    columnWidths,
    newLineCreation,
    tableRef,
    onAddButtonClick,
    onCellEvent,
}: Props): JSX.Element => {
    const filterItems = [
        {
            columnName: '1',
            name: 'Действия',
            count: 3,
        },
        {
            columnName: '2',
            name: 'Роль',
            count: 2,
        },
    ];

    return (
        <div className={style.root}>
            <div className={style.topLine}>
                <div
                    className={classNames(style.addButton, newLineCreation && style.disabled)}
                    onClick={newLineCreation ? null : onAddButtonClick}
                >
                    <div className={style.addButtonIcon}>
                        <Icon type={IconType.PLUS_IN_CIRCLE} svgSize={18} />
                    </div>
                    Создать новую роль
                </div>

                {/* {!lodash.isEmpty(filterItems) &&
                    <div className={style.filtersList}>
                        <FiltersList
                            items={[
                                {
                                    columnName: '1',
                                    name: 'Действия',
                                    count: 3
                                },
                                {
                                    columnName: '2',
                                    name: 'Роль',
                                    count: 2
                                }
                            ]}
                            onFilterRemove={columnName => console.log(columnName)}
                        />
                    </div>
                } */}
            </div>

            <div className={style.table}>
                <TableView
                    ref={tableRef}
                    viewModel={viewModel}
                    columns={tableColumns}
                    leftFixedColumns={leftFixedColumns}
                    rightFixedColumns={rightFixedColumns}
                    readOnlyColumns={readOnlyColumns}
                    lines={tableLineIds}
                    columnWidths={columnWidths}
                    onCellEvent={onCellEvent}
                />
            </div>
        </div>
    );
};
