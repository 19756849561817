import autobind from 'autobind-decorator';
import { Component, createElement } from 'react';

import { JournalEventLineContainerProps, JournalEventLineContainerState as State } from './types';
import { JournalEventLineTemplate } from './JournalEventLineTemplate';

/** "JournalEventLine" container component */
export class JournalEventLineContainer extends Component<JournalEventLineContainerProps, State> {
    /** "JournalEventLine" container component state */
    public state: State = {
        isExpanded: false,
    };
    /** Render component */
    public render(): JSX.Element {
        const { id, type, authorId, data, ip, time } = this.props;
        const { isExpanded } = this.state;
        return createElement(JournalEventLineTemplate, {
            id,
            type,
            authorId,
            data,
            ip,
            time,
            isExpanded,
            onToggleButtonClick: this.onToggleButtonClick,
        });
    }
    /** Toggle button click handler */
    @autobind
    private onToggleButtonClick(): void {
        this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }));
    }
}
