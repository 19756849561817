import type { RoleLine, CreateRoleParams } from '../../../../api';
import type { LineId } from '../types';

import { store } from '../../../../store';
import { getPageData } from '../../../../store/rolesPage/selectors';
import { MrmClient } from '../../../../api';

interface StoreProps {
    roleLines: RoleLine[];
}

export class Saver {
    private static instance: Saver;

    public static getInstance(): Saver {
        if (!Saver.instance) {
            Saver.instance = new Saver();
        }
        return Saver.instance;
    }

    public async createRoleLine(params: CreateRoleParams) {
        const client = await MrmClient.getInstance();

        await client.api.rbac.createRole(params);
    }

    public async deleteRoleLine(lineId: LineId) {
        const { roleLines } = this.getStoreProps();

        const roleLine = roleLines.find((item) => item.model.id === lineId);
        await roleLine.model.delete();
    }

    public async saveRoleLine(lineId: LineId) {
        const { roleLines } = this.getStoreProps();

        const roleLine = roleLines.find((item) => item.model.id === lineId);
        await roleLine.model.save();
    }

    public async resetRoleLineChanges(lineId: LineId) {
        const { roleLines } = this.getStoreProps();

        const roleLine = roleLines.find((item) => item.model.id === lineId);
        await roleLine.model.cancel();
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        const { roleLines } = getPageData(storeState);

        return {
            roleLines,
        };
    }
}
