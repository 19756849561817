// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-RolesPage-Table-ColumnHeaderTypes-FiltersColumnHeader-Filters-_Filters__root{display:block;overflow:hidden}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/RolesPage/Table/ColumnHeaderTypes/FiltersColumnHeader/Filters/Filters.scss"],"names":[],"mappings":"AAAA,qGACI,aAAc,CACd,eAAgB","sourcesContent":[".root {\n    display: block;\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-RolesPage-Table-ColumnHeaderTypes-FiltersColumnHeader-Filters-_Filters__root"
};
export default ___CSS_LOADER_EXPORT___;
