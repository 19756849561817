import * as React from 'react';
import * as classnames from 'classnames';

import * as styles from './UserInfo.scss';
import { UserInfoTemplateProps } from './types';
import { OrganizationInfo } from '../OrganizationInfo';

const buildFSM = (firstName: string, secondName: string, middleName = ''): string =>
    `${secondName} ${firstName} ${middleName}`.trim();

/** "UserInfo" template component */
export const UserInfoTemplate: React.StatelessComponent<UserInfoTemplateProps> = ({
    className,
    id,
    found,
    firstName,
    middleName,
    secondName,
    email,
    organizationId,
}: UserInfoTemplateProps): JSX.Element => (
    <div className={classnames(styles.root, className)}>
        <div className={styles.line}>
            <div className={styles.fieldName}>Id</div>
            <div>{id}</div>
        </div>
        {found && (
            <div className={styles.line}>
                <div className={styles.fieldName}>ФИО</div>
                <div>{buildFSM(firstName, secondName, middleName)}</div>
            </div>
        )}
        {found && (
            <div className={styles.line}>
                <div className={styles.fieldName}>Email/login</div>
                <div>{email}</div>
            </div>
        )}
        {found && <OrganizationInfo className={styles.organization} id={organizationId} />}
    </div>
);
