import * as React from 'react';
import { Dispatch, connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { OrganizationParams } from 'sber-marketing-types/admin';
import { AddOrganizationParams } from '../../../../store/organizations/types';

import { OrganizationFormContainer } from './OrganizationFormContainer';
import { State as StoreState } from '../../../../store';
import {
    loadCurrentOrganization,
    addOrganization,
    resetCurrentOrganization,
} from '../../../../store/organizations/actions';
import { getCurrentOrganization } from '../../../../store/organizations/selectors';
import { OrganizationApi } from '../../../../api';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {}

interface MapProps {
    organization: OrganizationParams;
}

interface DispatchProps {
    loadCurrentOrganization: (params: OrganizationParams) => void;
    addOrganization: (params: AddOrganizationParams) => void;
    resetCurrentOrganization: () => void;
}

interface State {
    isBlocked: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class CreateOrganizationFormContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isBlocked: false,
        };
    }

    public componentDidMount() {
        this.props.loadCurrentOrganization({
            id: null,
            name: '',
            executorIds: [],
            customerIds: [],
        });
    }

    public render(): JSX.Element {
        const { name = '', executorIds = [], customerIds = [] } = this.props.organization;

        const { isBlocked } = this.state;

        return React.createElement(OrganizationFormContainer, {
            blockTitle: 'Создание организации',
            confirmButtonText: 'Создать',
            name,
            executorIds,
            customerIds,
            isBlocked,
            onConfirmClick: this.onConfirmClick,
        });
    }

    @autobind
    protected async onConfirmClick() {
        this.setState({ isBlocked: true });

        const { name, executorIds, customerIds } = this.props.organization;

        const { id } = await OrganizationApi.createOrganization({ name });

        if (!!executorIds.length || !!customerIds.length) {
            await OrganizationApi.updateOrganization(id, {
                name,
                executorIds,
                customerIds,
            });
        }

        this.props.addOrganization({
            id,
            name,
            executorIds,
            customerIds,
        });

        this.props.resetCurrentOrganization();

        this.setState({ isBlocked: false });

        return {
            organizationId: id,
            organizationName: name,
        };
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        organization: getCurrentOrganization(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return {
        loadCurrentOrganization: (params: OrganizationParams) => dispatch(loadCurrentOrganization(params)),
        addOrganization: (params: AddOrganizationParams) => dispatch(addOrganization(params)),
        resetCurrentOrganization: () => dispatch(resetCurrentOrganization()),
    };
}
