import * as React from 'react';
import * as classnames from 'classnames';
import { Button_redesign, ButtonTheme_redesign } from 'sber-marketing-ui';

import * as styles from './ErrorHandleForm.scss';

/** Default retry button content */
const DEFAULT_BUTTON_CONTENT = 'Попробовать снова';

/** Default title element content */
const DEFAULT_TITLE_CONTENT = 'Произошла ошибка';

/** "ErrorHandleForm" component properties */
export interface ErrorHandleFormProps {
    /** Additional CSS class */
    className?: string;
    /** Name of error (e.g. "Error.name") */
    name: string;
    /** Message in errror (e.g. "Error.message") */
    message: string;
    /** Content of title element (optional) */
    titleContent?: string;
    /** Content of retry button content (optional) */
    retryButtonContent?: string | JSX.Element;
    /** Retry button click handler */
    onRetryClick(): void;
}

/** "ErrorHandleForm" component */
export const ErrorHandleForm: React.StatelessComponent<ErrorHandleFormProps> = ({
    className,
    name,
    message,
    onRetryClick,
    titleContent = DEFAULT_TITLE_CONTENT,
    retryButtonContent = DEFAULT_BUTTON_CONTENT,
}: ErrorHandleFormProps): JSX.Element => (
    <div className={classnames(styles.root, className)}>
        <div className={styles.title}>{titleContent}</div>
        <div className={styles.description}>
            <div className={styles.name}>{name}</div>
            <div className={styles.message}>{message}</div>
        </div>
        <div className={styles.retryButton}>
            <Button_redesign theme={ButtonTheme_redesign.Ghost} onClick={onRetryClick}>
                {retryButtonContent}
            </Button_redesign>
        </div>
    </div>
);
