// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-journal-JournalEventLine-_JournalEventLine__root{width:inherit}.src-client-layers-view-journal-JournalEventLine-_JournalEventLine__root:hover{cursor:pointer;background-color:#f4f4f4}.src-client-layers-view-journal-JournalEventLine-_JournalEventLine__cell{padding:10px 14px;vertical-align:top;border-right:solid 1px #dcdcdc;border-bottom:solid 1px #dcdcdc}.src-client-layers-view-journal-JournalEventLine-_JournalEventLine__cell:first-child{border-left:solid 1px #dcdcdc}.src-client-layers-view-journal-JournalEventLine-_JournalEventLine__timestamp{width:120px}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/journal/JournalEventLine/JournalEventLine.scss"],"names":[],"mappings":"AAAA,yEACI,aAAc,CADlB,+EAIQ,cAAe,CAEf,wBAAyB,CAC5B,yEAID,iBAAkB,CAElB,kBAAmB,CAEnB,8BAA+B,CAC/B,+BAAgC,CANpC,qFASQ,6BAA8B,CACjC,8EAID,WAAY","sourcesContent":[".root {\n    width: inherit;\n\n    &:hover {\n        cursor: pointer;\n\n        background-color: #f4f4f4;\n    }\n}\n\n.cell {\n    padding: 10px 14px;\n\n    vertical-align: top;\n\n    border-right: solid 1px #dcdcdc;\n    border-bottom: solid 1px #dcdcdc;\n\n    &:first-child {\n        border-left: solid 1px #dcdcdc;\n    }\n}\n\n.timestamp {\n    width: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-journal-JournalEventLine-_JournalEventLine__root",
	"cell": "src-client-layers-view-journal-JournalEventLine-_JournalEventLine__cell",
	"timestamp": "src-client-layers-view-journal-JournalEventLine-_JournalEventLine__timestamp"
};
export default ___CSS_LOADER_EXPORT___;
