import { ComponentType } from 'react';
import { connect } from 'react-redux';

import { State } from '../../../store/types';
import { getRecords } from '../../../store/journal';
import { JournalEventsTableStoreProps, MappedState } from './types';
import { JournalEventsTableTemplate } from './JournalEventsTableTemplate';

const mapStateToProps = (state: State): MappedState => ({
    records: getRecords(state),
});

const withStore = connect(mapStateToProps);

/** "JournalEventsTable" store component */
export const JournalEventsTableStore: ComponentType<JournalEventsTableStoreProps> =
    withStore(JournalEventsTableTemplate);
