// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-journal-PeriodEditor-_PeriodEditor__root{display:flex;width:656px;align-items:center;justify-content:space-between}.src-client-layers-view-journal-PeriodEditor-_PeriodEditor__datepickerWrapper{width:300px}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/journal/PeriodEditor/PeriodEditor.scss"],"names":[],"mappings":"AAGA,iEACI,YAAa,CAEb,WAAO,CAEP,kBAAmB,CACnB,6BAA8B,CACjC,8EAGG,WAAO","sourcesContent":["$itemWidth: 300;\n$itemsDistance: 56;\n\n.root {\n    display: flex;\n\n    width: #{$itemWidth * 2 + $itemsDistance}px;\n\n    align-items: center;\n    justify-content: space-between;\n}\n\n.datepickerWrapper {\n    width: #{$itemWidth}px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-journal-PeriodEditor-_PeriodEditor__root",
	"datepickerWrapper": "src-client-layers-view-journal-PeriodEditor-_PeriodEditor__datepickerWrapper"
};
export default ___CSS_LOADER_EXPORT___;
