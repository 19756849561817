import * as React from 'react';
import autobind from 'autobind-decorator';
import * as moment from 'moment';

import { ReportPage } from './ReportPage';
import { JournalApi } from '../../api';

export interface Props {}

interface State {
    start: moment.Moment;
    end: moment.Moment;
    showErrors: boolean;
}

export class ReportPageContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            start: null,
            end: null,
            showErrors: false,
        };
    }

    public render(): JSX.Element {
        return React.createElement(ReportPage, {
            onButtonClick: this.onButtonClick,
            start: this.state.start,
            end: this.state.end,
            showErrors: this.state.showErrors,
            onStartDateChange: this.onStartDateChange,
            onEndDateChange: this.onEndDateChange,
        });
    }

    @autobind
    protected onStartDateChange(start: moment.Moment) {
        this.setState({
            start,
            showErrors: false,
        });
    }

    @autobind
    protected onEndDateChange(end: moment.Moment) {
        this.setState({
            end,
            showErrors: false,
        });
    }

    @autobind
    protected async onButtonClick(): Promise<void> {
        await this.validateDates();

        if (!this.state.showErrors) {
            await this.loadReport();
        }
    }

    private async validateDates() {
        this.setState(() => ({
            showErrors: !(this.state.start && this.state.start.isValid() && this.state.end && this.state.end.isValid()),
        }));
    }

    private async loadReport(): Promise<void> {
        const dateFrom = this.state.start.format('YYYY-MM-DD');
        const dateTill = this.state.end.format('YYYY-MM-DD');

        const url = await JournalApi.getInstance().getListCSVDownloadLink({
            dateFrom,
            dateTill,
        });

        window.open(url, '_blank');
    }
}
