import * as React from 'react';

import * as style from './TextCell.scss';

interface Props {
    value: string;
}

export const TextCell = ({ value = '—' }: Props): JSX.Element => {
    return <div className={style.root}>{value}</div>;
};
