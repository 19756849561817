import * as React from 'react';
import * as moment from 'moment';

import { UserParams } from 'sber-marketing-types/admin';

import {
    LabeledInput,
    Icon,
    IconType,
    InputType,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    MASK,
} from 'sber-marketing-ui';

import * as styles from './UserForm.scss';

interface Props {
    toggleUserCreating: () => void;
    businessAdmin: Partial<UserParams>;
    onChangeBusinessAdminField: (field: string, value: string) => void;
    onSaveUser: () => void;
}

export function UserForm({
    toggleUserCreating,
    businessAdmin,
    onChangeBusinessAdminField,
    onSaveUser,
}: Props): JSX.Element {
    return (
        <div className={styles.root}>
            <div className={styles.titleWrapper}>
                <span className={styles.iconWrapper}>
                    <Icon type={IconType.ARROW_BACK} svgSize={16} onClick={toggleUserCreating} />
                </span>
                <span>Создание бизнес администратора</span>
            </div>
            <div className={styles.inputWrapper}>
                <LabeledInput
                    title={'Имя'}
                    value={businessAdmin.firstName}
                    onValueChange={(value) => onChangeBusinessAdminField('firstName', value as string)}
                />
            </div>
            <div className={styles.inputWrapper}>
                <LabeledInput
                    title={'Фамилия'}
                    value={businessAdmin.secondName}
                    onValueChange={(value) => onChangeBusinessAdminField('secondName', value as string)}
                />
            </div>
            <div className={styles.inputWrapper}>
                <LabeledInput
                    title={'Отчество'}
                    value={businessAdmin.middleName}
                    onValueChange={(value) => onChangeBusinessAdminField('middleName', value as string)}
                />
            </div>
            <div className={styles.inputWrapper}>
                <LabeledInput
                    title={'Телефон'}
                    type={InputType.MaskedInput}
                    mask={MASK.phone}
                    value={businessAdmin.phoneNumber}
                    onValueChange={(value) => onChangeBusinessAdminField('phoneNumber', value as string)}
                />
            </div>
            <div className={styles.inputWrapper}>
                <LabeledInput
                    title={'Email'}
                    value={businessAdmin.email}
                    onValueChange={(value) => onChangeBusinessAdminField('email', value as string)}
                />
            </div>
            <div className={styles.inputWrapper}>
                <LabeledInput
                    type={InputType.DatePicker}
                    title={'Срок жизни аккаунта'}
                    value={moment(businessAdmin.activeUntil)}
                    onValueChange={(value: moment.Moment) => onChangeBusinessAdminField('activeUntil', value.format())}
                    disabled
                />
            </div>
            <div className={styles.inputWrapper}>
                <LabeledInput
                    title={'Статус пользователя'}
                    value={businessAdmin.status}
                    onValueChange={(value) => onChangeBusinessAdminField('status', value as string)}
                    disabled
                />
            </div>
            <div className={styles.buttonWrapper}>
                <Button theme={ButtonTheme.Main} onClick={onSaveUser}>
                    Сохранить
                </Button>
            </div>
        </div>
    );
}
