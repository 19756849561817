// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-journal-Journal-_Journal__root{position:relative;width:100%;padding:40px 56px;color:#262626;background-color:rgba(255,255,255,0.9);box-shadow:0 1px 6px 0 rgba(0,0,0,0.1);font:400 14px/1.5 'Open Sans'}@media screen and (max-width: 1350px){.src-client-layers-view-journal-Journal-_Journal__root{padding:16px 16px 24px}}.src-client-layers-view-journal-Journal-_Journal__table{width:100%}.src-client-layers-view-journal-Journal-_Journal__title{margin-bottom:24px;font:600 28px/30px 'Open Sans'}.src-client-layers-view-journal-Journal-_Journal__paginationTop{margin-bottom:16px}.src-client-layers-view-journal-Journal-_Journal__paginationBottom{margin-top:16px}.src-client-layers-view-journal-Journal-_Journal__manageButtons{margin-top:16px}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/journal/Journal/Journal.scss"],"names":[],"mappings":"AAAA,uDACI,iBAAkB,CAElB,UAAW,CACX,iBAAkB,CAElB,aAAc,CACd,sCAAuC,CACvC,sCAAuC,CAEvC,6BAA8B,CACjC,sCAGG,uDACI,sBAAuB,CAC1B,CAGL,wDACI,UAAW,CACd,wDAGG,kBAAmB,CAEnB,8BAA+B,CAClC,gEAGG,kBAAmB,CACtB,mEAGG,eAAgB,CACnB,gEAGG,eAAgB","sourcesContent":[".root {\n    position: relative;\n\n    width: 100%;\n    padding: 40px 56px;\n\n    color: #262626;\n    background-color: rgba(255,255,255,0.9);\n    box-shadow: 0 1px 6px 0 rgba(0,0,0,0.1);\n\n    font: 400 14px/1.5 'Open Sans';\n}\n\n@media screen and (max-width: 1350px) {\n    .root {\n        padding: 16px 16px 24px;\n    }\n}\n\n.table {\n    width: 100%;\n}\n\n.title {\n    margin-bottom: 24px;\n\n    font: 600 28px/30px 'Open Sans';\n}\n\n.paginationTop {\n    margin-bottom: 16px;\n}\n\n.paginationBottom {\n    margin-top: 16px;\n}\n\n.manageButtons {\n    margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-journal-Journal-_Journal__root",
	"table": "src-client-layers-view-journal-Journal-_Journal__table",
	"title": "src-client-layers-view-journal-Journal-_Journal__title",
	"paginationTop": "src-client-layers-view-journal-Journal-_Journal__paginationTop",
	"paginationBottom": "src-client-layers-view-journal-Journal-_Journal__paginationBottom",
	"manageButtons": "src-client-layers-view-journal-Journal-_Journal__manageButtons"
};
export default ___CSS_LOADER_EXPORT___;
