import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../../store/types';
import { getDateFrom, getDateTill, editDateFrom, editDateTill } from '../../../store/journalFilters';
import { MappedActions, MappedState, PeriodEditorStoreProps } from './types';
import { PeriodEditorTemplate } from './PeriodEditorTemplate';

const mapStateToProps = (state: State): MappedState => ({
    dateTill: getDateTill(state),
    dateFrom: getDateFrom(state),
});

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    onDateFromChange: (value) => dispatch(editDateFrom(value)),
    onDateTillChange: (value) => dispatch(editDateTill(value)),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** "PeriodEditor" store component */
export const PeriodEditorStore: ComponentType<PeriodEditorStoreProps> = withStore(PeriodEditorTemplate);
