import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../../store/types';
import { getCurrentStore, editStore } from '../../../store/configuration';
import { StoreInputStoreProps, MappedActions, MappedState } from './types';
import { StoreInputTemplate } from './StoreInputTemplate';

const mapStateToProps = (state: State): MappedState => ({
    store: getCurrentStore(state),
});

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    editStore: (value) => dispatch(editStore(value)),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** "StoreInput" store component */
export const StoreInputStore: ComponentType<StoreInputStoreProps> = withStore(StoreInputTemplate);
