// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:100,300,400,600,500,700|Roboto:100,300,400,500,900&subset=cyrillic);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,body{width:100%;min-height:100%}.src-client-layers-view-common-AdminDashboardPage-_AdminDashboardPage__i-hidden{display:none}.src-client-layers-view-common-AdminDashboardPage-_AdminDashboardPage__root{position:relative;width:100%;height:100vh;background-color:#f1f5f7}.src-client-layers-view-common-AdminDashboardPage-_AdminDashboardPage__header{width:100%;height:48px}.src-client-layers-view-common-AdminDashboardPage-_AdminDashboardPage__content{width:100%;height:calc(100% - 48px)}.src-client-layers-view-common-AdminDashboardPage-_AdminDashboardPage__scrollView{overflow-x:hidden !important;overflow-y:scroll !important;height:100%;padding-bottom:80px}.src-client-layers-view-common-AdminDashboardPage-_AdminDashboardPage__inlineIcons{display:none}\n", "",{"version":3,"sources":["webpack://./src/client/lib/commonStyles_.scss","webpack://./src/client/layers/view/common/AdminDashboardPage/AdminDashboardPage.scss"],"names":[],"mappings":"AAEA,UACI,UAAW,CACX,eAAgB,CACnB,gFAGG,YAAa,CCNjB,4EACI,iBAAkB,CAElB,UAAW,CACX,YAAa,CAEb,wBAAyB,CAC5B,8EAGG,UAAW,CACX,WAAY,CACf,+EAGG,UAAW,CACX,wBAAyB,CAC5B,kFAGG,4BAA6B,CAC7B,4BAA6B,CAE7B,WAAY,CACZ,mBAAoB,CACvB,mFAGG,YAAa","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Open+Sans:100,300,400,600,500,700|Roboto:100,300,400,500,900&subset=cyrillic');\n\nhtml, body {\n    width: 100%;\n    min-height: 100%;\n}\n\n.i-hidden {\n    display: none;\n}\n","@import \"src/client/lib/commonStyles_\";\n\n.root {\n    position: relative;\n\n    width: 100%;\n    height: 100vh;\n\n    background-color: #f1f5f7;\n}\n\n.header {\n    width: 100%;\n    height: 48px;\n}\n\n.content {\n    width: 100%;\n    height: calc(100% - 48px);\n}\n\n.scrollView {\n    overflow-x: hidden !important;\n    overflow-y: scroll !important;\n\n    height: 100%;\n    padding-bottom: 80px;\n}\n\n.inlineIcons {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"i-hidden": "src-client-layers-view-common-AdminDashboardPage-_AdminDashboardPage__i-hidden",
	"root": "src-client-layers-view-common-AdminDashboardPage-_AdminDashboardPage__root",
	"header": "src-client-layers-view-common-AdminDashboardPage-_AdminDashboardPage__header",
	"content": "src-client-layers-view-common-AdminDashboardPage-_AdminDashboardPage__content",
	"scrollView": "src-client-layers-view-common-AdminDashboardPage-_AdminDashboardPage__scrollView",
	"inlineIcons": "src-client-layers-view-common-AdminDashboardPage-_AdminDashboardPage__inlineIcons"
};
export default ___CSS_LOADER_EXPORT___;
