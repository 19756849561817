import { createSelector } from 'reselect';
import { compact, includes, uniq } from 'lodash';
import { User, Organization, Role } from 'sber-marketing-types/system-admin';

import { getFullState, getNumberId, getNumberIds, getStringId, getStringIds } from '../utils';
import { FilterDataState } from './types';
import { ItemLoadingStatus } from '../types';

// Technical data selectors

export const getFilterDataState = createSelector(getFullState, ({ filterData }): FilterDataState => filterData);

export const getLoadingStatus = createSelector(
    getFilterDataState,
    ({ loadingStatus }): ItemLoadingStatus => loadingStatus,
);

export const getError = createSelector(getFilterDataState, ({ error }): Error => error);

export const getErrorCode = createSelector(getFilterDataState, ({ errorCode }): number | null => errorCode);

// Users selectors

export const getUserById = createSelector(
    [getFilterDataState, getNumberId],
    ({ users }, id): User | undefined => users[id],
);

export const getUsersByIds = createSelector([getFullState, getNumberIds], (state, ids): User[] =>
    compact(ids.map((id) => getUserById(state, id))),
);

export const getAllUsersIds = createSelector(getFilterDataState, ({ users }): number[] =>
    Object.keys(users).map(Number),
);

export const getAllUsers = createSelector([getFullState, getAllUsersIds], getUsersByIds);

export const findUsersByEmails = createSelector([getAllUsers, getStringIds], (users, emails): User[] =>
    users.filter(({ email }) => includes(emails, email)),
);

export const getAllUsersEmails = createSelector(getAllUsers, (users) => uniq(users.map(({ email }) => email)));

// Roles selectors

export const getRoleById = createSelector(
    [getFilterDataState, getNumberId],
    ({ roles }, id): Role | undefined => roles[id],
);

export const getRolesByIds = createSelector([getFullState, getNumberIds], (state, ids): Role[] =>
    compact(ids.map((id) => getRoleById(state, id))),
);

export const getAllRolesIds = createSelector(getFilterDataState, ({ roles }): number[] =>
    Object.keys(roles).map(Number),
);

export const getAllRoles = createSelector([getFullState, getAllRolesIds], getRolesByIds);

export const getAvailableRolesNames = createSelector(getAllRoles, (roles) => uniq(roles.map(({ name }) => name)));

// Organizations selector

export const getOrganizationById = createSelector(
    [getFilterDataState, getStringId],
    ({ organizations }, id): Organization | undefined => organizations[id],
);

export const getOrganizationsByIds = createSelector([getFullState, getStringIds], (state, ids): Organization[] =>
    compact(ids.map((id) => getOrganizationById(state, id))),
);

export const getAllOrganizationsIds = createSelector(getFilterDataState, ({ organizations }): string[] =>
    Object.keys(organizations),
);

export const getAllOrganizations = createSelector([getFullState, getAllOrganizationsIds], getOrganizationsByIds);
