import * as React from 'react';

import * as style from './ConfirmModal.scss';

import {
    Popup,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    Icon,
    IconType,
} from 'sber-marketing-ui';

interface Props {
    title: string;
    message: string;
    confirmButtonTitle: string;
    closeButtonTitle: string;
    onConfirm: () => void;
    onClose: () => void;
}

export const ConfirmModal = ({
    title,
    message,
    confirmButtonTitle,
    closeButtonTitle,
    onConfirm,
    onClose,
}: Props): JSX.Element => {
    return (
        <Popup onOutOfContentClick={onClose}>
            <div className={style.root}>
                <div className={style.title}>{title}</div>

                <div className={style.message}>{message}</div>

                <div className={style.buttons}>
                    <div className={style.button}>
                        <Button theme={ButtonTheme.GhostRounded} onClick={onClose}>
                            {closeButtonTitle}
                        </Button>
                    </div>

                    <div className={style.button}>
                        <Button theme={ButtonTheme.MainRounded} onClick={onConfirm}>
                            {confirmButtonTitle}
                        </Button>
                    </div>
                </div>

                <div className={style.closeButton} onClick={onClose}>
                    <Icon type={IconType.CROSS} />
                </div>
            </div>
        </Popup>
    );
};
