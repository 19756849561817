import * as React from 'react';
import autobind from 'autobind-decorator';

import { UserParams } from 'sber-marketing-types/admin';

import { UserForm } from './UserForm';

interface Props {
    toggleUserCreating: () => void;
    businessAdmin: Partial<UserParams>;
    onChangeBusinessAdminField: (field: string, value: string) => void;
}

export class UserFormContainer extends React.PureComponent<Props> {
    public render() {
        return React.createElement(UserForm, {
            toggleUserCreating: this.props.toggleUserCreating,
            businessAdmin: this.props.businessAdmin,
            onChangeBusinessAdminField: this.props.onChangeBusinessAdminField,
            onSaveUser: this.handleSaveUser,
        });
    }

    @autobind
    protected async handleSaveUser() {
        this.props.toggleUserCreating();
    }
}
