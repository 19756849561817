import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { without } from 'lodash';

import { MappedState, MappedActions, OrganizationSuggestExternalProps } from './types';
import { State } from '../../../store/types';
import {
    addAuthorOrganizationId,
    removeAuthorOrganizationId,
    getAuthorOrganizationIds,
} from '../../../store/journalFilters';
import { getAllOrganizationsIds, getOrganizationsByIds } from '../../../store/filterData';
import {
    MultipleSelectSuggest,
    MultipleSelectSuggestItem,
    MultipleSelectSuggestProps,
} from '../../common/MultipleSelectSuggest';

const mapStateToProps = (state: State): MappedState => {
    const selectedIds: string[] = getAuthorOrganizationIds(state);
    const selected: MultipleSelectSuggestItem[] = getOrganizationsByIds(state, selectedIds).map(({ id, name }) => ({
        name,
        value: id,
    }));
    const restIds: string[] = without(getAllOrganizationsIds(state), ...selectedIds);
    const rest: MultipleSelectSuggestItem[] = getOrganizationsByIds(state, restIds).map(({ id, name }) => ({
        name,
        value: id,
    }));
    return { selected, rest };
};

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    onAddItem: (id: string) => dispatch(addAuthorOrganizationId(id)),
    onRemoveItem: (id: string) => dispatch(removeAuthorOrganizationId(id)),
});

const mergeProps = (
    { selected, rest }: MappedState,
    { onAddItem, onRemoveItem }: MappedActions,
    { className }: OrganizationSuggestExternalProps,
): MultipleSelectSuggestProps => ({
    className,
    selected,
    rest,
    title: 'Добавить организацию',
    onAddItem: ({ value }: MultipleSelectSuggestItem) => onAddItem(<string>value),
    onRemoveItem: ({ value }: MultipleSelectSuggestItem) => onRemoveItem(<string>value),
});

const withStore = connect(mapStateToProps, mapDispatchToProps, mergeProps);

/** "OrganizationSuggest" store component */
export const OrganizationSuggestStore: ComponentType<OrganizationSuggestExternalProps> =
    withStore(MultipleSelectSuggest);
