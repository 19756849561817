import type { OrganizationParams as Organization } from 'sber-marketing-types/admin';
import type { Action, RoleLine } from '../../api';

export interface PageState {
    pageData: PageData;
    filters: Filter[];
    sorting: SortingParams;
}

export interface PageData {
    organization: Organization;
    roleLines: RoleLine[];
    actions: Action[];
}

export const enum ColumnName {
    Id = 'id',
    Name = 'name',
    Permissions = 'permissions',
    Actions = 'actions',
}

export const enum ColumnHeaderType {
    Text = 'text',
    Filters = 'filters',
}

export const enum ColumnType {
    Text = 'text',
    Input = 'input',
    CheckboxList = 'checkboxList',
    Actions = 'actions',
}

export interface Filter {
    columnName: ColumnName;
    selectedValues: React.ReactText[];
}

export interface SortingParams {
    columnName: ColumnName;
    orderType: OrderType;
}

export const enum OrderType {
    Asc = 'asc',
    Desc = 'desc',
}
