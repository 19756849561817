// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-organizations-OrganizationsPage-_OrganizationsPage__root{padding:80px 50px 0}.src-client-layers-view-organizations-OrganizationsPage-_OrganizationsPage__button{display:flex;width:100%;height:100%;cursor:pointer;text-align:center;color:#9b9b9b;border:8px solid rgba(220,220,220,0.5);border-radius:8px;font-family:'Open Sans';font-size:14px;line-height:16px;align-items:center;justify-content:center}.src-client-layers-view-organizations-OrganizationsPage-_OrganizationsPage__card{width:100%;height:100%;cursor:pointer}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/organizations/OrganizationsPage/OrganizationsPage.scss"],"names":[],"mappings":"AAAA,iFACI,mBAAoB,CACvB,mFAGG,YAAa,CAEb,UAAW,CACX,WAAY,CAEZ,cAAe,CACf,iBAAkB,CAElB,aAAc,CACd,sCAA0C,CAC1C,iBAAkB,CAElB,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CAEjB,kBAAmB,CACnB,sBAAuB,CAC1B,iFAGG,UAAW,CACX,WAAY,CAEZ,cAAe","sourcesContent":[".root {\n    padding: 80px 50px 0;\n}\n\n.button {\n    display: flex;\n\n    width: 100%;\n    height: 100%;\n\n    cursor: pointer;\n    text-align: center;\n\n    color: #9b9b9b;\n    border: 8px solid rgba(220, 220, 220, 0.5);\n    border-radius: 8px;\n\n    font-family: 'Open Sans';\n    font-size: 14px;\n    line-height: 16px;\n\n    align-items: center;\n    justify-content: center;\n}\n\n.card {\n    width: 100%;\n    height: 100%;\n\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-organizations-OrganizationsPage-_OrganizationsPage__root",
	"button": "src-client-layers-view-organizations-OrganizationsPage-_OrganizationsPage__button",
	"card": "src-client-layers-view-organizations-OrganizationsPage-_OrganizationsPage__card"
};
export default ___CSS_LOADER_EXPORT___;
