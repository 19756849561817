import * as React from 'react';
import { LabeledSelect, SelectItem } from 'sber-marketing-ui';
import { WrongPasswordCount } from 'sber-marketing-types/system-admin';

import { WrongPasswordCountSelectTemplateProps } from './types';

const ITEMS: SelectItem[] = [
    {
        value: WrongPasswordCount.THREE_TIMES,
        label: 'Три',
    },
    {
        value: WrongPasswordCount.FIVE_TIMES,
        label: 'Пять',
    },
    {
        value: WrongPasswordCount.TEN_TIMES,
        label: 'Десять',
    },
];

const TITLE = 'Попыток ввода пароля до блокировки';

/** "RequestTimeoutSelect" template component */
export const WrongPasswordCountSelectTemplate: React.StatelessComponent<WrongPasswordCountSelectTemplateProps> = ({
    className,
    wrongPasswordCount,
    editWrongPasswordsCount,
}: WrongPasswordCountSelectTemplateProps): JSX.Element => (
    <div className={className}>
        <LabeledSelect
            title={TITLE}
            selectedValue={wrongPasswordCount}
            items={ITEMS}
            onSelection={editWrongPasswordsCount}
        />
    </div>
);
