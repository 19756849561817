import * as React from 'react';
import * as classnames from 'classnames';
import { Button_redesign } from 'sber-marketing-ui';

import * as styles from './ManageButtons.scss';
import { Link } from '../../common/Link';
import { ManageButtonsTemplateProps } from './types';

/** "ManageButtons" template component */
export const ManageButtonsTemplate: React.StatelessComponent<ManageButtonsTemplateProps> = ({
    className,
    downloadUrl,
    beginJournalClear,
}: ManageButtonsTemplateProps): JSX.Element => (
    <div className={classnames(styles.root, className)}>
        <Link to={downloadUrl} className={styles.item} target="_blank">
            Выгрузить в CSV
        </Link>
        <div className={styles.item}>
            <Button_redesign onClick={beginJournalClear}>Очистить журнал</Button_redesign>
        </div>
    </div>
);
