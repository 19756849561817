// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-common-SimpleSuggest-_SimpleSuggest__root{position:relative}.src-client-layers-view-common-SimpleSuggest-_SimpleSuggest__notFound{display:block;box-sizing:border-box;width:100%;height:27px;padding:5px 16px;user-select:none;color:#262626;font-size:11px;line-height:1.82}.src-client-layers-view-common-SimpleSuggest-_SimpleSuggest__inputWrap{width:100%}.src-client-layers-view-common-SimpleSuggest-_SimpleSuggest__itemsContainer{position:absolute;z-index:1;overflow-x:hidden;overflow-y:auto;width:100%;max-height:300px;outline:none;background-color:#ffffff;box-shadow:0 1px 6px 0 rgba(0,0,0,0.1)}.src-client-layers-view-common-SimpleSuggest-_SimpleSuggest__itemsContainer_hidden{display:none}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/common/SimpleSuggest/SimpleSuggest.scss"],"names":[],"mappings":"AAAA,kEACI,iBAAkB,CACrB,sEAGG,aAAc,CAEd,qBAAsB,CACtB,UAAW,CACX,WAAY,CACZ,gBAAiB,CAEjB,gBAAiB,CAEjB,aAAc,CAEd,cAAe,CACf,gBAAiB,CACpB,uEAGG,UAAW,CACd,4EAGG,iBAAkB,CAClB,SAAU,CAEV,iBAAkB,CAClB,eAAgB,CAEhB,UAAW,CACX,gBAAiB,CAEjB,YAAa,CACb,wBAAyB,CACzB,sCAAuC,CAC1C,mFAGG,YAAa","sourcesContent":[".root {\n    position: relative;\n}\n\n.notFound {\n    display: block;\n\n    box-sizing: border-box;\n    width: 100%;\n    height: 27px;\n    padding: 5px 16px;\n\n    user-select: none;\n\n    color: #262626;\n\n    font-size: 11px;\n    line-height: 1.82;\n}\n\n.inputWrap {\n    width: 100%;\n}\n\n.itemsContainer {\n    position: absolute;\n    z-index: 1;\n\n    overflow-x: hidden;\n    overflow-y: auto;\n\n    width: 100%;\n    max-height: 300px;\n\n    outline: none;\n    background-color: #ffffff;\n    box-shadow: 0 1px 6px 0 rgba(0,0,0,0.1);\n}\n\n.itemsContainer_hidden {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-common-SimpleSuggest-_SimpleSuggest__root",
	"notFound": "src-client-layers-view-common-SimpleSuggest-_SimpleSuggest__notFound",
	"inputWrap": "src-client-layers-view-common-SimpleSuggest-_SimpleSuggest__inputWrap",
	"itemsContainer": "src-client-layers-view-common-SimpleSuggest-_SimpleSuggest__itemsContainer",
	"itemsContainer_hidden": "src-client-layers-view-common-SimpleSuggest-_SimpleSuggest__itemsContainer_hidden"
};
export default ___CSS_LOADER_EXPORT___;
