import * as React from 'react';
import autobind from 'autobind-decorator';
import { Dispatch, connect } from 'react-redux';

import { OrganizationParams } from 'sber-marketing-types/admin';
import { RoleId, UserParams } from 'sber-marketing-types/backend';
import { UpdateOrganizationParams } from '../../../../store/organizations/types';

import { OrganizationFormContainer } from './OrganizationFormContainer';
import { State as StoreState } from '../../../../store';
import {
    loadCurrentOrganization,
    updateOrganization,
    resetCurrentOrganization,
} from '../../../../store/organizations/actions';
import { loadUsers } from '../../../../store/user/actions';
import { getCurrentOrganization, getCurrentOrganizationId } from '../../../../store/organizations/selectors';
import { OrganizationApi, UserApi } from '../../../../api';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {}

interface MapProps {
    id: string;
    organization: OrganizationParams;
}

interface DispatchProps {
    loadCurrentOrganization: (params: OrganizationParams) => void;
    loadUsers: (users: UserParams[]) => void;
    updateOrganization: (params: UpdateOrganizationParams) => void;
    resetCurrentOrganization: () => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class EditOrganizationFormContainer extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public async componentDidMount() {
        const { id, loadCurrentOrganization, loadUsers } = this.props;

        const [organization, admins] = await Promise.all([
            OrganizationApi.getOrganization(id),
            UserApi.getUserList({
                organizationIds: [id],
                roleIds: [RoleId.BusinessAdmin],
            }),
        ]);

        loadCurrentOrganization(organization);
        loadUsers(admins);
    }

    public render(): JSX.Element {
        const { name = '', executorIds = [], customerIds = [] } = this.props.organization;

        return React.createElement(OrganizationFormContainer, {
            blockTitle: 'Редактирование организации',
            confirmButtonText: 'Сохранить',
            name,
            executorIds,
            customerIds,
            onConfirmClick: this.onConfirmClick,
        });
    }

    @autobind
    protected async onConfirmClick() {
        const { id, name, executorIds, customerIds } = this.props.organization;

        await OrganizationApi.updateOrganization(id, {
            name,
            executorIds,
            customerIds,
        });

        this.props.updateOrganization({
            id,
            name,
            executorIds,
            customerIds,
        });

        this.props.resetCurrentOrganization();

        return {
            organizationId: id,
            organizationName: name,
        };
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        id: getCurrentOrganizationId(state),
        organization: getCurrentOrganization(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return {
        loadCurrentOrganization: (params: OrganizationParams) => dispatch(loadCurrentOrganization(params)),
        loadUsers: (users: UserParams[]) => dispatch(loadUsers(users)),
        updateOrganization: (params: UpdateOrganizationParams) => dispatch(updateOrganization(params)),
        resetCurrentOrganization: () => dispatch(resetCurrentOrganization()),
    };
}
