import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../../store/types';
import { getSort, editSort } from '../../../store/journalFilters';
import { MappedActions, MappedState, SortSelectStoreProps } from './types';
import { SortSelectTemplate } from './SortSelectTemplate';

const mapStateToProps = (state: State): MappedState => ({
    value: getSort(state),
});

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    onChange: (value) => dispatch(editSort(value)),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** "SortSelect" store component */
export const SortSelectStore: ComponentType<SortSelectStoreProps> = withStore(SortSelectTemplate);
