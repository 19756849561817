import { createElement, ComponentType, Component as ReactComponent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { omit } from 'lodash';

import { State, ItemLoadingStatus } from '../../../store/types';
import { getLoadingStatus, loadFilterData } from '../../../store/filterData';

interface MappedState {
    loadingStatus: ItemLoadingStatus;
}

interface MappedActions {
    loadFilterData(): void;
}

type Props<P extends {}> = P & MappedState & MappedActions;

const mapStateToProps = (state: State): MappedState => ({
    loadingStatus: getLoadingStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    loadFilterData: () => dispatch(loadFilterData(null)),
});

/**
 * High-Order Component (HOC) to mark components where filter data are required.
 * They will be automatically loaded. If server throws an error, request will be repeated.
 */
export function needFilterData<P extends {} = {}>(Component: ComponentType<P>): ComponentType<P> {
    const withStore = connect<MappedState, MappedActions, P, State>(mapStateToProps, mapDispatchToProps);
    const Wrapper = class extends ReactComponent<Props<P>> {
        public componentDidMount() {
            if (this.props.loadingStatus === ItemLoadingStatus.NOT_LOADED) {
                this.props.loadFilterData();
            }
        }
        public componentDidUpdate({ loadingStatus: prevLoadingStatus }: Props<P>) {
            const { loadingStatus } = this.props;
            if (loadingStatus === ItemLoadingStatus.ERROR && loadingStatus !== prevLoadingStatus) {
                this.props.loadFilterData();
            }
        }
        public render(): JSX.Element {
            return createElement(
                Component,
                <any>omit(this.props, ['loadingStatus', 'loadFilterData', 'children']),
                this.props.children,
            );
        }
    };
    return withStore(Wrapper);
}
