import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../../store/types';
import { getCurrentWrongPasswordsCount, editWrongPasswordsCount } from '../../../store/configuration';
import { MappedActions, MappedState, WrongPasswordCountSelectStoreProps } from './types';
import { WrongPasswordCountSelectTemplate } from './WrongPasswordCountSelectTemplate';

const mapStateToProps = (state: State): MappedState => ({
    wrongPasswordCount: getCurrentWrongPasswordsCount(state),
});

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    editWrongPasswordsCount: (value) => dispatch(editWrongPasswordsCount(value)),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** "WrongPasswordCountSelect" store component */
export const WrongPasswordCountSelectStore: ComponentType<WrongPasswordCountSelectStoreProps> = withStore(
    WrongPasswordCountSelectTemplate,
);
