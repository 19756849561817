import * as baseActions from './baseActions';

import { authRequired } from '../thunks';

/** Map of routes */
export const routes = {
    [baseActions.routeAuthorizationErrorPage.type]: '/auth-error',
    [baseActions.routeReportPage.type]: '/report',
    [baseActions.routeConfigurationPage.type]: {
        path: '/configuration',
        thunk: authRequired,
    },
    [baseActions.routeOrganizationsPage.type]: {
        path: '/(organizations)?',
        thunk: authRequired,
    },
    [baseActions.routeRolesPage.type]: {
        path: '/roles/:organizationId',
        thunk: authRequired,
    },
    [baseActions.routeJournalPage.type]: {
        path: '/journal',
        thunk: authRequired,
    },
};
