import { actionCreatorFactory } from 'typescript-fsa';
import {
    Configuration,
    RequestTimeout,
    PasswordTimeout,
    WrongPasswordCount,
    FileStore,
    TransitPasswordLifetime,
} from 'sber-marketing-types/system-admin';

const creator = actionCreatorFactory('CONFIGURATION');

// Async actions

/** Load configuration */
export const loadConfiguration = creator.async<null | undefined, Configuration, Error>('LOAD_CONFIGURATION');

/** Update configuration */
export const updateConfiguration = creator.async<null | undefined, Configuration, Error>('UPDATE_CONFIGURATION');

// Sync actions

/** Edit request timeout */
export const editRequestTimeout = creator<RequestTimeout>('EDIT_REQUEST_TIMEOUT');

/** Edit store */
export const editStore = creator<FileStore>('EDIT_STORE');

/** Edit password timeout */
export const editPasswordTimeout = creator<PasswordTimeout>('EDIT_PASSWORD_TIMEOUT');

/** Edit wrong passwords count */
export const editWrongPasswordsCount = creator<WrongPasswordCount>('EDIT_WRONG_PASSWORDS_COUNT');

/** Edit file splitting */
export const editFileSplitting = creator<number>('EDIT_FILE_SPLITTING');

/** Edit transit password lifetime */
export const editTransitPasswordLifetime = creator<TransitPasswordLifetime>('EDIT_TRANSIT_PASSWORD_LIFETIME');
