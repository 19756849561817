import * as React from 'react';
import * as classnames from 'classnames';

import * as styles from './OrganizationInfo.scss';
import { OrganizationInfoTemplateProps } from './types';

/** "OrganizationInfo" template component */
export const OrganizationInfoTemplate: React.StatelessComponent<OrganizationInfoTemplateProps> = ({
    className,
    id,
    found,
    name,
    withStats,
    users,
    activities,
}: OrganizationInfoTemplateProps): JSX.Element => (
    <div className={classnames(styles.root, className)}>
        <div className={styles.line}>
            <div className={styles.fieldName}>Id организации</div>
            <div>{id}</div>
        </div>
        {found && (
            <div className={styles.line}>
                <div className={styles.fieldName}>Название организации</div>
                <div>{name}</div>
            </div>
        )}
        {found && withStats && (
            <div className={styles.line}>
                <div className={styles.fieldName}>Пользователей</div>
                <div>{users}</div>
            </div>
        )}
        {found && withStats && (
            <div className={styles.line}>
                <div className={styles.fieldName}>Проектов</div>
                <div>{activities}</div>
            </div>
        )}
    </div>
);
