import * as qs from 'qs';
import isNumber from 'is-number';
import { QuerySerializer } from 'redux-first-router';
import { Dictionary, isArray } from 'lodash';

const isBooleanLike = (value: string): boolean => value === 'true' || value === 'false';

const processTypes = (query: Dictionary<string | string[]>): Dictionary<any> => {
    const result: Dictionary<any> = {};
    const keys: string[] = Object.keys(query);
    for (const key of keys) {
        const item = query[key];
        if (isArray(item)) {
            result[key] = item.map((value) => {
                let res: number | string | boolean;
                if (isNumber(value)) {
                    res = Number(value);
                } else if (isBooleanLike(value)) {
                    res = value !== 'true';
                } else {
                    res = value;
                }
                return res;
            });
        } else if (isNumber(item)) {
            result[key] = Number(item);
        } else if (isBooleanLike(<string>item)) {
            result[key] = item !== 'true';
        } else {
            result[key] = item;
        }
    }
    return result;
};

export const querySerializer: QuerySerializer = {
    stringify: (query: object) =>
        qs.stringify(query, {
            arrayFormat: 'brackets',
        }),
    parse: (query: string) => processTypes(qs.parse(query)),
};
