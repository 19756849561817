import { createSelector } from 'reselect';

import { OrganizationParams } from 'sber-marketing-types/admin';
import { State as State } from '../types';
import { OrganizationsPageState } from './types';

export const getOrganizationsPageState = (state: State): OrganizationsPageState => state.organizationsPage;

export const getOrganizations = createSelector(
    getOrganizationsPageState,
    (state: OrganizationsPageState): OrganizationParams[] => {
        return state.organizations;
    },
);

export const getLoadingStatus = createSelector(getOrganizationsPageState, (state: OrganizationsPageState): boolean => {
    return state.isLoading;
});

export const getCurrentOrganizationId = createSelector(
    getOrganizationsPageState,
    (state: OrganizationsPageState): string => {
        return state.currentOrganizationId;
    },
);

export const getCurrentOrganization = createSelector(
    getOrganizationsPageState,
    (state: OrganizationsPageState): OrganizationParams => {
        return state.currentOrganization;
    },
);
