import { isNil } from 'lodash';
import { stringify as qsStringify } from 'qs';
import { EventsRecordsListQuery, EventsRecordsListPage } from 'sber-marketing-types/system-admin';

import { HttpClient } from '../../lib';

/** Journal API service */
export class JournalApi {
    /** Auth API service instance */
    private static instance: JournalApi | undefined;
    /** Base URL path for requests */
    private static basePath = '/api/journal';
    /** HTTP Client instance */
    private http: HttpClient;
    /** @constructor */
    private constructor() {
        this.http = HttpClient.getInstance();
    }
    /** Get HTTP client service instance (creates if not exists) */
    public static getInstance(): JournalApi {
        if (isNil(JournalApi.instance)) {
            JournalApi.instance = new JournalApi();
        }
        return JournalApi.instance!;
    }
    /** Get filtered list of items */
    public getList(query: EventsRecordsListQuery): Promise<EventsRecordsListPage> {
        return this.http.get<EventsRecordsListQuery, EventsRecordsListPage>(`${JournalApi.basePath}/`, query);
    }
    /** Get download link for CSV file with full filtered list */
    public getListCSVDownloadLink(query: EventsRecordsListQuery): string {
        const queryString = qsStringify(query, {
            arrayFormat: 'brackets',
        });
        return `${this.http.getBaseUrl()}${JournalApi.basePath}/csv?${queryString}`;
    }
    /** Clear journal */
    public clearJournal(): Promise<void> {
        return this.http.delete(`${JournalApi.basePath}/`);
    }
}
