import { Success } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
    Configuration,
    RequestTimeout,
    PasswordTimeout,
    WrongPasswordCount,
    FileStore,
    TransitPasswordLifetime,
} from 'sber-marketing-types/system-admin';
import { defaultTo } from 'lodash';

import { loadConfiguration, updateConfiguration } from '../actions';
import { skipReducer } from '../../commonReducers';
import { DEFAULT_FILE_SPLITTING } from '../constants';

export const requestTimeoutFactory = () =>
    reducerWithInitialState<RequestTimeout>(RequestTimeout.HALF_HOUR)
        .cases([loadConfiguration.started, updateConfiguration.started], skipReducer)
        .cases([loadConfiguration.failed, updateConfiguration.failed], skipReducer)
        .cases(
            [loadConfiguration.done, updateConfiguration.done],
            (state: RequestTimeout, { result: { requestTimeout } }: Success<null | undefined, Configuration>) =>
                defaultTo(requestTimeout, RequestTimeout.ONE_HOUR),
        );

export const storeFactory = () =>
    reducerWithInitialState<FileStore>(FileStore.SELCDN)
        .cases([loadConfiguration.started, updateConfiguration.started], skipReducer)
        .cases([loadConfiguration.failed, updateConfiguration.failed], skipReducer)
        .cases(
            [loadConfiguration.done, updateConfiguration.done],
            (state: FileStore, { result: { store } }: Success<null | undefined, Configuration>) =>
                defaultTo(store, FileStore.SELCDN),
        );

export const passwordTimeoutFactory = () =>
    reducerWithInitialState<PasswordTimeout>(PasswordTimeout.ONE_MONTH)
        .cases([loadConfiguration.started, updateConfiguration.started], skipReducer)
        .cases([loadConfiguration.failed, updateConfiguration.failed], skipReducer)
        .cases(
            [loadConfiguration.done, updateConfiguration.done],
            (state: PasswordTimeout, { result: { passwordTimeout } }: Success<null | undefined, Configuration>) =>
                defaultTo(passwordTimeout, PasswordTimeout.TWELVE_MONTHS),
        );

export const wrongPasswordsCountFactory = () =>
    reducerWithInitialState<WrongPasswordCount>(WrongPasswordCount.THREE_TIMES)
        .cases([loadConfiguration.started, updateConfiguration.started], skipReducer)
        .cases([loadConfiguration.failed, updateConfiguration.failed], skipReducer)
        .cases(
            [loadConfiguration.done, updateConfiguration.done],
            (
                state: WrongPasswordCount,
                { result: { wrongPasswordsCount } }: Success<null | undefined, Configuration>,
            ) => defaultTo(wrongPasswordsCount, WrongPasswordCount.FIVE_TIMES),
        );

export const fileSplittingFactory = () =>
    reducerWithInitialState<number>(DEFAULT_FILE_SPLITTING)
        .cases([loadConfiguration.started, updateConfiguration.started], skipReducer)
        .cases([loadConfiguration.failed, updateConfiguration.failed], skipReducer)
        .cases(
            [loadConfiguration.done, updateConfiguration.done],
            (state: number, { result: { fileSplitting } }: Success<null | undefined, Configuration>) =>
                defaultTo(fileSplitting, DEFAULT_FILE_SPLITTING),
        );

export const transitPasswordLifetimeFactory = () =>
    reducerWithInitialState<TransitPasswordLifetime>(TransitPasswordLifetime.ONE_HOUR)
        .cases([loadConfiguration.started, updateConfiguration.started], skipReducer)
        .cases([loadConfiguration.failed, updateConfiguration.failed], skipReducer)
        .cases(
            [loadConfiguration.done, updateConfiguration.done],
            (
                state: TransitPasswordLifetime,
                { result: { transitPasswordLifetime } }: Success<null | undefined, Configuration>,
            ) => defaultTo(transitPasswordLifetime, TransitPasswordLifetime.ONE_HOUR),
        );
