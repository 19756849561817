import { isNil } from 'lodash';
import { FilterData } from 'sber-marketing-types/system-admin';

import { HttpClient } from '../../lib';

export class FilterDataApi {
    /** Auth API service instance */
    private static instance: FilterDataApi | undefined;
    /** Base URL path for requests */
    private static basePath = '/api/filter-data';
    /** HTTP Client instance */
    private http: HttpClient;
    /** @constructor */
    private constructor() {
        this.http = HttpClient.getInstance();
    }
    /** Get HTTP client service instance (creates if not exists) */
    public static getInstance(): FilterDataApi {
        if (isNil(FilterDataApi.instance)) {
            FilterDataApi.instance = new FilterDataApi();
        }
        return FilterDataApi.instance!;
    }
    /** Get filter data */
    public getFilterData(): Promise<FilterData> {
        return this.http.get<{}, FilterData>(FilterDataApi.basePath);
    }
}
