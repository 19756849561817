import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../../store/types';
import { getEventsRecordsCsvDownloadUrl } from '../../../store/journalFilters';
import { beginJournalClear } from '../../../store/journalClear';
import { MappedActions, MappedState, ManageButtonsStoreProps } from './types';
import { ManageButtonsTemplate } from './ManageButtonsTemplate';

const mapStateToProps = (state: State): MappedState => ({
    downloadUrl: getEventsRecordsCsvDownloadUrl(state),
});

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    beginJournalClear: () => dispatch(beginJournalClear()),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** "ManageButtons" store component */
export const ManageButtonsStore: ComponentType<ManageButtonsStoreProps> = withStore(ManageButtonsTemplate);
