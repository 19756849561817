import * as React from 'react';
import classNames from 'classnames';

import * as style from './ActionsCell.scss';

import { DynamicIcon, Icon, IconType } from 'sber-marketing-ui';
import { ConfirmModal } from '../../ConfirmModal';

interface Props {
    lineIsNew: boolean;
    lineHasChanges: boolean;
    saveDisabled?: boolean;
    onCreateButtonClick: () => void;
    onCancelCreationButtonClick: () => void;
    onCopyButtonClick: () => void;
    onDeleteConfirm: () => void;
    onSaveButtonClick: () => void;
    onCancelEditButtonClick: () => void;
}

export const ActionsCell = ({
    lineIsNew,
    lineHasChanges,
    saveDisabled,
    onCreateButtonClick,
    onCancelCreationButtonClick,
    onCopyButtonClick,
    onDeleteConfirm,
    onSaveButtonClick,
    onCancelEditButtonClick,
}: Props): JSX.Element => {
    const [displayDeletionModal, setDeletionModalStatus] = React.useState(false);

    const onDeleteButtonClick = React.useCallback(() => {
        setDeletionModalStatus(true);
    }, []);

    const onModalConfirm = React.useCallback(() => {
        onDeleteConfirm();
        setDeletionModalStatus(false);
    }, []);

    const onModalClose = React.useCallback(() => {
        setDeletionModalStatus(false);
    }, []);

    const disabled = false;

    return (
        <div className={style.root}>
            {!lineIsNew && !lineHasChanges && (
                <>
                    <div title={'Копировать'} className={style.copyButton} onClick={onCopyButtonClick}>
                        <DynamicIcon
                            common={{ svgSize: 24 }}
                            normal={{ type: IconType.COPY }}
                            hover={{ type: IconType.COPY_HOVER }}
                        />
                    </div>

                    <div className={style.separator} />

                    <div title={'Удалить'} className={style.deleteButton} onClick={onDeleteButtonClick}>
                        <Icon type={IconType.DELETE_V2} svgSize={24} />
                    </div>
                </>
            )}

            {lineIsNew && (
                <div className={style.newLineActions}>
                    <div
                        className={classNames(style.createButton, saveDisabled && style.disabled)}
                        onClick={!saveDisabled ? onCreateButtonClick : null}
                    >
                        {disabled && (
                            <div className={style.warningIcon}>
                                <Icon type={IconType.WARNING_IN_CIRCLE} svgSize={16} />
                            </div>
                        )}
                        Создать
                    </div>

                    <div className={style.cancelButton} onClick={onCancelCreationButtonClick}>
                        Отменить
                    </div>
                </div>
            )}

            {lineHasChanges && (
                <div className={style.changedLineActions}>
                    <div
                        className={classNames(style.saveButton, saveDisabled && style.disabled)}
                        onClick={!saveDisabled ? onSaveButtonClick : null}
                    >
                        Сохранить
                    </div>

                    <div className={style.cancelButton} onClick={onCancelEditButtonClick}>
                        Отменить
                    </div>
                </div>
            )}

            {displayDeletionModal && (
                <ConfirmModal
                    title={'Внимание!'}
                    message={'Правило доступа будет удалено'}
                    confirmButtonTitle={'Удалить'}
                    closeButtonTitle={'Отменить'}
                    onConfirm={onModalConfirm}
                    onClose={onModalClose}
                />
            )}
        </div>
    );
};
