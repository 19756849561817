// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-journal-ManageButtons-_ManageButtons__root{display:flex;width:246px;height:26px;justify-content:space-between}.src-client-layers-view-journal-ManageButtons-_ManageButtons__item{width:118px;height:26px}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/journal/ManageButtons/ManageButtons.scss"],"names":[],"mappings":"AAGA,mEACI,YAAa,CAEb,WAAO,CACP,WAAQ,CAER,6BAA8B,CACjC,mEAGG,WAAO,CACP,WAAQ","sourcesContent":["$buttonWidth: 118;\n$buttonHeight: 26;\n\n.root {\n    display: flex;\n\n    width: #{$buttonWidth * 2 + 10}px;\n    height: #{$buttonHeight}px;\n\n    justify-content: space-between;\n}\n\n.item {\n    width: #{$buttonWidth}px;\n    height: #{$buttonHeight}px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-journal-ManageButtons-_ManageButtons__root",
	"item": "src-client-layers-view-journal-ManageButtons-_ManageButtons__item"
};
export default ___CSS_LOADER_EXPORT___;
