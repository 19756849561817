// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-report-_ReportPage__root{margin:32px;padding:8px 38px 32px;background-color:#ffffff;font-family:'Open Sans'}.src-client-layers-view-report-_ReportPage__title{margin-top:32px;margin-bottom:24px;user-select:none;pointer-events:none;color:#262626;font-family:'Open Sans';font-size:16px;font-weight:600}.src-client-layers-view-report-_ReportPage__datepickersWrapper{display:flex;margin-top:32px;justify-content:space-between}.src-client-layers-view-report-_ReportPage__datepickerWrapper{width:40%}.src-client-layers-view-report-_ReportPage__buttonWrapper{width:118px;height:26px;margin-top:28px}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/report/ReportPage.scss"],"names":[],"mappings":"AAAA,iDACI,WAAY,CACZ,qBAAsB,CAEtB,wBAAyB,CAEzB,uBAAwB,CAC3B,kDAGG,eAAgB,CAChB,kBAAmB,CAEnB,gBAAiB,CACjB,mBAAoB,CAEpB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB,CACnB,+DAGG,YAAa,CAEb,eAAgB,CAEhB,6BAA8B,CACjC,8DAGG,SAAU,CACb,0DAGG,WAAY,CACZ,WAAY,CACZ,eAAgB","sourcesContent":[".root {\n    margin: 32px;\n    padding: 8px 38px 32px;\n\n    background-color: #ffffff;\n\n    font-family: 'Open Sans';\n}\n\n.title {\n    margin-top: 32px;\n    margin-bottom: 24px;\n\n    user-select: none;\n    pointer-events: none;\n\n    color: #262626;\n\n    font-family: 'Open Sans';\n    font-size: 16px;\n    font-weight: 600;\n}\n\n.datepickersWrapper {\n    display: flex;\n\n    margin-top: 32px;\n\n    justify-content: space-between;\n}\n\n.datepickerWrapper {\n    width: 40%;\n}\n\n.buttonWrapper {\n    width: 118px;\n    height: 26px;\n    margin-top: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-report-_ReportPage__root",
	"title": "src-client-layers-view-report-_ReportPage__title",
	"datepickersWrapper": "src-client-layers-view-report-_ReportPage__datepickersWrapper",
	"datepickerWrapper": "src-client-layers-view-report-_ReportPage__datepickerWrapper",
	"buttonWrapper": "src-client-layers-view-report-_ReportPage__buttonWrapper"
};
export default ___CSS_LOADER_EXPORT___;
