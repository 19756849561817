import * as React from 'react';

import { CurrentPage } from '../../../store/routing';
import { JournalPage } from '../../journal/JournalPage';
import { ReportPage } from '../../report';
import { RolesPage } from '../../RolesPage';
import { OrganizationsPage } from '../../organizations/OrganizationsPage';
import { ConfigurationPage } from '../../configuration/ConfigurationPage';
import { RouterTemplateProps } from './types';
import { AuthErrorPage } from '../../auth/AuthErrorPage';
import { GlobalPreloaderPage } from '../GlobalPreloaderPage';

export const RouterTemplate: React.FunctionComponent<RouterTemplateProps> = ({
    isAuthChecking,
    pageType,
}: RouterTemplateProps): JSX.Element => {
    let result: JSX.Element = null;

    if (!isAuthChecking) {
        switch (pageType) {
            case CurrentPage.AUTHORIZATION_ERROR:
                result = <AuthErrorPage />;
                break;
            case CurrentPage.CONFIGURATION:
                result = <ConfigurationPage />;
                break;
            case CurrentPage.JOURNAL:
                result = <JournalPage />;
                break;
            case CurrentPage.ORGANIZATIONS:
                result = <OrganizationsPage />;
                break;
            case CurrentPage.REPORT:
                result = <ReportPage />;
                break;
            case CurrentPage.ROLES:
                result = <RolesPage />;
                break;
            default:
                window.location.href = '/organizations';
        }
    } else {
        result = <GlobalPreloaderPage />;
    }

    return result;
};
