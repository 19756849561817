import { actionCreatorFactory } from 'typescript-fsa';

const creator = actionCreatorFactory('JOURNAL_CLEAR');

// Sync actions

/** Begin journal clear (open modal) */
export const beginJournalClear = creator('BEGIN_JOURNAL_CLEAR');

/** End journal clear (close modal) */
export const endJournalClear = creator('END_JOURNAL_CLEAR');

// Async actions

/** Clear journal */
export const clearJournal = creator.async<null | undefined, void, Error>('CLEAR_JOURNAL');
