import { combineReducers } from 'redux';

import { loadConfiguration, updateConfiguration } from '../actions';
import { ConfigurationState } from '../types';
import {
    loadingStatusReducerFactory,
    asyncActionAxiosErrorCodeReducer,
    asyncActionErrorReducerFactory,
} from '../../commonReducers';
import { previous } from './previous';
import { current } from './current';

const status = loadingStatusReducerFactory(loadConfiguration, updateConfiguration);

const errorCode = asyncActionAxiosErrorCodeReducer(loadConfiguration, updateConfiguration);

const error = asyncActionErrorReducerFactory(loadConfiguration, updateConfiguration);

export const configuration = combineReducers<ConfigurationState>({
    status,
    errorCode,
    error,
    previous,
    current,
});
