import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../../store/types';
import { getCurrentTransitPasswordLifetime, editTransitPasswordLifetime } from '../../../store/configuration';
import { TransitPasswordLifetimeSelectStoreProps, MappedActions, MappedState } from './types';
import { TransitPasswordLifetimeSelectTemplate } from './TransitPasswordLifetimeSelectTemplate';

const mapStateToProps = (state: State): MappedState => ({
    transitPasswordLifetime: getCurrentTransitPasswordLifetime(state),
});

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    editTransitPasswordLifetime: (value) => dispatch(editTransitPasswordLifetime(value)),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** "TransitPasswordLifetimeSelect" store component */
export const TransitPasswordLifetimeSelectStore: ComponentType<TransitPasswordLifetimeSelectStoreProps> = withStore(
    TransitPasswordLifetimeSelectTemplate,
);
