import * as url from 'url';
import { isNil } from 'lodash';
import { IsAuthorizedResponse, LeftTimeResponse } from 'sber-marketing-types/system-admin';

import { HttpClient } from '../../lib';

/** Auth API service */
export class AuthApi {
    /** Auth API service instance */
    private static instance: AuthApi | undefined;
    /** Base URL path for requests */
    private static basePath = '/api/auth';
    /** HTTP Client instance */
    private http: HttpClient;
    /** @constructor */
    private constructor() {
        this.http = HttpClient.getInstance();
    }
    /** Get HTTP client service instance (creates if not exists) */
    public static getInstance(): AuthApi {
        if (isNil(AuthApi.instance)) {
            AuthApi.instance = new AuthApi();
        }
        return AuthApi.instance!;
    }
    /** Get authorization status data */
    public isAuthorized(): Promise<IsAuthorizedResponse> {
        return this.http.get<{}, IsAuthorizedResponse>(`${AuthApi.basePath}/is-authorized`);
    }
    /** Logout user */
    public logout(): void {
        const { protocol, host } = url.parse(window.location.href);
        window.location.href = url.format({
            protocol,
            host,
            pathname: `${AuthApi.basePath}/logout`,
        });
    }
    /** User's session expiring time left */
    public async leftTime(): Promise<number> {
        const { leftTime } = await this.http.get<{}, LeftTimeResponse>(`${AuthApi.basePath}/left-time`);
        return leftTime;
    }
    /** Login user */
    public login(from: string): void {
        const { protocol, host } = url.parse(window.location.href);
        window.location.href = url.format({
            protocol,
            host,
            pathname: `${AuthApi.basePath}/login`,
            query: {
                from: encodeURIComponent(from),
            },
        });
    }
}
