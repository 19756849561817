import { actionCreatorFactory } from 'typescript-fsa';

const creator = actionCreatorFactory('AUTH_ERROR');

/** Set user's first name */
export const setUserFirstName = creator<string | null>('SET_USER_FIRST_NAME');

/** Set user's second name */
export const setUserSecondName = creator<string | null>('SET_USER_SECOND_NAME');

/** Set authorization error type */
export const setType = creator<'not-authorized' | 'no-access' | 'other'>('SET_TYPE');
