import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { without } from 'lodash';

import { MappedState, MappedActions, UserEmailsSuggestExternalProps } from './types';
import { State } from '../../../store/types';
import { getAuthorEmails, removeAuthorEmail, addAuthorEmail } from '../../../store/journalFilters';
import { getAllUsersEmails, findUsersByEmails } from '../../../store/filterData';
import {
    MultipleSelectSuggest,
    MultipleSelectSuggestItem,
    MultipleSelectSuggestProps,
} from '../../common/MultipleSelectSuggest';

const mapStateToProps = (state: State): MappedState => {
    const selectedEmails: string[] = getAuthorEmails(state);
    const selected: MultipleSelectSuggestItem[] = findUsersByEmails(state, selectedEmails).map(({ email }) => ({
        name: email,
        value: email,
    }));
    const restEmails: string[] = without(getAllUsersEmails(state), ...selectedEmails);
    const rest: MultipleSelectSuggestItem[] = findUsersByEmails(state, restEmails).map(({ email }) => ({
        name: email,
        value: email,
    }));
    return { selected, rest };
};

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    onAddItem: (email: string) => dispatch(addAuthorEmail(email)),
    onRemoveItem: (email: string) => dispatch(removeAuthorEmail(email)),
});

const mergeProps = (
    { selected, rest }: MappedState,
    { onAddItem, onRemoveItem }: MappedActions,
    { className }: UserEmailsSuggestExternalProps,
): MultipleSelectSuggestProps => ({
    className,
    selected,
    rest,
    title: 'Добавить логин или электронную почту',
    onAddItem: ({ value }: MultipleSelectSuggestItem) => onAddItem(<string>value),
    onRemoveItem: ({ value }: MultipleSelectSuggestItem) => onRemoveItem(<string>value),
});

const withStore = connect(mapStateToProps, mapDispatchToProps, mergeProps);

/** "UserEmailsSuggest" store component */
export const UserEmailsSuggestStore: ComponentType<UserEmailsSuggestExternalProps> = withStore(MultipleSelectSuggest);
