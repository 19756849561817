import * as React from 'react';
import * as classnames from 'classnames';
import { To } from 'redux-first-router-link';
import { ButtonTheme_redesign } from 'sber-marketing-ui';

import * as styles from './JournalPagination.scss';
import { JournalPaginationTemplateProps } from './types';
import { Link } from '../../common/Link';

/** "JournalPagination" template component */
export const JournalPaginationTemplate: React.StatelessComponent<JournalPaginationTemplateProps> = ({
    className,
    currentPage,
    countPages,
    first,
    last,
    next,
    previous,
    loadFirstJournalPage,
    loadPreviousJournalPage,
    loadNextJournalPage,
    loadLastJournalPage,
}: JournalPaginationTemplateProps): JSX.Element => (
    <div className={classnames(styles.root, className)}>
        <div>
            {first && (
                <PaginationButton to={first} onClick={loadFirstJournalPage}>
                    В начало
                </PaginationButton>
            )}
            {previous && (
                <PaginationButton to={previous} onClick={loadPreviousJournalPage}>
                    Назад
                </PaginationButton>
            )}
            {next && (
                <PaginationButton to={next} onClick={loadNextJournalPage}>
                    Вперед
                </PaginationButton>
            )}
            {last && (
                <PaginationButton to={last} onClick={loadLastJournalPage}>
                    В конец
                </PaginationButton>
            )}
        </div>
        <div>
            <span className={styles.pageNumber}>{currentPage}</span>
            &nbsp;страница из&nbsp;
            <span className={styles.pageNumber}>{countPages}</span>
        </div>
    </div>
);

/** "PaginationButton" component properties */
interface PaginationButtonProps {
    /** Target action */
    to: To;
    /** Click event handler */
    onClick: React.MouseEventHandler<HTMLElement>;
    /** Children */
    children?: string | JSX.Element;
}

/** Pagination button component */
const PaginationButton: React.StatelessComponent<PaginationButtonProps> = ({
    to,
    onClick,
    children,
}: PaginationButtonProps): JSX.Element => (
    <Link className={styles.button} theme={ButtonTheme_redesign.Ghost} to={to} onClick={onClick}>
        {children}
    </Link>
);
