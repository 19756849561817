import * as React from 'react';
import { Provider } from 'react-redux';

import { store } from '../../../store';
import { SessionControl } from '../SessionControl';
import { Router } from '../Router';

export const AppTemplate: React.FunctionComponent = (): JSX.Element => (
    <Provider store={store}>
        <SessionControl>
            <Router />
        </SessionControl>
    </Provider>
);
