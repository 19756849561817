// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-common-ErrorWithRetry-_ErrorWithRetry__root{display:flex;flex-direction:column;align-items:flex-start;justify-content:flex-start}.src-client-layers-view-common-ErrorWithRetry-_ErrorWithRetry__title{font-size:30px}.src-client-layers-view-common-ErrorWithRetry-_ErrorWithRetry__error{overflow-x:auto;width:100%}.src-client-layers-view-common-ErrorWithRetry-_ErrorWithRetry__retryButtonWrap{display:flex;width:100%;height:26px;align-items:center;justify-content:flex-end}.src-client-layers-view-common-ErrorWithRetry-_ErrorWithRetry__error,.src-client-layers-view-common-ErrorWithRetry-_ErrorWithRetry__retryButtonWrap{margin-top:16px}.src-client-layers-view-common-ErrorWithRetry-_ErrorWithRetry__retryButton{width:118px;height:26px}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/common/ErrorWithRetry/ErrorWithRetry.scss"],"names":[],"mappings":"AAAA,oEACI,YAAa,CACb,qBAAsB,CAEtB,sBAAuB,CACvB,0BAA2B,CAC9B,qEAGG,cAAe,CAClB,qEAGG,eAAgB,CAEhB,UAAW,CACd,+EAGG,YAAa,CAEb,UAAW,CACX,WAAY,CAEZ,kBAAmB,CACnB,wBAAyB,CAC5B,oJAIG,eAAgB,CACnB,2EAGG,WAAY,CACZ,WAAY","sourcesContent":[".root {\n    display: flex;\n    flex-direction: column;\n\n    align-items: flex-start;\n    justify-content: flex-start;\n}\n\n.title {\n    font-size: 30px;\n}\n\n.error {\n    overflow-x: auto;\n\n    width: 100%;\n}\n\n.retryButtonWrap {\n    display: flex;\n\n    width: 100%;\n    height: 26px;\n\n    align-items: center;\n    justify-content: flex-end;\n}\n\n.error,\n.retryButtonWrap {\n    margin-top: 16px;\n}\n\n.retryButton {\n    width: 118px;\n    height: 26px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-common-ErrorWithRetry-_ErrorWithRetry__root",
	"title": "src-client-layers-view-common-ErrorWithRetry-_ErrorWithRetry__title",
	"error": "src-client-layers-view-common-ErrorWithRetry-_ErrorWithRetry__error",
	"retryButtonWrap": "src-client-layers-view-common-ErrorWithRetry-_ErrorWithRetry__retryButtonWrap",
	"retryButton": "src-client-layers-view-common-ErrorWithRetry-_ErrorWithRetry__retryButton"
};
export default ___CSS_LOADER_EXPORT___;
