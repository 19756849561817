import axios, { AxiosInstance } from 'axios';
import { isNil } from 'lodash';

/** HTTP client service */
export class HttpClient {
    /** HTTP client instance */
    private static instance: HttpClient | undefined;
    /** "axios" HTTP client instance */
    private axios: AxiosInstance;
    /** @constructor */
    private constructor() {
        this.axios = axios.create({
            baseURL: window['BASE_URL'] || window.location.origin,
        });
    }
    /** Get HTTP client service instance (creates if not exists) */
    public static getInstance(): HttpClient {
        if (isNil(HttpClient.instance)) {
            HttpClient.instance = new HttpClient();
        }
        return HttpClient.instance!;
    }
    /** Return base URL */
    public getBaseUrl(): string {
        return this.axios.defaults.baseURL;
    }
    /** Send GET HTTP request */
    public async get<Q extends {} = {}, R = any>(path: string, query?: Q, oterParams?: any): Promise<R> {
        const { data } = await this.axios.get<R>(path, {
            params: query,
            ...oterParams,
            headers: {
                'Cache-Control': 'no-cache, no-store, must-reinvalidate',
                Pragma: 'no-cache',
                Expires: 0,
            },
        });
        return data;
    }
    /** Send POST HTTP request */
    public async post<B extends {} = {}, Q extends {} = {}, R = any>(path: string, body?: B, query?: Q): Promise<R> {
        const { data } = await this.axios.post<R>(path, body, { params: query });
        return data;
    }
    /** Send PUT HTTP request */
    public async put<B extends {} = {}, Q extends {} = {}, R = any>(path: string, body?: B, query?: Q): Promise<R> {
        const { data } = await this.axios.put<R>(path, body, { params: query });
        return data;
    }
    /** Send DELETE HTTP request */
    public async delete<Q extends {} = {}>(path: string, query?: Q): Promise<void> {
        await this.axios.delete(path, { params: query });
    }
}
