import * as React from 'react';
import onClickOutside from 'react-onclickoutside';
import autobind from 'autobind-decorator';

import { OrganizationCard } from './OrganizationCard';

interface Props {
    name: string;
    userCount: number;
    activityCount: number;
    onEditClick: () => void;
    onDeleteClick: () => void;
}

interface State {
    isMenuOpened: boolean;
}

@(onClickOutside as any)
export class OrganizationCardContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isMenuOpened: false,
        };
    }

    public render(): JSX.Element {
        const { name, userCount, activityCount, onEditClick, onDeleteClick } = this.props;
        const { isMenuOpened } = this.state;

        return React.createElement(OrganizationCard, {
            name,
            userCount,
            activityCount,
            isMenuOpened,
            onOpenerClick: this.onOpenerClick,
            onEditClick,
            onDeleteClick,
        });
    }

    @autobind
    public handleClickOutside() {
        this.setState({
            isMenuOpened: false,
        });
    }

    @autobind
    protected onOpenerClick(event: React.MouseEvent<HTMLDivElement>) {
        event.stopPropagation();

        this.setState({
            isMenuOpened: !this.state.isMenuOpened,
        });
    }
}
