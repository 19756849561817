import * as React from 'react';
import * as lodash from 'lodash';
import classNames from 'classnames';

import * as style from './CheckboxListCell.scss';

import { CustomScrollbar_new as CustomScrollbar, Icon, IconType } from 'sber-marketing-ui';
import { CellEditorWrapper } from '../CellEditorWrapper';

const MAX_SCROLLBAR_HEIGHT = 320;

interface Props {
    entityName: string;
    items: CheckboxItem[];
    selectedValues: React.ReactText[];
    emptyListMessage?: string;
    onValueChange: (value: React.ReactText[]) => void;
}

export interface CheckboxItem {
    title: string;
    value: React.ReactText;
}

export const CheckboxListCellEdit = ({
    entityName,
    items,
    selectedValues = [],
    emptyListMessage,
    onValueChange,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.title}>{makeTitle(entityName, items, selectedValues)}</div>

            <div className={classNames(style.openerIcon, style.opened)}>
                <Icon type={IconType.TRIANGLE8_UP} svgSize={8} />
            </div>

            <CellEditorWrapper>
                <div className={style.content}>
                    {!lodash.isEmpty(items) && (
                        <CustomScrollbar maxHeight={MAX_SCROLLBAR_HEIGHT} hideScrollX freezeScrollX>
                            {items.map((item) => (
                                <div
                                    key={item.value}
                                    className={style.item}
                                    onClick={() => onValueChange(lodash.xor(selectedValues, [item.value]))}
                                >
                                    <div className={style.checkIcon}>
                                        <Icon
                                            type={
                                                selectedValues.includes(item.value)
                                                    ? IconType.CHECKBOX24_CHECKED
                                                    : IconType.CHECKBOX24_UNCHECKED
                                            }
                                            svgSize={22}
                                        />
                                    </div>

                                    {item.title}
                                </div>
                            ))}
                        </CustomScrollbar>
                    )}

                    {lodash.isEmpty(items) && <div className={style.emptyListMessage}>{emptyListMessage}</div>}
                </div>
            </CellEditorWrapper>
        </div>
    );
};

function makeTitle(entityName: string, items: CheckboxItem[], selectedValues: React.ReactText[]) {
    if (selectedValues.length == 0) {
        return '—';
    }

    const selectedItems = items.filter((item) => selectedValues.includes(item.value));

    if (selectedItems.length == 1 || selectedItems.length == 2) {
        return (
            <>
                <div className={style.titleItem}>{selectedItems[0].title}</div>

                {selectedItems.length == 2 && <div className={style.titleItem}>{selectedItems[1].title}</div>}
            </>
        );
    }

    return (
        <div className={style.itemsCount}>
            {entityName}: {selectedItems.length}
        </div>
    );
}
