import { axios, AxiosResponse } from '../../lib/axios';

const BASE_URL = '/api/esa';

export class EsaApi {
    public static async getLogoutPage(): Promise<string> {
        const res: AxiosResponse<string> = await axios.get(`${BASE_URL}/session/end`);

        return res.data;
    }

    public static async logout(data: FormDataEntryValue): Promise<void> {
        await axios.post(`${BASE_URL}/session/end`, data, {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
            },
        });
    }
}
