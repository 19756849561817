import * as lodash from 'lodash';

import { ColumnName, ColumnWidths, LineHeights, LineId, Size } from '../../types';

const CELLS_SPACING = 1;

export class Virtualizer {
    private viewportSize: Size;

    public setViewportSize(size: Size): void {
        this.viewportSize = size;
    }

    public getVisibleColumnsIndexes(columns: ColumnName[], columnWidths: ColumnWidths, scroll: number): number[] {
        return this.getVisibleItems<ColumnName>(columns, columnWidths, scroll, this.viewportSize.width);
    }

    public getVisibleLinesIndexes(lineIds: LineId[], lineHeights: LineHeights, scroll: number): number[] {
        return this.getVisibleItems<LineId>(lineIds, lineHeights, scroll, this.viewportSize.height);
    }

    private getVisibleItems<T extends number | string>(
        items: T[],
        itemsSize: Record<T, number>,
        scroll: number,
        viewportSize: number,
    ): number[] {
        let viewportStartCellIndex = 0;
        let viewportEndCellIndex = 0;

        const viewportStart = scroll;
        const viewportEnd = scroll + viewportSize;

        let sizeSum = 0;
        let itemIndex = 0;

        while (sizeSum < viewportStart && itemIndex < items.length + 1) {
            const itemName = items[itemIndex];

            sizeSum += itemsSize[itemName] + CELLS_SPACING;
            itemIndex += 1;
        }

        viewportStartCellIndex = sizeSum == viewportStart ? itemIndex : itemIndex - 1;

        while (sizeSum < viewportEnd && itemIndex < items.length + 1) {
            const itemName = items[itemIndex];

            sizeSum += itemsSize[itemName] + CELLS_SPACING;
            itemIndex += 1;
        }

        viewportEndCellIndex = sizeSum - CELLS_SPACING == viewportEnd ? itemIndex : itemIndex - 1;

        return lodash.range(viewportStartCellIndex, viewportEndCellIndex + 1);
    }
}
