import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../../store/types';
import { getPageLength, editPageLength } from '../../../store/journalFilters';
import { MappedActions, MappedState, PageSizeSelectStoreProps } from './types';
import { PageSizeSelectTemplate } from './PageSizeSelectTemplate';

const mapStateToProps = (state: State): MappedState => ({
    value: getPageLength(state),
});

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    onChange: (pageSize) => dispatch(editPageLength(pageSize)),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** "PageSizeSelect" store component */
export const PageSizeSelectStore: ComponentType<PageSizeSelectStoreProps> = withStore(PageSizeSelectTemplate);
