import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../../store/types';
import { getLoadingStatus, loadConfiguration } from '../../../store/configuration';
import { ConfigurationEditorStoreProps, MappedActions, MappedState } from './types';
import { ConfigurationEditorContainer } from './ConfigurationEditorContainer';

const mapStateToProps = (state: State): MappedState => ({
    status: getLoadingStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    loadConfiguration: () => dispatch(loadConfiguration(null)),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** "ConfigurationEditor" store component */
export const ConfigurationEditorStore: ComponentType<ConfigurationEditorStoreProps> =
    withStore(ConfigurationEditorContainer);
