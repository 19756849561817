import * as React from 'react';
import * as classnames from 'classnames';
import RouterLink, { To } from 'redux-first-router-link';
import { Button_redesign } from 'sber-marketing-ui';

import * as styles from './Link.scss';

/** "Link" component properties */
export interface LinkProps {
    /** Additional CSS class */
    className?: string;
    /** Button theme */
    theme?: string;
    /** Link address */
    to: To;
    /** Child elements */
    children?: string | JSX.Element;
    /** Click event handler */
    onClick?: React.EventHandler<React.MouseEvent<HTMLElement>>;
    /** Link target */
    target?: string;
}

/** "Link" component */
export const Link: React.FunctionComponent<LinkProps> = ({
    className,
    theme,
    to,
    children,
    onClick,
    target,
}: LinkProps): JSX.Element => (
    <RouterLink className={classnames(styles.root, className)} to={to} onClick={onClick} target={target}>
        <Button_redesign theme={theme}>{children}</Button_redesign>
    </RouterLink>
);
