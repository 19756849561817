import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { Configuration } from 'sber-marketing-types/system-admin';

import {
    loadConfiguration as loadConfigurationAction,
    updateConfiguration as updateConfigurationAction,
} from './actions';
import { State } from '../types';
import { ConfigurationApi } from '../../api';
import { getConfigurationUpdateBody, isConfigurationChanged, getCurrentConfiguration } from './selectors';

/** Load configuration */
export const loadConfiguration = bindThunkAction<State, null | undefined, Configuration, Error>(
    loadConfigurationAction,
    () => ConfigurationApi.getInstance().getConfiguration(),
);

/** Update configuration */
export const updateConfiguration = bindThunkAction<State, null | undefined, Configuration, Error>(
    updateConfigurationAction,
    (payload, dispatch, getState) =>
        isConfigurationChanged(getState())
            ? ConfigurationApi.getInstance().updateConfiguration(getConfigurationUpdateBody(getState()))
            : Promise.resolve(getCurrentConfiguration(getState())),
);
