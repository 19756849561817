import autobind from 'autobind-decorator';
import { Component, createElement } from 'react';

import { JournalContainerProps } from './types';
import { JournalTemplate } from './JournalTemplate';

/** "Journal" container component */
export class JournalContainer extends Component<JournalContainerProps> {
    /** @override */
    public componentDidMount() {
        const { loadJournalPage, pageNumber } = this.props;
        loadJournalPage(pageNumber);
    }
    /** @override */
    public render(): JSX.Element {
        const { isLoading, error, className } = this.props;
        return createElement(JournalTemplate, { className, isLoading, error, onRetryClick: this.onRetryClick });
    }
    /** Retry button click handler */
    @autobind
    private onRetryClick(): void {
        const { loadJournalPage, pageNumber } = this.props;
        loadJournalPage(pageNumber);
    }
}
