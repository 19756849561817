import * as React from 'react';
import * as classnames from 'classnames';
import * as ErrorStackParser from 'error-stack-parser';

import * as styles from './ErrorCard.scss';

/** "ErrorCard" component properties */
export interface ErrorCardProps {
    /** Additional CSS class */
    className?: string;
    /** Error to show */
    error: Error;
}

/** "ErrorCard" component - displays errors at interface */
export const ErrorCard: React.StatelessComponent<ErrorCardProps> = ({
    className,
    error,
}: ErrorCardProps): JSX.Element => (
    <div className={classnames(styles.root, className)}>
        <div className={styles.name}>{error.name}</div>
        <div className={styles.message}>{error.message}</div>
        <div className={styles.stack}>
            {ErrorStackParser.parse(error).map((item, index) => (
                <StackItem item={item} key={index} />
            ))}
        </div>
    </div>
);

export interface StackItemProps {
    item: ErrorStackParser.StackFrame;
}

/** Stack item */
const StackItem: React.StatelessComponent<StackItemProps> = ({ item }: StackItemProps): JSX.Element => (
    <div className={styles.stackItem}>
        at {item.isConstructor ? 'new' : ''} {item.functionName} ({item.fileName}
        {item.lineNumber}:{item.columnNumber})
    </div>
);
