import * as React from 'react';
import * as classnames from 'classnames';
import inlineSvgSprite from 'inline-svg-sprite';

import * as styles from './AdminDashboardPage.scss';

import { CustomScrollbar } from 'sber-marketing-ui';
import { NavigationTabs } from '../NavigationTabs';

import 'sber-marketing-ui/public/ui-components.css';
import 'minireset.css/minireset.css';

export interface Props {
    className?: string;
    children?: React.ReactNode;
    customHeader?: React.ClassType<any, any, any>;
}

export const AdminDashboardPageTemplate: React.FunctionComponent<Props> = ({
    className,
    children,
    customHeader,
}: Props) => {
    return (
        <div className={classnames(styles.root, className)}>
            {!customHeader && <NavigationTabs className={styles.header} />}

            {customHeader !== undefined && React.createElement(customHeader)}

            <div className={styles.content}>
                <CustomScrollbar
                    renderView={(props) => <div {...props} className={styles.scrollView} />}
                    hideTracksWhenNotNeeded
                    autoHide
                >
                    {children}
                </CustomScrollbar>
            </div>

            <div className={styles.inlineIcons} dangerouslySetInnerHTML={{ __html: inlineSvgSprite }} />
        </div>
    );
};
