import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { loadJournalPage as loadJournalPageAction } from './actions';
import { State } from '../types';
import { JournalApi } from '../../api';
import { bulkAddUsers, bulkAddOrganizations } from '../filterData';
import { LoadJournalPageSuccess } from './types';
import { getEventsRecordsListQuery } from '../journalFilters';

export const loadJournalPage = bindThunkAction<State, number, LoadJournalPageSuccess, Error>(
    loadJournalPageAction,
    async (pageNumber, dispatch, getState): Promise<LoadJournalPageSuccess> => {
        const { records, users, organizations, countPages, page } = await JournalApi.getInstance().getList(
            getEventsRecordsListQuery(getState(), pageNumber),
        );
        if (users.length > 0) {
            dispatch(bulkAddUsers(users));
        }
        if (organizations.length > 0) {
            dispatch(bulkAddOrganizations(organizations));
        }
        return { records, countPages, page };
    },
);
