import * as React from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { State as StoreState } from '../../../store';
import type { Action, RoleLine, Role, CreateRoleParams } from '../../../api';
import type { SortingParams, Filter } from '../../../store/rolesPage/types';
import { ColumnHeaderType, ColumnType, ColumnName, OrderType } from '../../../store/rolesPage/types';
import type { CellParams, CellPosition, ColumnHeaderParams, ColumnWidths, LineId } from './types';
import { CellEvent } from './types';

import { TableTemplate } from './TableTemplate';
import { TableViewModel } from './TableViewModel';
import { TableView } from './TableView';
import {
    tableColumns,
    leftFixedColumns,
    rightFixedColumns,
    readOnlyColumns,
    ColumnsConfig,
    ColumnHeaderComponentsByType,
    CellComponentsByColumnType,
    AccessorParams,
} from './ColumnsConfig';
import { getPageData, getFilters, getSortingParams } from '../../../store/rolesPage/selectors';
import { Loader, Saver } from './modules';

const columnWidths: ColumnWidths = lodash.mapValues(ColumnsConfig, (item) => item.defaultWidth);

interface Props extends Partial<MapProps> {}

interface MapProps {
    roleLines: RoleLine[];
    actions: Action[];
    sortingParams: SortingParams;
    filters: Filter[];
}

interface State {
    tableLineIds: LineId[];
    newLineCreation: boolean;
}

@(connect(mapStateToProps, null) as any)
export class TableBehaviour extends React.PureComponent<Props, State> {
    private viewModel: TableViewModel;
    private table: TableView;
    private newLineFields: { [columnName: string]: any } = {};
    private lineNames: Record<string, LineId[]> = {};
    private invalidLines: LineId[] = [];
    private loader: Loader;
    private saver: Saver;

    public constructor(props: Props) {
        super(props);

        this.state = {
            tableLineIds: [],
            newLineCreation: false,
        };

        this.viewModel = new TableViewModel({
            makeColumnHeaderParams: this.makeColumnHeaderParams,
            makeCellParams: this.makeCellParams,
        });

        this.loader = Loader.getInstance();
        this.saver = Saver.getInstance();
    }

    public async componentDidMount() {
        this.loader.subscribeRoleLinesChanges(this.onRoleDomainUpdate);
        this.updateValidation();
        this.updateLineIds();
    }

    public componentDidUpdate(prevProps: Props) {
        const sortingParamsChanged = this.props.sortingParams !== prevProps.sortingParams;
        const filtersChanged = this.props.filters !== prevProps.filters;

        if (sortingParamsChanged || filtersChanged) {
            this.updateLineIds();
        }
    }

    public render(): JSX.Element {
        const { tableLineIds, newLineCreation } = this.state;

        return React.createElement(TableTemplate, {
            viewModel: this.viewModel,
            tableColumns,
            leftFixedColumns,
            rightFixedColumns,
            readOnlyColumns,
            tableLineIds,
            columnWidths,
            newLineCreation,
            tableRef: this.tableRef,
            onAddButtonClick: this.onAddButtonClick,
            onCellEvent: this.onCellEvent,
        });
    }

    @autobind
    protected tableRef(component: TableView) {
        this.table = component ? (component as any).getInstance() : null;
    }

    @autobind
    protected async onAddButtonClick() {
        this.startNewLineCreation();
    }

    @autobind
    protected async onCreateButtonClick() {
        await this.saver.createRoleLine(this.makeCreateRoleLineParams());
        await this.loader.loadRoleLines();
        this.loader.subscribeRoleLinesChanges(this.onRoleDomainUpdate);
        this.endNewLineCreation();
        this.updateValidation();
        this.updateLineIds();
    }

    @autobind
    protected async onCancelCreationButtonClick() {
        this.endNewLineCreation();
    }

    @autobind
    protected async onCopyButtonClick(lineId: LineId) {
        this.startNewLineCreation();

        const columns = [ColumnName.Name, ColumnName.Permissions];

        columns.forEach((columnName) => {
            this.newLineFields[columnName] = this.getCellValue({ columnName, lineId });

            this.updateCell({ columnName, lineId: 'new' }, false);
        });
    }

    @autobind
    protected async onDeleteConfirm(lineId: LineId) {
        await this.saver.deleteRoleLine(lineId);
        await this.loader.loadRoleLines();
        this.loader.subscribeRoleLinesChanges(this.onRoleDomainUpdate);
        this.updateValidation();
        this.updateLineIds();
    }

    @autobind
    protected async onSaveButtonClick(lineId: LineId) {
        await this.saver.saveRoleLine(lineId);
        await this.loader.loadRoleLines();
        this.updateLineIds();
    }

    @autobind
    protected async onCancelEditButtonClick(lineId: LineId) {
        await this.saver.resetRoleLineChanges(lineId);
    }

    @autobind
    protected onRoleDomainUpdate(line: Partial<Role>) {
        this.updateLine(line.id);
        this.updateValidation();
    }

    @autobind
    protected getCellValue(cellPosition: CellPosition) {
        const { lineId, columnName } = cellPosition;

        const lineIsNew = lineId === 'new';

        if (lineIsNew) {
            return this.newLineFields[columnName];
        }

        const accessorParams = this.makeAccessorParams(lineId);

        return ColumnsConfig[columnName].getValue(accessorParams);
    }

    @autobind
    protected getCellItems(cellPosition: CellPosition): { title: React.ReactText; value: any }[] {
        const { lineId, columnName } = cellPosition;

        const accessorParams = this.makeAccessorParams(lineId);

        return ColumnsConfig[columnName].getItems(accessorParams) || [];
    }

    @autobind
    protected makeValueChangeHandler(cellPosition: CellPosition, closeEditorOnChange: boolean) {
        return async (value: any) => {
            const { lineId, columnName } = cellPosition;

            const lineIsNew = lineId === 'new';

            if (lineIsNew) {
                this.newLineFields[columnName] = value;

                if (ColumnsConfig[columnName].linkedColumns) {
                    ColumnsConfig[columnName].linkedColumns.forEach((item) => {
                        this.newLineFields[item] = null;
                    });
                }

                this.updateLine(lineId);
            } else {
                const accessorParams = this.makeAccessorParams(lineId);

                await ColumnsConfig[columnName].setValue(accessorParams, value);
            }

            if (columnName === ColumnName.Name) {
                if (!this.lineNames[value]) {
                    this.lineNames[value] = [];
                }

                if (!this.lineNames[value].includes(lineId)) {
                    this.lineNames[value].push(lineId);
                }
            }

            this.updateValidation();
        };
    }

    @autobind
    protected async onCellEvent(eventType: CellEvent, position: CellPosition) {
        switch (eventType) {
            case CellEvent.EditStart:
                this.updateCell(position, true);
                break;

            case CellEvent.EditEnd:
                this.updateCell(position, false);
                break;

            case CellEvent.Selection:
                this.updateCell(position, false);
                break;
        }
    }

    @autobind
    private updateLine(lineId: LineId) {
        const allColumns = [...tableColumns, ...leftFixedColumns, ...rightFixedColumns];

        allColumns.forEach((columnName) => {
            const cellPosition = { lineId, columnName };

            const cellEditStatus = this.table.getCellEditStatus(cellPosition);
            this.updateCell(cellPosition, cellEditStatus);
        });
    }

    private updateCell(position: CellPosition, edit: boolean) {
        this.viewModel.setCellParams(position, this.makeCellParams(position, edit));
    }

    @autobind
    private makeColumnHeaderParams(columnName: ColumnName): ColumnHeaderParams {
        return {
            component: this.getColumnHeaderComponent(columnName),
            columnHeaderProps: this.makeColumnHeaderProps(columnName),
        };
    }

    private getColumnHeaderComponent(columnName: ColumnName): React.ClassType<any, any, any> {
        const columnType = ColumnsConfig[columnName].headerType;

        return ColumnHeaderComponentsByType[columnType];
    }

    private makeColumnHeaderProps(columnName: ColumnName): any {
        const headerType = ColumnsConfig[columnName].headerType;

        let cellProps: any;

        switch (headerType) {
            case ColumnHeaderType.Text:
                cellProps = this.makeTextColumnHeaderProps(columnName as ColumnName);
                break;

            case ColumnHeaderType.Filters:
                cellProps = this.makeFiltersColumnHeaderProps(columnName as ColumnName);
                break;
        }

        return cellProps;
    }

    private makeTextColumnHeaderProps(columnName: ColumnName): any {
        return {
            title: ColumnsConfig[columnName].title,
        };
    }

    private makeFiltersColumnHeaderProps(columnName: ColumnName): any {
        return {
            title: ColumnsConfig[columnName].title,
            columnName,
            disableSorting: ColumnsConfig[columnName].disableSorting,
            disableFilters: ColumnsConfig[columnName].disableFilters,
            makeFilterItems: () => this.makeFilterItems(columnName),
        };
    }

    @autobind
    private makeFilterItems(columnName: ColumnName): any[] {
        if (ColumnsConfig[columnName].disableFilters) {
            return [];
        }

        const { roleLines, filters } = this.props;

        const filtersToApply = filters.some((item) => item.columnName === columnName)
            ? filters.slice(
                  0,
                  filters.findIndex((item) => item.columnName === columnName),
              )
            : filters;

        let filteredLines = roleLines;

        filtersToApply.forEach((filter) => {
            filteredLines = filteredLines.filter((line) => {
                const value = this.getCellValue({ columnName: filter.columnName, lineId: line.model.id });

                return lodash.isArray(value)
                    ? filter.selectedValues.some((item) => value.includes(item))
                    : filter.selectedValues.includes(value);
            });
        });

        const linesValues = filteredLines.map((item) => this.getCellValue({ columnName, lineId: item.model.id }));

        const hasLinesWithoutValue = linesValues.some((value) =>
            lodash.isArray(value) ? lodash.isEmpty(value) : !value,
        );

        const selectedValues = lodash.uniq(lodash.flatten(linesValues));

        const accessorParams = this.makeAccessorParams(null);

        const possibleItems = ColumnsConfig[columnName].getItems(accessorParams) || [];

        const filterItems = selectedValues.map((value) => ({
            id: value,
            title: possibleItems.find((item) => item.value === value)?.title || 'Не найдено',
        }));

        const sortedItems = lodash.sortBy(filterItems, (item) => item.title);

        if (hasLinesWithoutValue) {
            sortedItems.unshift({
                id: null,
                title: 'Значение не задано',
            });
        }

        return sortedItems;
    }

    @autobind
    private makeCellParams(cellPosition: CellPosition, edit: boolean): CellParams {
        return {
            component: this.getCellComponent(cellPosition, edit),
            cellProps: this.makeCellProps(cellPosition, edit),
        };
    }

    private getCellComponent(cellPosition: CellPosition, edit: boolean): React.ClassType<any, any, any> {
        const { columnName } = cellPosition;

        const columnType = ColumnsConfig[columnName].type;

        return edit ? CellComponentsByColumnType[columnType].editCell : CellComponentsByColumnType[columnType].cell;
    }

    private makeCellProps(cellPosition: CellPosition, edit: boolean): any {
        const { columnName, lineId } = cellPosition;

        const columnType = ColumnsConfig[columnName].type;

        let cellProps: any;

        switch (columnType) {
            case ColumnType.Text:
                cellProps = this.makeTextCellProps(cellPosition);
                break;

            case ColumnType.Input:
                cellProps = this.makeInputCellProps(cellPosition, edit);
                break;

            case ColumnType.CheckboxList:
                cellProps = this.makeCheckboxListCellProps(cellPosition, edit);
                break;

            case ColumnType.Actions:
                cellProps = this.makeActionsCellProps(cellPosition);
                break;
        }

        return cellProps;
    }

    private makeTextCellProps(cellPosition: CellPosition): any {
        return {
            value: this.getCellValue(cellPosition) || '—',
        };
    }

    private makeInputCellProps(cellPosition: CellPosition, edit: boolean): any {
        const { columnName, lineId } = cellPosition;

        const lineIsNew = lineId === 'new';

        const value = this.getCellValue(cellPosition);

        const params: any = edit
            ? {
                  value,
                  placeholder: '',
                  onValueChange: this.makeValueChangeHandler(cellPosition, true),
              }
            : {
                  value,
              };

        if (columnName === ColumnName.Name && this.invalidLines.includes(lineId) && !(lineIsNew && !value)) {
            params.style = {
                outline: '2px solid #e63900',
            };
        }

        return params;
    }

    private makeCheckboxListCellProps(cellPosition: CellPosition, edit: boolean): any {
        const { columnName } = cellPosition;

        const value: any[] = this.getCellValue(cellPosition);
        const items = this.getCellItems(cellPosition);

        return edit
            ? {
                  entityName: ColumnsConfig[columnName].title,
                  items,
                  selectedValues: value || [],
                  emptyListMessage: null,
                  onValueChange: this.makeValueChangeHandler(cellPosition, false),
              }
            : {
                  entityName: ColumnsConfig[columnName].title,
                  items,
                  selectedValues: value || [],
              };
    }

    private makeActionsCellProps(cellPosition: CellPosition): any {
        const { lineId } = cellPosition;

        const lineIsNew = lineId === 'new';

        const roleLine = this.props.roleLines.find((item) => item.model.id === lineId);

        const lineHasChanges = !lineIsNew ? roleLine.model.isUnsaved : false;

        return {
            lineIsNew,
            lineHasChanges,
            saveDisabled: this.invalidLines.includes(lineId),
            onCreateButtonClick: this.onCreateButtonClick,
            onCancelCreationButtonClick: this.onCancelCreationButtonClick,
            onCopyButtonClick: () => this.onCopyButtonClick(lineId),
            onDeleteConfirm: () => this.onDeleteConfirm(lineId),
            onSaveButtonClick: () => this.onSaveButtonClick(lineId),
            onCancelEditButtonClick: () => this.onCancelEditButtonClick(lineId),
        };
    }

    private updateLineIds() {
        const { roleLines } = this.props;
        const { newLineCreation } = this.state;

        const filteredLines = this.filterLines(roleLines);
        const sortedLines = this.sortLines(filteredLines);

        const updatedTableLineIds: React.ReactText[] = sortedLines.map((item) => item.model.id);

        if (newLineCreation) {
            updatedTableLineIds.unshift('new');
        }

        this.setState({
            tableLineIds: updatedTableLineIds,
        });
    }

    private filterLines(lines: RoleLine[]): RoleLine[] {
        const { filters } = this.props;

        let filteredLines = lines;

        filters.forEach((filter) => {
            filteredLines = filteredLines.filter((line) => {
                const value = this.getCellValue({ columnName: filter.columnName, lineId: line.model.id });

                return lodash.isArray(value)
                    ? filter.selectedValues.some(
                          (item) => value.includes(item) || (item === null && lodash.isEmpty(value)),
                      )
                    : filter.selectedValues.includes(value);
            });
        });

        return filteredLines;
    }

    private sortLines(lines: RoleLine[]): RoleLine[] {
        const { sortingParams } = this.props;

        if (!sortingParams.columnName || !sortingParams.orderType) {
            return lines;
        }

        const sortedItems = lodash.sortBy(lines, (item) => {
            const accessorParams = this.makeAccessorParams(item.model.id);

            return ColumnsConfig[sortingParams.columnName].getSortingValue(accessorParams) as unknown;
        });

        if (sortingParams.orderType === OrderType.Desc) {
            sortedItems.reverse();
        }

        return sortedItems;
    }

    private async updateValidation() {
        const { roleLines } = this.props;

        const oldInvalidLines = this.invalidLines;

        this.lineNames = {};
        this.invalidLines = [];

        roleLines.forEach((line) => {
            const lineId = line.model.id;

            const name = this.getCellValue({ columnName: ColumnName.Name, lineId }) || null;

            if (!this.lineNames[name]) {
                this.lineNames[name] = [];
            }

            if (!this.lineNames[name].includes(lineId)) {
                this.lineNames[name].push(lineId);
            }
        });

        if (this.state.tableLineIds.includes('new')) {
            const name = this.newLineFields[ColumnName.Name] || null;

            if (!this.lineNames[name]) {
                this.lineNames[name] = [];
            }

            if (!this.lineNames[name].includes('new')) {
                this.lineNames[name].push('new');
            }
        }

        lodash.forEach(this.lineNames, (lineIds, name) => {
            if (lineIds.length > 1 || name == 'null') {
                this.invalidLines.push(...lineIds);
            }
        });

        const linesToUpdate = lodash.uniq([...oldInvalidLines, ...this.invalidLines]);

        linesToUpdate.forEach((lineId) => {
            this.updateLine(lineId);
        });
    }

    private makeAccessorParams(lineId: LineId): AccessorParams {
        const { roleLines, actions } = this.props;

        const roleLine = roleLines.find((item) => item.model.id === lineId);

        return {
            lineId,
            roleLine,
            allRoleLines: roleLines,
            newLineFields: this.newLineFields,
            actions,
        };
    }

    private startNewLineCreation() {
        if (!this.state.newLineCreation) {
            const updatedTableLineIds = ['new', ...this.state.tableLineIds];

            this.setState(
                {
                    tableLineIds: updatedTableLineIds,
                    newLineCreation: true,
                },
                () => {
                    this.updateValidation();
                },
            );
        }
    }

    private endNewLineCreation() {
        const updatedTableLineIds = lodash.without(this.state.tableLineIds, 'new');

        this.setState({
            tableLineIds: updatedTableLineIds,
            newLineCreation: false,
        });

        this.newLineFields = {};
        this.viewModel.clearLineCells('new');
    }

    private makeCreateRoleLineParams(): CreateRoleParams {
        return {
            name: this.newLineFields[ColumnName.Name],
            actions: this.newLineFields[ColumnName.Permissions] || [],
        };
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { roleLines, actions } = getPageData(state);

    return {
        roleLines,
        actions,
        sortingParams: getSortingParams(state),
        filters: getFilters(state),
    };
}
