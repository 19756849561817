import * as React from 'react';
import * as classnames from 'classnames';
import { isNil } from 'lodash';
import { Button_redesign, ButtonTheme_redesign } from 'sber-marketing-ui';

import * as styles from './ErrorWithRetry.scss';
import { ErrorCard } from '../ErrorCard';

const getTitle = (errorCode: number | null): string =>
    isNil(errorCode) ? `Ошибка ${errorCode}` : 'Неизвестная ошибка';

/** "ErrorWithRetry" component properties */
export interface ErrorWithRetryProps {
    /** Additional CSS class */
    className?: string;
    /** Error HTTP status code */
    errorCode?: number | null | undefined;
    /** Thrown error */
    error: Error;
    /** Retry button click handler */
    onRetryButtonClick(): void;
}

/** "ErrorWithRetry" component - form to display error with retry button to handle error */
export const ErrorWithRetry: React.StatelessComponent<ErrorWithRetryProps> = ({
    className,
    error,
    errorCode,
    onRetryButtonClick,
}: ErrorWithRetryProps): JSX.Element => (
    <div className={classnames(styles.root, className)}>
        <div className={styles.title}>{getTitle(errorCode)}</div>
        <ErrorCard className={styles.error} error={error} />
        <div className={styles.retryButtonWrap}>
            <div className={styles.retryButton}>
                <Button_redesign onClick={onRetryButtonClick} theme={ButtonTheme_redesign.Ghost}>
                    Попробовать снова
                </Button_redesign>
            </div>
        </div>
    </div>
);
