import { createSelector } from 'reselect';
import {
    Configuration,
    ConfigurationUpdateBody,
    RequestTimeout,
    PasswordTimeout,
    WrongPasswordCount,
    TransitPasswordLifetime,
    FileStore,
} from 'sber-marketing-types/system-admin';

import { State, ItemLoadingStatus } from '../types';
import { ConfigurationState } from './types';
import { getFullState } from '../utils';

export const getConfigurationState = createSelector(
    getFullState,
    ({ configuration }: State): ConfigurationState => configuration,
);

export const getLoadingStatus = createSelector(
    getConfigurationState,
    ({ status }: ConfigurationState): ItemLoadingStatus => status,
);

export const getLoadingError = createSelector(
    getConfigurationState,
    ({ error }: ConfigurationState): Error | null => error,
);

export const getLoadingErrorCode = createSelector(getConfigurationState, ({ errorCode }): number | null => errorCode);

export const getPreviousConfiguration = createSelector(
    getConfigurationState,
    ({ previous }): Configuration => previous,
);

export const getCurrentConfiguration = createSelector(getConfigurationState, ({ current }): Configuration => current);

type ConfigurationSelector = (state: State) => Configuration;

const createRequestTimeoutSelector = (getter: ConfigurationSelector) =>
    createSelector(getter, ({ requestTimeout }): RequestTimeout => requestTimeout);

const createStoreSelector = (getter: ConfigurationSelector) => createSelector(getter, ({ store }): FileStore => store);

const createPasswordTimeoutSelector = (getter: ConfigurationSelector) =>
    createSelector(getter, ({ passwordTimeout }): PasswordTimeout => passwordTimeout);

const createWrongPasswordsCountSelector = (getter: ConfigurationSelector) =>
    createSelector(getter, ({ wrongPasswordsCount }): WrongPasswordCount => wrongPasswordsCount);

const createFileSplittingSelector = (getter: ConfigurationSelector) =>
    createSelector(getter, ({ fileSplitting }): number => fileSplitting);

const createTransitPasswordLifetimeSelector = (getter: ConfigurationSelector) =>
    createSelector(getter, ({ transitPasswordLifetime }): TransitPasswordLifetime => transitPasswordLifetime);

export const getPreviousRequestTimeout = createRequestTimeoutSelector(getPreviousConfiguration);

export const getPreviousStore = createStoreSelector(getPreviousConfiguration);

export const getPreviousPasswordTimeout = createPasswordTimeoutSelector(getPreviousConfiguration);

export const getPreviousWrongPasswordsCount = createWrongPasswordsCountSelector(getPreviousConfiguration);

export const getPreviousFileSplitting = createFileSplittingSelector(getPreviousConfiguration);

export const getPreviousTransitPasswordLifetime = createTransitPasswordLifetimeSelector(getPreviousConfiguration);

export const getCurrentRequestTimeout = createRequestTimeoutSelector(getCurrentConfiguration);

export const getCurrentStore = createStoreSelector(getCurrentConfiguration);

export const getCurrentPasswordTimeout = createPasswordTimeoutSelector(getCurrentConfiguration);

export const getCurrentWrongPasswordsCount = createWrongPasswordsCountSelector(getCurrentConfiguration);

export const getCurrentFileSplitting = createFileSplittingSelector(getCurrentConfiguration);

export const getCurrentTransitPasswordLifetime = createTransitPasswordLifetimeSelector(getCurrentConfiguration);

type Getter<T> = (state: State) => T;

const createChangeStatusSelector = <T>(previousGetter: Getter<T>, currentGetter: Getter<T>) =>
    createSelector([previousGetter, currentGetter], (previous: T, current: T): boolean => current !== previous);

export const isRequestTimeoutChanged = createChangeStatusSelector<RequestTimeout>(
    getPreviousRequestTimeout,
    getCurrentRequestTimeout,
);

export const isStoreChanged = createChangeStatusSelector<FileStore>(getPreviousStore, getCurrentStore);

export const isPasswordTimeoutChanged = createChangeStatusSelector<PasswordTimeout>(
    getPreviousPasswordTimeout,
    getCurrentPasswordTimeout,
);

export const isWrongPasswordsCount = createChangeStatusSelector<WrongPasswordCount>(
    getPreviousWrongPasswordsCount,
    getCurrentWrongPasswordsCount,
);

export const isFileSplittingChanged = createChangeStatusSelector<number>(
    getPreviousFileSplitting,
    getCurrentFileSplitting,
);

export const isTransitPasswordLifetimeChanged = createChangeStatusSelector<TransitPasswordLifetime>(
    getPreviousTransitPasswordLifetime,
    getCurrentTransitPasswordLifetime,
);

export const isConfigurationChanged = createSelector(
    [
        isRequestTimeoutChanged,
        isStoreChanged,
        isPasswordTimeoutChanged,
        isWrongPasswordsCount,
        isFileSplittingChanged,
        isTransitPasswordLifetimeChanged,
    ],
    (
        isRequestTimeoutChanged,
        isStoreChanged,
        isPasswordTimeoutChanged,
        isWrongPasswordsCount,
        isFileSplittingChanged,
        isTransitPasswordLifetimeChanged,
    ): boolean =>
        isRequestTimeoutChanged ||
        isStoreChanged ||
        isPasswordTimeoutChanged ||
        isWrongPasswordsCount ||
        isFileSplittingChanged ||
        isTransitPasswordLifetimeChanged,
);

export const getConfigurationUpdateBody = createSelector(
    [
        getCurrentConfiguration,
        isRequestTimeoutChanged,
        isStoreChanged,
        isPasswordTimeoutChanged,
        isWrongPasswordsCount,
        isFileSplittingChanged,
        isTransitPasswordLifetimeChanged,
    ],
    (
        { requestTimeout, store, passwordTimeout, wrongPasswordsCount, fileSplitting, transitPasswordLifetime },
        isRequestTimeoutChanged,
        isStoreChanged,
        isPasswordTimeoutChanged,
        isWrongPasswordsCount,
        isFileSplittingChanged,
        isTransitPasswordLifetimeChanged,
    ): ConfigurationUpdateBody => {
        const result: ConfigurationUpdateBody = {};
        if (isRequestTimeoutChanged) {
            result.requestTimeout = requestTimeout;
        }
        if (isStoreChanged) {
            result.store = store;
        }
        if (isPasswordTimeoutChanged) {
            result.passwordTimeout = passwordTimeout;
        }
        if (isWrongPasswordsCount) {
            result.wrongPasswordsCount = wrongPasswordsCount;
        }
        if (isFileSplittingChanged) {
            result.fileSplitting = fileSplitting;
        }
        if (isTransitPasswordLifetimeChanged) {
            result.transitPasswordLifetime = transitPasswordLifetime;
        }
        return result;
    },
);
