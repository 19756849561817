import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { EmbedEntities } from 'sber-marketing-types/system-admin';

import { loadFilterData as loadFilterDataAction, bulkAddOrganizations, bulkAddRoles, bulkAddUsers } from './actions';
import { State } from '../types';
import { FilterDataApi } from '../../api';
import { removeEmbed } from '../journalFilters';

export const loadFilterData = bindThunkAction<State, null | undefined, void, Error>(
    loadFilterDataAction,
    async (payload, dispatch) => {
        const { users, roles, organizations } = await FilterDataApi.getInstance().getFilterData();
        dispatch(bulkAddUsers(users));
        dispatch(removeEmbed(EmbedEntities.USERS));
        dispatch(bulkAddOrganizations(organizations));
        dispatch(removeEmbed(EmbedEntities.ORGANIZATIONS));
        dispatch(bulkAddRoles(roles));
    },
);
