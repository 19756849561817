import * as React from 'react';
import * as classnames from 'classnames';
import { Popup, PopupTheme, Preloader, Button_redesign, ButtonTheme_redesign } from 'sber-marketing-ui';

import * as styles from './ClearJournalModal.scss';
import { ErrorCard } from '../../common/ErrorCard';
import { ClearJournalModalTemplateProps } from './types';

export const ClearJournalModalTemplate: React.StatelessComponent<ClearJournalModalTemplateProps> = ({
    showPreloader,
    showMessage,
    message,
    showMultipleButtons,
    showOneButton,
    firstButtonLabel,
    secondButtonLabel,
    error,
    showError,
    onFirstButtonClick,
    onSecondButtonClick,
}: ClearJournalModalTemplateProps): JSX.Element => (
    <Popup theme={PopupTheme.createTaskTheme}>
        <div className={classnames(styles.root, showPreloader && styles.rootInProgress)}>
            {showPreloader && <Preloader />}
            {showError && <ErrorCard error={error!} className={styles.error} />}
            {showMessage && (
                <div className={styles.message}>
                    <div className={styles.messageContent}>{message}</div>
                </div>
            )}
            {showMultipleButtons && (
                <div className={styles.buttonsLine}>
                    <div className={styles.button}>
                        <Button_redesign theme={ButtonTheme_redesign.Main} onClick={onFirstButtonClick}>
                            {firstButtonLabel as string | JSX.Element}
                        </Button_redesign>
                    </div>
                    <div className={styles.button}>
                        <Button_redesign theme={ButtonTheme_redesign.Ghost} onClick={onSecondButtonClick}>
                            {secondButtonLabel as string | JSX.Element}
                        </Button_redesign>
                    </div>
                </div>
            )}
            {showOneButton && (
                <div className={classnames(styles.button, styles.buttonSingle)}>
                    <Button_redesign theme={ButtonTheme_redesign.Ghost} onClick={onFirstButtonClick}>
                        {secondButtonLabel as string | JSX.Element}
                    </Button_redesign>
                </div>
            )}
        </div>
    </Popup>
);
