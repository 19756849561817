import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Link from 'redux-first-router-link';

import * as style from './Header.scss';

import type { State as StoreState } from '../../../store';

import { Icon, IconType, Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';
import { Skeleton } from '../../common/Skeleton';
import { getPageData } from '../../../store/rolesPage/selectors';
import { routeOrganizationsPage, logout } from '../../../store/routing';

interface Props {}

export const Header: React.FunctionComponent<Props> = ({}: Props) => {
    const { organization } = useSelector((state: StoreState) => getPageData(state));

    const dispatch = useDispatch();

    const onLogoutButtonClick = React.useCallback(() => {
        dispatch(logout());
    }, []);

    return (
        <div className={style.root}>
            <Link to={routeOrganizationsPage()} className={style.backButton}>
                <div className={style.backButtonIcon}>
                    <Icon type={IconType.ARROW_GRAY_LEFT} />
                </div>
                К организациям
            </Link>

            {organization && <div className={style.organisationName}>Пользовательские роли {organization.name}</div>}

            {!organization && <Skeleton customStyle={{ height: 22, width: '325px', borderRadius: 2 }} />}

            <div className={style.logoutButton}>
                <Button theme={ButtonTheme.MainRounded} onClick={onLogoutButtonClick}>
                    Выход
                </Button>
            </div>
        </div>
    );
};
