import * as React from 'react';
import * as classnames from 'classnames';
import { Preloader as Preloader } from 'sber-marketing-ui';

import * as styles from './Journal.scss';
import { JournalPagination } from '../JournalPagination';
import { JournalEventsTable } from '../JournalEventsTable';
import { JournalTemplateProps } from './types';
import { ErrorHandleForm } from '../../common/ErrorHandleForm';
import { ManageButtons } from '../ManageButtons';

const resolveRootClasses = (className?: string): string => classnames(styles.root, className);

/** "Journal" template component */
export const JournalTemplate = ({ className, isLoading, error, onRetryClick }: JournalTemplateProps): JSX.Element => (
    <div className={resolveRootClasses(className)}>
        {isLoading && <Preloader />}

        <div className={styles.title}>Журнал</div>

        {error ? (
            <ErrorHandleForm name={error.name} message={error.message} onRetryClick={onRetryClick} />
        ) : (
            <React.Fragment>
                <JournalPagination className={styles.paginationTop} />
                <JournalEventsTable className={styles.table} />
                <JournalPagination className={styles.paginationBottom} />
                <ManageButtons className={styles.manageButtons} />
            </React.Fragment>
        )}
    </div>
);
