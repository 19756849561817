import { actionCreatorFactory } from 'typescript-fsa';

import type { PageData, SortingParams, Filter } from './types';

const actionCreator = actionCreatorFactory('CREATIVE_PAGE');

const loadPageData = actionCreator<Partial<PageData>>('LOAD_PAGE_DATA');
const resetPageState = actionCreator('RESET_PAGE_STATE');
const setTableSorting = actionCreator<SortingParams>('SET_TABLE_SORTING');
const setTableFilters = actionCreator<Filter[]>('SET_TABLE_FILTERS');

export { loadPageData, resetPageState, setTableSorting, setTableFilters };
