import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { NavigationTabsTemplate } from './NavigationTabsTemplate';
import { State } from '../../../store/types';
import { getCurrentPage, CurrentPage, logout } from '../../../store/routing';

export interface NavigationTabsStoreProps {
    className?: string;
}

interface MappedState {
    currentPage: CurrentPage;
}

interface MappedActions {
    logout(): void;
}

const mapStateToProps = (state: State): MappedState => ({
    currentPage: getCurrentPage(state),
});

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    logout: () => dispatch(logout()),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** "NavigationTabs" store component */
export const NavigationTabsStore: ComponentType<NavigationTabsStoreProps> = withStore(NavigationTabsTemplate);
