import { ColumnName, ColumnHeaderType, ColumnType } from '../../../store/rolesPage/types';
import type { Action, RoleLine } from '../../../api';

import { TextColumnHeader, FiltersColumnHeader } from './ColumnHeaderTypes';
import { CheckboxListCell, CheckboxListCellEdit, TextCell, ActionsCell, InputCell, InputCellEdit } from './CellTypes';
import { LineId } from './types';

export interface AccessorParams {
    lineId: LineId;
    roleLine: RoleLine;
    allRoleLines: RoleLine[];
    newLineFields: { [columnName: string]: any };
    actions: Action[];
}

export interface ColumnParams {
    title: string;
    headerType: ColumnHeaderType;
    type: ColumnType;
    defaultWidth: number;
    readOnly?: boolean;
    disableSorting?: boolean;
    disableFilters?: boolean;
    linkedColumns?: ColumnName[];
    getSortingValue?: (params: AccessorParams) => any;
    getValue?: (params: AccessorParams) => any;
    setValue?: (params: AccessorParams, value: any) => Promise<void>;
    getItems?: (params: AccessorParams) => { title: React.ReactText; value: any }[];
}

export const ColumnHeaderComponentsByType: Record<ColumnHeaderType, React.ClassType<any, any, any>> = {
    [ColumnHeaderType.Text]: TextColumnHeader,
    [ColumnHeaderType.Filters]: FiltersColumnHeader,
};

export const CellComponentsByColumnType: Record<
    ColumnType,
    {
        cell: React.ClassType<any, any, any>;
        editCell?: React.ClassType<any, any, any>;
    }
> = {
    [ColumnType.Text]: {
        cell: TextCell,
    },
    [ColumnType.Input]: {
        cell: InputCell,
        editCell: InputCellEdit,
    },
    [ColumnType.CheckboxList]: {
        cell: CheckboxListCell,
        editCell: CheckboxListCellEdit,
    },
    [ColumnType.Actions]: {
        cell: ActionsCell,
    },
};

export const ColumnsConfig: { [columnName: string]: ColumnParams } = {
    [ColumnName.Id]: {
        title: 'ID',
        headerType: ColumnHeaderType.Filters,
        type: ColumnType.Text,
        readOnly: true,
        defaultWidth: 80,
        disableFilters: true,
        getSortingValue: (params: AccessorParams) => params.roleLine.model.id,
        getValue: (params: AccessorParams) => params.roleLine.model.id,
    },
    [ColumnName.Name]: {
        title: 'Роль',
        headerType: ColumnHeaderType.Filters,
        type: ColumnType.Input,
        defaultWidth: 320,
        disableFilters: true,
        getSortingValue: (params: AccessorParams) => params.roleLine.model.name,
        getValue: (params: AccessorParams) => params.roleLine.model.name,
        setValue: async (params: AccessorParams, value: any) => {
            params.roleLine.model.setName({ name: value });
        },
    },
    [ColumnName.Permissions]: {
        title: 'Действия',
        headerType: ColumnHeaderType.Filters,
        type: ColumnType.CheckboxList,
        defaultWidth: 480,
        disableSorting: true,
        getValue: (params: AccessorParams) => {
            const selectedIds = params.roleLine.model.actions;

            const filteredActionsIds = params.actions.filter((item) => !item.parentId).map((item) => item.id);

            return selectedIds.filter((item) => filteredActionsIds.includes(item));
        },
        setValue: async (params: AccessorParams, value: any) => {
            params.roleLine.model.setActions({ actions: value });
        },
        getItems: (params: AccessorParams) =>
            params.actions.map((item) => ({
                title: item.title,
                value: item.id,
            })),
    },
    [ColumnName.Actions]: {
        title: 'Настройки ',
        headerType: ColumnHeaderType.Text,
        type: ColumnType.Actions,
        defaultWidth: 128,
        readOnly: true,
    },
};

export const tableColumns = [ColumnName.Id, ColumnName.Name, ColumnName.Permissions, ColumnName.Actions];

export const leftFixedColumns: ColumnName[] = [];

export const rightFixedColumns: ColumnName[] = [];

export const readOnlyColumns = [...tableColumns, ...leftFixedColumns, ...rightFixedColumns].filter(
    (item) => ColumnsConfig[item].readOnly,
);
