// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-common-Skeleton-_Skeleton__root{display:inherit;overflow:hidden;width:100%;height:14px;white-space:nowrap;text-overflow:ellipsis;border-radius:2px;font-size:inherit;justify-content:inherit}.src-client-layers-view-common-Skeleton-_Skeleton__root_active{animation:src-client-layers-view-common-Skeleton-_Skeleton__skeletonBackground 1.2s ease-in-out infinite;background-repeat:no-repeat;background-size:200px 100%}@keyframes src-client-layers-view-common-Skeleton-_Skeleton__skeletonBackground{0%{background-position:-200px 0}100%{background-position:calc(200px + 100%) 0}}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/common/Skeleton/Skeleton.scss"],"names":[],"mappings":"AAAA,wDACI,eAAgB,CAChB,eAAgB,CAEhB,UAAW,CACX,WAAY,CAEZ,kBAAmB,CACnB,sBAAuB,CAEvB,iBAAkB,CAElB,iBAAkB,CAElB,uBAAwB,CAC3B,+DAGG,wGAAuD,CAEvD,2BAA4B,CAC5B,0BAA2B,CAC9B,gFAGG,GACI,4BAA6B,CAGjC,KACI,wCAAyC,CAAA","sourcesContent":[".root {\n    display: inherit;\n    overflow: hidden;\n\n    width: 100%;\n    height: 14px;\n\n    white-space: nowrap;\n    text-overflow: ellipsis;\n\n    border-radius: 2px;\n\n    font-size: inherit;\n\n    justify-content: inherit;\n}\n\n.root_active {\n    animation: skeletonBackground 1.2s ease-in-out infinite;\n\n    background-repeat: no-repeat;\n    background-size: 200px 100%;\n}\n\n@keyframes skeletonBackground {\n    0% {\n        background-position: -200px 0;\n    }\n\n    100% {\n        background-position: calc(200px + 100%) 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-common-Skeleton-_Skeleton__root",
	"root_active": "src-client-layers-view-common-Skeleton-_Skeleton__root_active",
	"skeletonBackground": "src-client-layers-view-common-Skeleton-_Skeleton__skeletonBackground"
};
export default ___CSS_LOADER_EXPORT___;
