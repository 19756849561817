import { ComponentType } from 'react';
import { connect } from 'react-redux';

import { State } from '../../../store/types';
import { getCurrentPage, getIsAuthChecking } from '../../../store/routing';
import { RouterTemplateProps } from './types';
import { RouterTemplate } from './RouterTemplate';

const mapStateToProps = (state: State): RouterTemplateProps => ({
    pageType: getCurrentPage(state),
    isAuthChecking: getIsAuthChecking(state),
});

const withStore = connect(mapStateToProps);

export const RouterStore: ComponentType = withStore(RouterTemplate);
