import { combineReducers } from 'redux';
import { Configuration } from 'sber-marketing-types/system-admin';

import {
    requestTimeoutFactory,
    storeFactory,
    passwordTimeoutFactory,
    wrongPasswordsCountFactory,
    fileSplittingFactory,
    transitPasswordLifetimeFactory,
} from './configurationReducers';
import { replaceReducer } from '../../commonReducers';
import {
    editPasswordTimeout,
    editRequestTimeout,
    editStore,
    editWrongPasswordsCount,
    editFileSplitting,
    editTransitPasswordLifetime,
} from '../actions';

const requestTimeout = requestTimeoutFactory().case(editRequestTimeout, replaceReducer);

const store = storeFactory().case(editStore, replaceReducer);

const passwordTimeout = passwordTimeoutFactory().case(editPasswordTimeout, replaceReducer);

const wrongPasswordsCount = wrongPasswordsCountFactory().case(editWrongPasswordsCount, replaceReducer);

const fileSplitting = fileSplittingFactory().case(editFileSplitting, replaceReducer);

const transitPasswordLifetime = transitPasswordLifetimeFactory().case(editTransitPasswordLifetime, replaceReducer);

export const current = combineReducers<Configuration>({
    requestTimeout,
    store,
    passwordTimeout,
    wrongPasswordsCount,
    fileSplitting,
    transitPasswordLifetime,
});
