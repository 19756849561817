import * as React from 'react';
import * as classnames from 'classnames';

import * as styles from './MultipleSelectSuggest.scss';
import {
    MultipleSelectSuggestTemplateProps,
    MultipleSelectSuggestEnhancedProps,
    MultipleSelectSuggestItem,
} from './types';
import { SimpleSuggest } from '../../common/SimpleSuggest';
import { MultipleSelectItem } from '../MultipleSelectItem';

const onValueChangeAssign = (name: string, { value }: MultipleSelectSuggestItem) => ({ value, name });
const renderItem = ({ name }: MultipleSelectSuggestItem): React.ReactNode => name;
const defaultSelectedValue = (): MultipleSelectSuggestItem => ({ value: '', name: '' });
const getItemIdentifier = ({ value }: MultipleSelectSuggestItem): React.Key => value;
const selectedValueToInputString = ({ name }: MultipleSelectSuggestItem): string => String(name);

/** "MultipleSelectSuggest" template component */
export const MultipleSelectSuggestTemplate: React.StatelessComponent<MultipleSelectSuggestTemplateProps> = ({
    selected,
    onAddItem,
    onRemoveItem,
    className,
    title,
    ...restProps
}: MultipleSelectSuggestTemplateProps) => (
    <div className={classnames(styles.root, className)}>
        <SimpleSuggest<MultipleSelectSuggestItem, MultipleSelectSuggestEnhancedProps>
            {...restProps}
            rootClassName={styles.suggestWrap}
            selected={selected}
            onAddItem={onAddItem}
            onRemoveItem={onRemoveItem}
            onSuggestItemSelect={onAddItem}
            renderItem={renderItem}
            onValueChangeAssign={onValueChangeAssign}
            defaultSelectedValue={defaultSelectedValue}
            getItemIdentifier={getItemIdentifier}
            selectedValueToInputString={selectedValueToInputString}
            title={title}
        />
        {selected.length > 0 && (
            <div className={styles.selectedItemsContainer}>
                {selected.map(({ value, name }) => (
                    <MultipleSelectItem
                        key={value}
                        className={styles.selectedItem}
                        value={value}
                        name={name}
                        onRemoveClick={onRemoveItem}
                    />
                ))}
            </div>
        )}
    </div>
);
