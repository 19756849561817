import { actionCreatorFactory } from 'typescript-fsa';
import { User, Organization, Role } from 'sber-marketing-types/system-admin';

const creator = actionCreatorFactory('FILTER_DATA');

export const loadFilterData = creator.async<undefined | null, void, Error>('LOAD_FILTER_DATA');

/** Add user to store */
export const addUser = creator<User>('ADD_USER');

/** Add several users to store */
export const bulkAddUsers = creator<User[]>('BULK_ADD_USERS');

/** Remove user from store */
export const removeUser = creator<number>('REMOVE_USER');

/** Remove several users from store */
export const bulkRemoveUsers = creator<number[]>('BULK_REMOVE_USERS');

/** Add organization to store */
export const addOrganization = creator<Organization>('ADD_ORGANIZATION');

/** Add several organizations to store */
export const bulkAddOrganizations = creator<Organization[]>('BULK_ADD_ORGANIZATIONS');

/** Remove organization from store */
export const removeOrganization = creator<string>('REMOVE_ORGANIZATION');

/** Remove several organizations from store */
export const bulkRemoveOrganizations = creator<string[]>('BULK_REMOVE_ORGANIZATIONS');

/** Add role to store */
export const addRole = creator<Role>('ADD_ROLE');

/** Add several roles to store */
export const bulkAddRoles = creator<Role[]>('BULK_ADD_ROLES');

/** Remove role from store */
export const removeRole = creator<number>('REMOVE_ROLE');

/** Remove several roles from store */
export const bulkRemoveRoles = creator<number[]>('BULK_REMOVE_ROLES');
