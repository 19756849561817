import * as React from 'react';

import * as style from './RolesPage.scss';

import { OpacityTransition } from 'sber-marketing-ui';
import { AdminDashboardPage } from '../common/AdminDashboardPage';
import { Header } from './Header';
import { Table } from './Table';

interface Props {
    preloader: boolean;
}

export const RolesPage: React.FunctionComponent<Props> = ({ preloader }: Props): JSX.Element => {
    return (
        <AdminDashboardPage customHeader={Header}>
            <OpacityTransition start={!preloader}>
                <div className={style.root}>
                    <div className={style.content}>
                        <Table />
                    </div>
                </div>
            </OpacityTransition>
        </AdminDashboardPage>
    );
};
