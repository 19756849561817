import { createSelector } from 'reselect';
import { EventRecord } from 'sber-marketing-types/system-admin';

import { State } from '../types';
import { JournalState } from './types';

const getJournalState = (state: State): JournalState => state.journal;

export const getPage = createSelector(getJournalState, ({ page }): number => page);

export const getCountPages = createSelector(getJournalState, ({ countPages }): number => countPages);

export const getRecords = createSelector(getJournalState, ({ records }): EventRecord[] => records);

export const getIsLoading = createSelector(getJournalState, ({ isLoading }): boolean => isLoading);

export const getErrorMessage = createSelector(getJournalState, ({ errorMessage }): string | null => errorMessage);

export const getErrorName = createSelector(getJournalState, ({ errorName }): string | null => errorName);
