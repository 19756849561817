import * as React from 'react';
import { LabeledSelect, SelectItem } from 'sber-marketing-ui';
import { FileStore } from 'sber-marketing-types/system-admin';

import type { StoreInputTemplateProps } from './types';

const TITLE = 'Текущее хранилище';

const ITEMS: SelectItem[] = [
    {
        value: FileStore.SELCDN,
        label: FileStore.SELCDN,
    },
    {
        value: FileStore.CEPH,
        label: FileStore.CEPH,
    },
];

/** "StoreInput" template component */
export const StoreInputTemplate: React.StatelessComponent<StoreInputTemplateProps> = ({
    className,
    store,
    editStore,
}: StoreInputTemplateProps): JSX.Element => (
    <div className={className}>
        <LabeledSelect title={TITLE} selectedValue={store} items={ITEMS} onSelection={editStore} />
    </div>
);
