import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { without, includes } from 'lodash';

import { MappedState, MappedActions, RolesSuggestExternalProps } from './types';
import { State } from '../../../store/types';
import { getAuthorRoles, addAuthorRole, removeAuthorRole } from '../../../store/journalFilters';
import { getAvailableRolesNames } from '../../../store/filterData';
import {
    MultipleSelectSuggest,
    MultipleSelectSuggestItem,
    MultipleSelectSuggestProps,
} from '../../common/MultipleSelectSuggest';

const mapStateToProps = (state: State): MappedState => {
    const selectedRoles: string[] = getAuthorRoles(state);
    const selected: MultipleSelectSuggestItem[] = getAvailableRolesNames(state)
        .filter((name) => includes(selectedRoles, name))
        .map((name) => ({ name, value: name }));
    const rest: MultipleSelectSuggestItem[] = without(getAvailableRolesNames(state), ...selectedRoles).map((name) => ({
        name,
        value: name,
    }));
    return { selected, rest };
};

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    onAddItem: (role: string) => dispatch(addAuthorRole(role)),
    onRemoveItem: (role: string) => dispatch(removeAuthorRole(role)),
});

const mergeProps = (
    { selected, rest }: MappedState,
    { onAddItem, onRemoveItem }: MappedActions,
    { className }: RolesSuggestExternalProps,
): MultipleSelectSuggestProps => ({
    className,
    selected,
    rest,
    title: 'Добавить роль',
    onAddItem: ({ value }: MultipleSelectSuggestItem) => onAddItem(<string>value),
    onRemoveItem: ({ value }: MultipleSelectSuggestItem) => onRemoveItem(<string>value),
});

const withStore = connect(mapStateToProps, mapDispatchToProps, mergeProps);

/** "RolesSuggest" store component */
export const RolesSuggestStore: ComponentType<RolesSuggestExternalProps> = withStore(MultipleSelectSuggest);
