import * as React from 'react';

import { UserLoginTemplateProps } from './types';

/** "UserLogin" template component */
export const UserLoginTemplate: React.StatelessComponent<UserLoginTemplateProps> = ({
    className,
    id,
    login,
}: UserLoginTemplateProps): JSX.Element => <div className={className}>{login || `#${id}`}</div>;
