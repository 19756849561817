import * as React from 'react';
import classNames from 'classnames';

import * as styles from './Skeleton.scss';

export interface Props {
    isReady?: any;
    className?: string;
    loadingClassName?: string;
    firstColor?: string;
    secondColor?: string;
    customStyle?: React.CSSProperties;
}

export const enum SkeletonType {
    Rect = 'rect',
}

export const Skeleton: React.FC<Props> = ({
    isReady = false,
    className,
    loadingClassName,
    firstColor = '#eeeeee',
    secondColor = '#f5f5f5',
    customStyle,
    children,
}) => {
    const showPreloader = !isReady;

    return showPreloader ? (
        <div
            className={classNames(styles.root, styles.root_active, className, loadingClassName)}
            style={{
                backgroundColor: firstColor,
                backgroundImage: `linear-gradient( 90deg,${firstColor},${secondColor},${firstColor} )`,
                borderRadius: `2px`,
                ...customStyle,
            }}
        >
            {'\u00A0'}
        </div>
    ) : (
        <React.Fragment>{children}</React.Fragment>
    );
};
