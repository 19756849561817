import * as React from 'react';

import * as style from './InputCell.scss';

import { Input_redesign as Input, InputTheme_redesign as InputTheme } from 'sber-marketing-ui';

interface Props {
    value: string;
    placeholder: string;
    onValueChange: (value: string) => void;
}

export const InputCellEdit = ({ value, placeholder, onValueChange }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <Input
                value={value}
                placeholder={placeholder}
                theme={InputTheme.Borderless}
                onBlur={(event, value) => onValueChange(value as string)}
                autofocus
            />
        </div>
    );
};
