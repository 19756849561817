import { EventType } from 'sber-marketing-types/system-admin';

/** Number of default page */
export const DEFAULT_PAGE = 1;

/** Display names of journal events types */
export const EVENT_TYPE_DISPLAY_NAMES = {
    [EventType.CreateUser]: 'Создан пользователь',
    [EventType.UpdateUser]: 'Отредактирован пользователь',
    [EventType.ChangeExpireDate]: 'Изменено время действия аккаунта',
    [EventType.InvalidPassword]: 'Введен неверный пароль',
    [EventType.UpdateDivision]: 'Отредактирован дивизион',
    [EventType.CreateDivision]: 'Создан дивизион',
    [EventType.UpdateProduct]: 'Отредактирован продукт',
    [EventType.CreateProduct]: 'Создан продукт',
    [EventType.UpdateActivityType]: 'Изменен тип проекта',
    [EventType.CreateWorkType]: 'Создан тип работ',
    [EventType.UpdateWorkType]: 'Отредактирован тип работ',
    [EventType.UpdateDepartment]: 'Отредактирован департамент',
    [EventType.CreateDepartment]: 'Создан департамент',
    [EventType.AddRoleToUser]: 'Назначена роль пользователю',
    [EventType.CreateOrganization]: 'Создана организация',
    [EventType.UpdateOrganization]: 'Отредактирована организация',
    [EventType.DeleteOrganization]: 'Удалена организация',
    [EventType.CreateRole]: 'Создана роль',
    [EventType.UpdateRole]: 'Отредактирована роль',
    [EventType.AddPermissionToRole]: 'Добавлено право для роли',
    [EventType.CreateActivity]: 'Создан проект',
    [EventType.UpdateActivity]: 'Отредактирован проект',
    [EventType.DeleteActivity]: 'Удален проект',
    [EventType.CreateTask]: 'Создана задача',
    [EventType.UpdateTask]: 'Отредактирована задача',
    [EventType.ChangedStatusUser]: 'Изменен статус пользователя',
    [EventType.AccountExpired]: 'Истекло время действия аккаунта',
    [EventType.ShareActivity]: 'Поделились активность',
    [EventType.UpdateActivityBudgetItemReference]: 'В активности изменены привязанные строки бюджета',
    [EventType.DeleteTask]: 'Удалена задача',
    [EventType.UploadTaskFile]: 'К задаче прикреплен файл',
    [EventType.DownloadTaskFile]: 'Скачан файл, прикрепленный к задаче',
    [EventType.UpdateTaskExecutor]: 'Изменен исполнитель задачи',
    [EventType.AddTaskParticipant]: 'Добавлен участник задачи',
    [EventType.RemoveTaskParticipant]: 'Удален участник задачи',
    [EventType.AddTaskComment]: 'Добавлен комментарий к задаче',
    [EventType.UploadTaskCommentFile]: 'Добавлен файл в комментарий к задаче',
    [EventType.UpdateTaskComment]: 'Отредактирован комментарий к задаче',
    [EventType.DeleteTaskComment]: 'Удален комментарий к задаче',
    [EventType.UploadBriefFile]: 'К брифу прикреплен файл',
    [EventType.DownloadBriefFile]: 'Скачан файл, прикрепленный к брифу',
    [EventType.UpdateSystemConfiguration]: 'Изменена конфигурация системы',
    [EventType.CreateBudget]: 'Создан бюджет',
    [EventType.CreateActivityBudget]: 'Создана активность в бюджетировании',
    [EventType.CreateActivityBudgetItem]: 'Создана строка бюджета',
    [EventType.UpdateActivityBudget]: 'Отредактирована активность в бюджетировании',
    [EventType.UpdateActivityBudgetItem]: 'Отредактирована строка бюджета',
    [EventType.DeleteActivityBudget]: 'Удалена активность в бюджетировании',
    [EventType.DeleteActivityBudgetItem]: 'Удалена строка бюджета',
};
