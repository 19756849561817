import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';

import { UsersState } from './types';

import { loadUsers, setLoadingStatus, addUser, updateUser } from './actions';
import { UserParams } from 'sber-marketing-types/admin';

export const initialState: UsersState = {
    users: [],
    isLoading: false,
};

export class Reducer {
    public static loadUsers(state: UsersState, payload: UserParams[]): UsersState {
        return { ...state, users: payload };
    }

    public static addUser(state: UsersState, payload: UserParams): UsersState {
        return { ...state, users: [...state.users, payload] };
    }

    public static updateUser(state: UsersState, payload: UserParams): UsersState {
        const id = payload.id;
        const users = state.users;
        lodash.remove(users, (item) => item.id == id);

        return { ...state, users: [...users, payload] };
    }

    public static setLoadingStatus(state: UsersState, payload: boolean): UsersState {
        return { ...state, isLoading: payload };
    }
}

export const userReducer = reducerWithInitialState(initialState)
    .case(loadUsers, Reducer.loadUsers)
    .case(addUser, Reducer.addUser)
    .case(updateUser, Reducer.updateUser)
    .case(setLoadingStatus, Reducer.setLoadingStatus);
