import * as React from 'react';

import * as styles from './JournalPage.scss';

import { ResponsiveLayout, CentralContent } from 'sber-marketing-ui';
import { AdminDashboardPage } from '../../common/AdminDashboardPage';
import { FilterForm } from '../FilterForm';
import { Journal } from '../Journal';
import { ClearJournalModal } from '../ClearJournalModal';
import { JournalPageTemplateProps } from './types';

/** "JournalPage" template component */
export const JournalPageTemplate: React.StatelessComponent<JournalPageTemplateProps> = ({
    showClearModal,
}: JournalPageTemplateProps): JSX.Element => (
    <AdminDashboardPage>
        <div className={styles.root}>
            <ResponsiveLayout>
                <CentralContent>
                    <div className={styles.cardWrapper}>
                        <FilterForm />
                    </div>

                    <div className={styles.cardWrapper}>
                        <Journal />
                    </div>

                    {showClearModal && <ClearJournalModal />}
                </CentralContent>
            </ResponsiveLayout>
        </div>
    </AdminDashboardPage>
);
