import { parse } from 'url';
import { Dispatch } from 'redux';

import { State } from '../types';
import { AuthApi } from '../../api';
import { routeAuthorizationErrorPage, setIsAuthChecking, setIsSessionExpired, setIsSessionWatching } from './actions';
import { getIsSessionWatching } from './selectors';
import { setUserFirstName, setUserSecondName } from '../authError';

/** Session control async worker function */
const sessionControlWorker = async (dispatch: Dispatch<State>) => {
    const leftTime = await AuthApi.getInstance().leftTime();
    if (leftTime <= 0) {
        dispatch(setIsSessionExpired(true));
    } else {
        window.setTimeout(sessionControlWorker.bind(null, dispatch), leftTime);
    }
};

/** Thunk for route actions to check user's authorization */
export const authRequired = async (dispatch: Dispatch<State>, getState: () => State) => {
    dispatch(setIsAuthChecking(true));
    const authApi = AuthApi.getInstance();
    const { isAuthorized, hasAccessToApplication, user } = await authApi.isAuthorized();
    if (!isAuthorized) {
        const { path } = parse(window.location.href);
        authApi.login(path);
    } else if (!hasAccessToApplication) {
        dispatch(routeAuthorizationErrorPage({ query: { type: 'no-access' } }));
    } else {
        const isSessionWatching = getIsSessionWatching(getState());
        if (!isSessionWatching) {
            dispatch(setIsSessionWatching(true));
            await sessionControlWorker(dispatch);
        }
        dispatch(setUserFirstName(user.firstName));
        dispatch(setUserSecondName(user.secondName));
    }
    dispatch(setIsAuthChecking(false));
};

/** Logout thunk */
export const logout = (): any => async (dispatch: Dispatch<State>) => {
    dispatch(setIsAuthChecking(true));
    try {
        AuthApi.getInstance().logout();
    } catch (err) {
        console.error(err);
        dispatch(setIsAuthChecking(false));
    }
};
