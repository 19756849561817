import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as lodash from 'lodash';

import * as style from './Filters.scss';

import type { State as StoreState } from '../../../../../../store';
import { ColumnName, Filter } from '../../../../../../store/rolesPage/types';

import { FilterMenu, FilterItem, FilterCheckboxBorderType } from 'sber-marketing-ui';
import { setTableFilters } from '../../../../../../store/rolesPage/actions';
import { getFilters } from '../../../../../../store/rolesPage/selectors';

interface Props {
    columnName: ColumnName;
    items: FilterItem[];
}

export const Filters = ({ columnName, items }: Props): JSX.Element => {
    const dispatch = useDispatch();

    const filters = useSelector((state: StoreState) => getFilters(state));

    const filtersRef = React.useRef<Filter[]>([]);

    React.useEffect(() => {
        filtersRef.current = filters;
    }, [filters]);

    const filter = filters.find((item) => item.columnName === columnName);

    const selectedValues = filter?.selectedValues || [];

    const onItemSelection = React.useCallback(
        (checkedItemsIds: React.ReactText[]) => {
            const filters = filtersRef.current;

            let updatedFilters = lodash.clone(filters);

            const filter = updatedFilters.find((item) => item.columnName === columnName);

            if (filter && !lodash.isEmpty(checkedItemsIds)) {
                filter.selectedValues = checkedItemsIds;
            } else if (filter && lodash.isEmpty(checkedItemsIds)) {
                updatedFilters = lodash.without(updatedFilters, filter);
            } else {
                updatedFilters.push({ columnName, selectedValues: checkedItemsIds });
            }

            dispatch(setTableFilters(updatedFilters));
        },
        [dispatch],
    );

    return (
        <div className={style.root}>
            <FilterMenu
                filterTitle={'Отображаемые значения'}
                items={items}
                checkedItemsIds={selectedValues}
                onItemSelection={onItemSelection}
                border={FilterCheckboxBorderType.NONE}
                preserveAllSelectedState
                freezeExpansion
                isExpanded
                disableSort
            />
        </div>
    );
};
