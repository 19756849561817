import { combineReducers } from 'redux';
import { Configuration } from 'sber-marketing-types/system-admin';

import {
    requestTimeoutFactory,
    storeFactory,
    passwordTimeoutFactory,
    wrongPasswordsCountFactory,
    fileSplittingFactory,
    transitPasswordLifetimeFactory,
} from './configurationReducers';

const requestTimeout = requestTimeoutFactory();

const store = storeFactory();

const passwordTimeout = passwordTimeoutFactory();

const wrongPasswordsCount = wrongPasswordsCountFactory();

const fileSplitting = fileSplittingFactory();

const transitPasswordLifetime = transitPasswordLifetimeFactory();

export const previous = combineReducers<Configuration>({
    requestTimeout,
    store,
    passwordTimeout,
    wrongPasswordsCount,
    fileSplitting,
    transitPasswordLifetime,
});
