import * as React from 'react';
import * as classnames from 'classnames';
import { Preloader, OpacityTransition, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

import * as styles from './FilterForm.scss';
import { FilterFormTemplateProps } from './types';
import { OrganizationSuggest } from '../OrganizationSuggest';
import { UserIdsSuggest } from '../UserIdsSuggest';
import { UserEmailsSuggest } from '../UserEmailsSuggest';
import { RolesSuggest } from '../RolesSuggest';
import { SortSelect } from '../SortSelect';
import { PageSizeSelect } from '../PageSizeSelect';
import { EventTypesMultiSelect } from '../EventTypesMultiSelect';
import { Link } from '../../common/Link';
import { PeriodEditor } from '../PeriodEditor';
import { ErrorWithRetry } from '../../common/ErrorWithRetry';

const resolveRootClass = (isLoading: boolean, className?: string): string =>
    classnames(styles.root, isLoading && styles.rootLoading, className);

/** "FilterForm" template component */
export const FilterFormTemplate: React.StatelessComponent<FilterFormTemplateProps> = ({
    className,
    onSubmitClick,
    onSubmitClickRoute,
    onClearClick,
    onClearClickRoute,
    isLoading,
    error,
    errorCode,
    hasError,
    loadFilterData,
}: FilterFormTemplateProps) => (
    <div className={resolveRootClass(isLoading, className)}>
        {isLoading && <Preloader />}

        {hasError && (
            <OpacityTransition>
                <ErrorWithRetry error={error} errorCode={errorCode} onRetryButtonClick={loadFilterData} />
            </OpacityTransition>
        )}

        {!hasError && (
            <OpacityTransition>
                <div className={styles.cardTitle}>Фильтры</div>

                <div className={styles.blockWrapper}>
                    <PeriodEditor className={styles.periodSection} />
                </div>

                <div className={styles.title}>Типы событий</div>
                <div className={styles.blockWrapper}>
                    <EventTypesMultiSelect />
                </div>

                <div className={styles.blockWrapper}>
                    <div className={styles.blockWrapper_half}>
                        <OrganizationSuggest />
                    </div>
                    <div className={styles.blockWrapper_half}>
                        <RolesSuggest />
                    </div>
                </div>

                <div className={styles.blockWrapper}>
                    <div className={styles.blockWrapper_half}>
                        <UserIdsSuggest />
                    </div>
                    <div className={styles.blockWrapper_half}>
                        <UserEmailsSuggest />
                    </div>
                </div>
                <div className={styles.blockWrapper}>
                    <div className={styles.periodSection}>
                        <SortSelect className={styles.item} />
                        <PageSizeSelect className={styles.item} />
                    </div>
                </div>
                <div className={styles.blockWrapper}>
                    <Link className={styles.button} to={onSubmitClickRoute} onClick={onSubmitClick}>
                        Применить
                    </Link>

                    <Link
                        className={styles.button}
                        to={onClearClickRoute}
                        onClick={onClearClick}
                        theme={ButtonTheme.Ghost}
                    >
                        Очистить
                    </Link>
                </div>
            </OpacityTransition>
        )}
    </div>
);
