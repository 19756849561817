// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-common-GlobalPreloaderPage-_GlobalPreloaderPage__root{display:flex;width:100%;height:100vh;background-color:#f1f5f7;align-items:center;justify-content:center}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/common/GlobalPreloaderPage/GlobalPreloaderPage.scss"],"names":[],"mappings":"AAAA,8EACI,YAAa,CAEb,UAAW,CACX,YAAa,CAEb,wBAAyB,CAEzB,kBAAmB,CACnB,sBAAuB","sourcesContent":[".root {\n    display: flex;\n\n    width: 100%;\n    height: 100vh;\n\n    background-color: #f1f5f7;\n\n    align-items: center;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-common-GlobalPreloaderPage-_GlobalPreloaderPage__root"
};
export default ___CSS_LOADER_EXPORT___;
