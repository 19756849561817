import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { without } from 'lodash';

import { MappedState, MappedActions, UserIdsSuggestCommonProps } from './types';
import { State } from '../../../store/types';
import { getAuthorIds, addAuthorId, removeAuthorId } from '../../../store/journalFilters';
import { getAllUsersIds, getUsersByIds } from '../../../store/filterData';
import {
    MultipleSelectSuggest,
    MultipleSelectSuggestItem,
    MultipleSelectSuggestProps,
} from '../../common/MultipleSelectSuggest';

const mapStateToProps = (state: State): MappedState => {
    const selectedIds: number[] = getAuthorIds(state);
    const selected: MultipleSelectSuggestItem[] = getUsersByIds(state, selectedIds).map(({ id }) => ({
        name: id,
        value: id,
    }));
    const restIds: number[] = without(getAllUsersIds(state), ...selectedIds);
    const rest: MultipleSelectSuggestItem[] = getUsersByIds(state, restIds).map(({ id }) => ({ name: id, value: id }));
    return { selected, rest };
};

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    onAddItem: (id: number) => dispatch(addAuthorId(id)),
    onRemoveItem: (id: number) => dispatch(removeAuthorId(id)),
});

const mergeProps = (
    { selected, rest }: MappedState,
    { onAddItem, onRemoveItem }: MappedActions,
    { className }: UserIdsSuggestCommonProps,
): MultipleSelectSuggestProps => ({
    className,
    selected,
    rest,
    title: 'Добавить идентификатор',
    onAddItem: ({ value }: MultipleSelectSuggestItem) => onAddItem(<number>value),
    onRemoveItem: ({ value }: MultipleSelectSuggestItem) => onRemoveItem(<number>value),
});

const withStore = connect(mapStateToProps, mapDispatchToProps, mergeProps);

/** "UserIdsSuggest" store component */
export const UserIdsSuggestStore: ComponentType<UserIdsSuggestCommonProps> = withStore(MultipleSelectSuggest);
