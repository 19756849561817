import * as React from 'react';

import * as style from './Cursor.scss';

import { CellEvent } from '../../types';

interface Props {
    editEnabled: boolean;
    component: React.ClassType<any, any, any>;
    cellProps: any;
    onCellEvent: (eventType: CellEvent) => void;
    onMaskClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const CursorTemplate = ({ editEnabled, component, cellProps, onCellEvent, onMaskClick }: Props): JSX.Element => {
    return (
        <div className={style.root} onClick={() => onCellEvent(CellEvent.Click)}>
            {React.createElement(component, cellProps)}

            {editEnabled && <div className={style.mask} onClick={onMaskClick} />}
        </div>
    );
};
