import * as React from 'react';

import * as style from './TextColumnHeader.scss';

interface Props {
    title: string;
}

export const TextColumnHeader = ({ title }: Props): JSX.Element => {
    return <div className={style.root}>{title}</div>;
};
