import { StackFrame } from 'error-stack-parser';

/** Journal clearing status */
export const enum JournalClearingStatus {
    /** Journal not cleared (default state) */
    NOT_CLEARED = 'not_cleared',
    /** Awaiting for user sumbition */
    WAIT_FOR_SUMBIT = 'wait_for_submit',
    /** Clearing in progress */
    IN_PROGRESS = 'in_progress',
    /** Error was thrown */
    ERROR = 'error',
    /** Clearing done */
    CLEARED = 'cleared',
}

/** Journal clearing state */
export interface JournalClearState {
    /** Journal clearing status */
    status: JournalClearingStatus;
    /** Journal clearing error (current) */
    error: Error | null;
}
