import * as React from 'react';
import * as classnames from 'classnames';

import * as styles from './MultipleSelectItem.scss';
import { MultipleSelectItemTemplateProps } from './types';

/** "MultipleSelectItem" template component */
export const MultipleSelectItemTemplate: React.StatelessComponent<MultipleSelectItemTemplateProps> = ({
    className,
    name,
    isHovered,
    onRemoveClick,
    onMouseOver,
    onMouseLeave,
}: MultipleSelectItemTemplateProps) => {
    const rootClass: string = classnames(styles.root, isHovered && styles.root_hover, className);
    return (
        <div className={rootClass} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={onRemoveClick}>
            <div className={styles.name}>{name}</div>
            {isHovered && <div className={styles.removeButton}>+</div>}
        </div>
    );
};
