// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-common-MultipleSelectSuggest-_MultipleSelectSuggest__root{width:auto}.src-client-layers-view-common-MultipleSelectSuggest-_MultipleSelectSuggest__suggestWrap{width:100%}.src-client-layers-view-common-MultipleSelectSuggest-_MultipleSelectSuggest__selectedItemsContainer{display:flex;width:100%;align-items:center;justify-content:flex-start;flex-wrap:wrap}.src-client-layers-view-common-MultipleSelectSuggest-_MultipleSelectSuggest__selectedItem{margin-top:4px;margin-right:16px}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/common/MultipleSelectSuggest/MultipleSelectSuggest.scss"],"names":[],"mappings":"AAAA,kFACI,UAAW,CACd,yFAGG,UAAW,CACd,oGAGG,YAAa,CAEb,UAAW,CAEX,kBAAmB,CACnB,0BAA2B,CAC3B,cAAe,CAClB,0FAGG,cAAe,CACf,iBAAkB","sourcesContent":[".root {\n    width: auto;\n}\n\n.suggestWrap {\n    width: 100%;\n}\n\n.selectedItemsContainer {\n    display: flex;\n\n    width: 100%;\n\n    align-items: center;\n    justify-content: flex-start;\n    flex-wrap: wrap;\n}\n\n.selectedItem {\n    margin-top: 4px;\n    margin-right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-common-MultipleSelectSuggest-_MultipleSelectSuggest__root",
	"suggestWrap": "src-client-layers-view-common-MultipleSelectSuggest-_MultipleSelectSuggest__suggestWrap",
	"selectedItemsContainer": "src-client-layers-view-common-MultipleSelectSuggest-_MultipleSelectSuggest__selectedItemsContainer",
	"selectedItem": "src-client-layers-view-common-MultipleSelectSuggest-_MultipleSelectSuggest__selectedItem"
};
export default ___CSS_LOADER_EXPORT___;
