// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-journal-JournalEventsTable-_JournalEventsTable__root{background-color:#ffffff}.src-client-layers-view-journal-JournalEventsTable-_JournalEventsTable__head{border-bottom:solid 1px #dcdcdc}.src-client-layers-view-journal-JournalEventsTable-_JournalEventsTable__headTimestamp{width:115px}.src-client-layers-view-journal-JournalEventsTable-_JournalEventsTable__headCell{padding:10px 14px;font-size:12px;font-weight:400}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/journal/JournalEventsTable/JournalEventsTable.scss"],"names":[],"mappings":"AAAA,6EACI,wBAAyB,CAC5B,6EAGG,+BAAgC,CACnC,sFAGG,WAAY,CACf,iFAGG,iBAAkB,CAElB,cAAe,CACf,eAAgB","sourcesContent":[".root {\n    background-color: #ffffff;\n}\n\n.head {\n    border-bottom: solid 1px #dcdcdc;\n}\n\n.headTimestamp {\n    width: 115px;\n}\n\n.headCell {\n    padding: 10px 14px;\n\n    font-size: 12px;\n    font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-journal-JournalEventsTable-_JournalEventsTable__root",
	"head": "src-client-layers-view-journal-JournalEventsTable-_JournalEventsTable__head",
	"headTimestamp": "src-client-layers-view-journal-JournalEventsTable-_JournalEventsTable__headTimestamp",
	"headCell": "src-client-layers-view-journal-JournalEventsTable-_JournalEventsTable__headCell"
};
export default ___CSS_LOADER_EXPORT___;
