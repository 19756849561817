import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../../store/types';
import { addEventType, removeEventType, getEventTypes } from '../../../store/journalFilters';
import { MappedActions, MappedState, EventTypesMultiSelectStoreProps } from './types';
import { EventTypesMultiSelectContainer } from './EventTypesMultiSelectContainer';

const mapStateToProps = (state: State): MappedState => ({
    selected: getEventTypes(state),
});

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    onAddItem: (value) => dispatch(addEventType(value)),
    onRemoveItem: (value) => dispatch(removeEventType(value)),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** "EventTypesMultiSelect" store component */
export const EventTypesMultiSelectStore: ComponentType<EventTypesMultiSelectStoreProps> =
    withStore(EventTypesMultiSelectContainer);
