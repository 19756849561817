import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { combineReducers } from 'redux';
import { get, defaultTo } from 'lodash';

import { AuthError } from './types';
import { setType, setUserFirstName, setUserSecondName } from './actions';
import { replaceReducer } from '../commonReducers';
import { routeAuthorizationErrorPage } from '../routing/actions';

const userFirstName = reducerWithInitialState<string | null>(null).case(setUserFirstName, replaceReducer);

const userSecondName = reducerWithInitialState<string | null>(null).case(setUserSecondName, replaceReducer);

const type = reducerWithInitialState<'not-authorized' | 'no-access' | 'other'>('other')
    .case(setType, replaceReducer)
    .case(routeAuthorizationErrorPage, (_, payload) => defaultTo(get(payload, 'query.type'), 'other'));

export const authError = combineReducers<AuthError>({
    userFirstName,
    userSecondName,
    type,
});
