import axios, { AxiosResponse } from 'axios';

const BASE_URL: string = typeof window != 'undefined' && window['BASE_URL'] ? window['BASE_URL'] : '';

const instance = axios.create({
    baseURL: BASE_URL,
});

instance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(new Error(error.response.data.data.data.message)),
);

export { instance as axios, AxiosResponse };
