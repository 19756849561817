import { ComponentType } from 'react';
import { connect } from 'react-redux';

import { State } from '../../../store/types';
import { JournalClearingStatus, getJournalClearingStatus } from '../../../store/journalClear';
import { JournalPageTemplateProps } from './types';
import { JournalPageTemplate } from './JournalPageTemplate';

const mapStateToProps = (state: State): JournalClearingStatus => getJournalClearingStatus(state);

const mergeProps = (status: JournalClearingStatus, actions: null): JournalPageTemplateProps => ({
    showClearModal: status !== JournalClearingStatus.NOT_CLEARED,
});

const withStore = connect(mapStateToProps, null, mergeProps);

/** "JournalPage" store component */
export const JournalPageStore: ComponentType = withStore(JournalPageTemplate);
