// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-common-OrganizationInfo-_OrganizationInfo__root{font-family:'Open Sans';font-size:14px}.src-client-layers-view-common-OrganizationInfo-_OrganizationInfo__line{margin-bottom:12px}.src-client-layers-view-common-OrganizationInfo-_OrganizationInfo__line:last-child{margin-bottom:0px}.src-client-layers-view-common-OrganizationInfo-_OrganizationInfo__fieldName{margin-bottom:4px;text-transform:uppercase;color:#404040;font-size:8px;line-height:12px}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/common/OrganizationInfo/OrganizationInfo.scss"],"names":[],"mappings":"AAAA,wEACI,uBAAwB,CACxB,cAAe,CAClB,wEAGG,kBAAmB,CADvB,mFAIQ,iBAAkB,CACrB,6EAID,iBAAkB,CAElB,wBAAyB,CAEzB,aAAc,CAEd,aAAc,CACd,gBAAiB","sourcesContent":[".root {\n    font-family: 'Open Sans';\n    font-size: 14px;\n}\n\n.line {\n    margin-bottom: 12px;\n\n    &:last-child {\n        margin-bottom: 0px;\n    }\n}\n\n.fieldName {\n    margin-bottom: 4px;\n\n    text-transform: uppercase;\n\n    color: #404040;\n\n    font-size: 8px;\n    line-height: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-common-OrganizationInfo-_OrganizationInfo__root",
	"line": "src-client-layers-view-common-OrganizationInfo-_OrganizationInfo__line",
	"fieldName": "src-client-layers-view-common-OrganizationInfo-_OrganizationInfo__fieldName"
};
export default ___CSS_LOADER_EXPORT___;
