import { createElement, Component } from 'react';

import { ConfigurationEditorContainerProps, ConfigurationEditorTemplateProps } from './types';
import { ConfigurationEditorTemplate } from './ConfigurationEditorTemplate';
import { ItemLoadingStatus } from '../../../store/types';

/** "ConfigurationEditor" container component */
export class ConfigurationEditorContainer extends Component<ConfigurationEditorContainerProps> {
    /** @override */
    public componentDidMount() {
        const { status, loadConfiguration } = this.props;
        if (status === ItemLoadingStatus.NOT_LOADED) {
            loadConfiguration();
        }
    }
    /** @override */
    public render(): JSX.Element {
        return createElement(ConfigurationEditorTemplate, this.getTemplateProps());
    }
    /** Returns "ConfigurationEditor" template component properties */
    private getTemplateProps(): ConfigurationEditorTemplateProps {
        const { className } = this.props;
        const isLoading: boolean =
            this.props.status !== ItemLoadingStatus.LOADED && this.props.status !== ItemLoadingStatus.ERROR;
        const hasError = this.props.status === ItemLoadingStatus.ERROR;
        return { className, isLoading, hasError };
    }
}
