import * as React from 'react';
import { StyledPopup } from 'sber-marketing-ui';

import { SessionControlTemplateProps } from './types';

/** Expired session popup title */
const EXPIRED_SESSION_POPUP_TITLE = 'Пожалуйста, войдите заново';

/** Expired session popup message */
const EXPIRED_SESSION_POPUP_MESSAGE =
    'Ваша сессия истекла. Пожалуйста, авторизуйтесь заново. Это необходимо для безопасности вашего аккаунта';

/** Expired session popup button label */
const EXPIRED_SESSION_POPUP_BUTTON_LABEL = 'Авторизоваться';

export const SessionControlTemplate: React.SFC<SessionControlTemplateProps> = ({
    isSessionExpired,
    logout,
    children,
}: SessionControlTemplateProps): JSX.Element => (
    <React.Fragment>
        {children}

        {isSessionExpired && (
            <StyledPopup
                title={EXPIRED_SESSION_POPUP_TITLE}
                fullMessage={EXPIRED_SESSION_POPUP_MESSAGE}
                firstButtonText={EXPIRED_SESSION_POPUP_BUTTON_LABEL}
                firstButtonHandler={logout}
            />
        )}
    </React.Fragment>
);
