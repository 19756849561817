// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-organizations-OrganizationsPage-OrganizationForm-CreateForm-RolesButton-_RolesButton__root{height:24px;display:inline-flex;align-items:center;padding:0 8px;background:#f8f8f8;border-radius:5px;font-family:'Open Sans';font-size:12px;line-height:16px;color:#000000;user-select:none;cursor:pointer;text-decoration:none}.src-client-layers-view-organizations-OrganizationsPage-OrganizationForm-CreateForm-RolesButton-_RolesButton__root:hover{background:#dfdfdf}.src-client-layers-view-organizations-OrganizationsPage-OrganizationForm-CreateForm-RolesButton-_RolesButton__icon{width:16px;height:16px;display:flex;align-items:center;justify-content:center;margin-right:6px;color:#5b605c}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/organizations/OrganizationsPage/OrganizationForm/CreateForm/RolesButton/RolesButton.scss"],"names":[],"mappings":"AAAA,mHACI,WAAY,CACZ,mBAAoB,CACpB,kBAAmB,CACnB,aAAc,CACd,kBAAmB,CACnB,iBAAkB,CAClB,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,aAAc,CACd,gBAAiB,CACjB,cAAe,CACf,oBAAqB,CAbzB,yHAgBQ,kBAAgC,CACnC,mHAID,UAAW,CACX,WAAY,CACZ,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,gBAAiB,CACjB,aAAc","sourcesContent":[".root {\n    height: 24px;\n    display: inline-flex;\n    align-items: center;\n    padding: 0 8px;\n    background: #f8f8f8;\n    border-radius: 5px;\n    font-family: 'Open Sans';\n    font-size: 12px;\n    line-height: 16px;\n    color: #000000;\n    user-select: none;\n    cursor: pointer;\n    text-decoration: none;\n\n    &:hover {\n        background: darken(#f8f8f8, 10%);\n    }\n}\n\n.icon {\n    width: 16px;\n    height: 16px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-right: 6px;\n    color: #5b605c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-organizations-OrganizationsPage-OrganizationForm-CreateForm-RolesButton-_RolesButton__root",
	"icon": "src-client-layers-view-organizations-OrganizationsPage-OrganizationForm-CreateForm-RolesButton-_RolesButton__icon"
};
export default ___CSS_LOADER_EXPORT___;
