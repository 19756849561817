/**
 * @fileoverview Reducers and reducers factories with same logic for different store modules
 */

import { AsyncActionCreators, Failure } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { isNil } from 'lodash';

import { ItemLoadingStatus } from './types';
import { isAxiosError } from './utils';

// Common reducers

/** Null reducer - replace state value to null */
export const nullReducer = (): null => null;

/** Skip reducer - returns old state */
export const skipReducer = <T>(state: T): T => state;

/** Replace reducer - reducer for actions which directly replaces old state by new state */
export const replaceReducer = <T>(state: T, payload: T): T => payload;

/** "ItemLoadingStatus.LOADING" reducer */
export const loadingStatusReducer = (): ItemLoadingStatus => ItemLoadingStatus.LOADING;

/** "ItemLoadingStatus.ERROR" reducer */
export const errorStatusReducer = (): ItemLoadingStatus => ItemLoadingStatus.ERROR;

/** "ItemLoadingStatus.LOADED" reducer */
export const loadedStatusReducer = (): ItemLoadingStatus => ItemLoadingStatus.LOADED;

// Reducers factories

const getDones = (asyncActions: AsyncActionCreators<any, any, Error>[]) => asyncActions.map((action) => action.done);

const getFailes = (asyncActions: AsyncActionCreators<any, any, Error>[]) => asyncActions.map((action) => action.failed);

const getStarts = (asyncActions: AsyncActionCreators<any, any, Error>[]) =>
    asyncActions.map((action) => action.started);

/** Creates reducer for "ItemLoadingStatus" type reducer */
export const itemLoadingStatusReducerFactory = () =>
    reducerWithInitialState<ItemLoadingStatus>(ItemLoadingStatus.NOT_LOADED);

/** Creates reducer for field with HTTP status code errors */
export const axiosErrorCodeReducerFactory = () => reducerWithInitialState<number | null>(null);

/** Creates reducer for error */
export const errorReducerFactory = () => reducerWithInitialState<Error | null>(null);

/** Creates reducer for nullable string ("string" or "null") */
export const nullableStringReducerFactory = () => reducerWithInitialState<string | null>(null);

/** Creates loading status reducer for multiple async actions */
export const loadingStatusReducerFactory = (...asyncActions: AsyncActionCreators<any, any, Error>[]) =>
    itemLoadingStatusReducerFactory()
        .cases(getStarts(asyncActions), loadingStatusReducer)
        .cases(getFailes(asyncActions), errorStatusReducer)
        .cases(getDones(asyncActions), loadedStatusReducer);

/** Creates async action Axios error code reducer */
export const asyncActionAxiosErrorCodeReducer = (...asyncActions: AsyncActionCreators<any, any, Error>[]) =>
    axiosErrorCodeReducerFactory()
        .cases(getStarts(asyncActions), nullReducer)
        .cases(getDones(asyncActions), nullReducer)
        .cases(getFailes(asyncActions), (state: number | null, { error }: Failure<any, Error>) =>
            isAxiosError(error) && !isNil(error.response) ? error.response.status : state,
        );

/** Creates async action error reducer */
export const asyncActionErrorReducerFactory = (...asyncActions: AsyncActionCreators<any, any, Error>[]) =>
    errorReducerFactory()
        .cases(getStarts(asyncActions), nullReducer)
        .cases(getDones(asyncActions), nullReducer)
        .cases(getFailes(asyncActions), (state: Error | null, { error }: Failure<any, Error>) => error);

/** Creates async action error name reducer */
export const asyncActionErrorNameReducerFactory = (...asyncActions: AsyncActionCreators<any, any, Error>[]) =>
    nullableStringReducerFactory()
        .cases(getStarts(asyncActions), nullReducer)
        .cases(getDones(asyncActions), nullReducer)
        .cases(getFailes(asyncActions), (state: string | null, { error }: Failure<any, Error>) => error.name);

/** Creates async action error message reducer */
export const asyncActionErrorMessageReducerFactory = (...asyncActions: AsyncActionCreators<any, any, Error>[]) =>
    nullableStringReducerFactory()
        .cases(getStarts(asyncActions), nullReducer)
        .cases(getDones(asyncActions), nullReducer)
        .cases(getFailes(asyncActions), (state: string | null, { error }: Failure<any, Error>) => error.message);
