import * as React from 'react';
import * as classnames from 'classnames';
import { Preloader } from 'sber-marketing-ui';

import * as styles from './ConfigurationEditor.scss';
import { ConfigurationEditorTemplateProps } from './types';
import { PasswordTimeoutSelect } from '../PasswordTimeoutSelect';
import { RequestTimeoutSelect } from '../RequestTimeoutSelect';
import { WrongPasswordCountSelect } from '../WrongPasswordCountSelect';
import { ConfigurationLoadingError } from '../ConfigurationLoadingError';
import { SaveConfigurationButton } from '../SaveConfigurationButton';
import { StoreInput } from '../StoreInput';
import { TransitPasswordLifetimeSelect } from '../TransitPasswordLifetimeSelect';

const rootCssClass = (isLoading: boolean, className?: string): string =>
    classnames(styles.root, isLoading && styles.rootLoading, className);

export const ConfigurationEditorTemplate: React.StatelessComponent<ConfigurationEditorTemplateProps> = ({
    className,
    isLoading,
    hasError,
}: ConfigurationEditorTemplateProps): JSX.Element => (
    <div className={rootCssClass(isLoading || hasError, className)}>
        {isLoading ? (
            <Preloader />
        ) : hasError ? (
            <ConfigurationLoadingError />
        ) : (
            <React.Fragment>
                <div className={styles.items}>
                    <PasswordTimeoutSelect className={classnames(styles.item, styles.tmpHidden)} />
                    <RequestTimeoutSelect className={styles.item} />
                    <WrongPasswordCountSelect className={styles.item} />
                    <StoreInput className={styles.item} />
                    <TransitPasswordLifetimeSelect className={classnames(styles.item, styles.tmpHidden)} />
                </div>
                <SaveConfigurationButton />
            </React.Fragment>
        )}
    </div>
);
