import * as React from 'react';
import { SelectItem, LabeledSelect } from 'sber-marketing-ui';
import { SortOrder } from 'sber-marketing-types/system-admin';

import type { SortSelectTemplateProps } from './types';

const ITEMS: SelectItem[] = [
    {
        value: SortOrder.ASC,
        label: 'Сначала старые',
    },
    {
        value: SortOrder.DESC,
        label: 'Сначала новые',
    },
];

/** "SortSelect" component */
export const SortSelectTemplate: React.StatelessComponent<SortSelectTemplateProps> = ({
    className,
    value,
    onChange,
}: SortSelectTemplateProps): JSX.Element => (
    <div className={className}>
        <LabeledSelect title="Сортировка событий" selectedValue={value} items={ITEMS} onSelection={onChange} />
    </div>
);
