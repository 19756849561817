import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../../store/types';
import { getCurrentRequestTimeout, editRequestTimeout } from '../../../store/configuration';
import { MappedActions, MappedState, RequestTimeoutSelectStoreProps } from './types';
import { RequestTimeoutSelectTemplate } from './RequestTimeoutSelectTemplate';

const mapStateToProps = (state: State): MappedState => ({
    requestTimeout: getCurrentRequestTimeout(state),
});

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    editRequestTimeout: (value) => dispatch(editRequestTimeout(value)),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** "RequestTimeoutSelect" store component */
export const RequestTimeoutSelectStore: ComponentType<RequestTimeoutSelectStoreProps> =
    withStore(RequestTimeoutSelectTemplate);
