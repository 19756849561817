import { createSelector } from 'reselect';

import { State } from '../types';
import { AuthError } from './types';

const getAuthError = (state: State): AuthError => state.authError;

export const getUserFirstName = createSelector(getAuthError, ({ userFirstName }): string | null => userFirstName);

export const getUserSecondName = createSelector(getAuthError, ({ userSecondName }): string | null => userSecondName);

export const getType = createSelector(getAuthError, ({ type }): 'not-authorized' | 'no-access' | 'other' => type);
