import { OrganizationParams, CreateOrganizationParams, UpdateOrganizationParams } from 'sber-marketing-types/admin';

import { axios } from '../../lib';

const BASE_URL = '/api/organization';

export class OrganizationApi {
    public static async getOrganization(organizationId: string): Promise<OrganizationParams> {
        const { data } = await axios.get<OrganizationParams>(`${BASE_URL}/${organizationId}`);

        return data;
    }

    public static async getList(): Promise<OrganizationParams[]> {
        const { data } = await axios.get<OrganizationParams[]>(`${BASE_URL}`);

        return data;
    }

    public static async createOrganization(params: CreateOrganizationParams): Promise<OrganizationParams> {
        const { data } = await axios.post<OrganizationParams>(`${BASE_URL}`, params);

        return data;
    }

    public static async updateOrganization(id: string, params: UpdateOrganizationParams): Promise<void> {
        await axios.put(`${BASE_URL}/${id}`, params);
    }

    public static async deleteOrganization(id: string): Promise<void> {
        await axios.delete(`${BASE_URL}/${id}`);
    }

    public static async getDepartmentByOrganizationId(id: string, name: string): Promise<string> {
        const { data } = await axios.get(`${BASE_URL}/${id}/department/${name}`);

        return data;
    }
}
