import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

import { State } from '../../../store/types';
import {
    FilterFormStoreMappedActions,
    FilterFormStoreMappedState,
    WithFilterDataPropsStore,
    FilterFormTemplateProps,
    FilterFormCommonProps,
} from './types';
import { FilterFormTemplate } from './FilterFormTemplate';
import { DEFAULT_PAGE, loadJournalPage } from '../../../store/journal';
import { getEventsRecordsListQuery } from '../../../store/journalFilters';
import { routeJournalPage } from '../../../store/routing';
import { withFilterData } from '../../common/hocs';

const mapStateToProps = (state: State): FilterFormStoreMappedState => ({
    listQuery: getEventsRecordsListQuery(state, DEFAULT_PAGE),
});

const mapDispatchToProps = (dispatch: Dispatch<State>): FilterFormStoreMappedActions => ({
    loadJournalPage: (pageNumber) => dispatch(loadJournalPage(pageNumber)),
});

const mergeProps = (
    { listQuery }: FilterFormStoreMappedState,
    { loadJournalPage }: FilterFormStoreMappedActions,
    props: WithFilterDataPropsStore,
): FilterFormTemplateProps => ({
    ...props,
    onSubmitClick: debounce(() => loadJournalPage(DEFAULT_PAGE)),
    onSubmitClickRoute: routeJournalPage({ query: listQuery }),
    onClearClick: debounce(() => loadJournalPage(DEFAULT_PAGE)),
    onClearClickRoute: routeJournalPage({ query: {} }),
});

const withStore = connect(mapStateToProps, mapDispatchToProps, mergeProps);

/** "FilterForm" store component */
export const FilterFormStore: ComponentType<FilterFormCommonProps> = withFilterData<FilterFormCommonProps>(
    withStore(FilterFormTemplate),
);
