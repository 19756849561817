import * as React from 'react';
import * as classnames from 'classnames';

import * as styles from './JournalEventsTable.scss';
import { JournalEventsTableTemplateProps } from './types';
import { JournalEventLine } from '../JournalEventLine';

/** "JournalEventsTable" template component */
export const JournalEventsTableTemplate: React.StatelessComponent<JournalEventsTableTemplateProps> = ({
    className,
    records,
}: JournalEventsTableTemplateProps): JSX.Element => (
    <table className={classnames(styles.root, className)}>
        <thead className={styles.head}>
            <tr>
                <th className={classnames(styles.headCell, styles.headTimestamp)}>Дата и время</th>
                <th className={styles.headCell}>Автор</th>
                <th className={styles.headCell}>IP-адрес</th>
                <th className={styles.headCell}>Тип</th>
                <th className={styles.headCell}>Подробности</th>
            </tr>
        </thead>
        <tbody>
            {records.map((record) => (
                <JournalEventLine key={record.id} {...record} />
            ))}
        </tbody>
    </table>
);
