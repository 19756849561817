import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State, ItemLoadingStatus } from '../../../store/types';
import { getLoadingStatus, isConfigurationChanged, updateConfiguration } from '../../../store/configuration';
import { SaveConfigurationButtonStoreProps, MappedActions, MappedState } from './types';
import { SaveConfigurationButtonTemplate } from './SaveConfigurationButtonTemplate';

const mapStateToProps = (state: State): MappedState => {
    const isLoaded: boolean = getLoadingStatus(state) === ItemLoadingStatus.LOADED;
    const isChanged: boolean = isConfigurationChanged(state);
    return {
        canBeSaved: isLoaded && isChanged,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    updateConfiguration: () => dispatch(updateConfiguration(null)),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** "SaveConfigurationButton" store component */
export const SaveConfigurationButtonStore: ComponentType<SaveConfigurationButtonStoreProps> = withStore(
    SaveConfigurationButtonTemplate,
);
