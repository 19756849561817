import * as React from 'react';

import * as style from './CreateForm.scss';

import { UserParams } from 'sber-marketing-types/admin';

import {
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    Preloader,
    LabeledInput,
    SuggestItemParams,
    Icon,
    IconType,
} from 'sber-marketing-ui';
import { OrganizationSelect } from './OrganizationSelect';
import { UserForm } from './UserForm';
import { RolesButton } from './RolesButton';

const MAX_NAME_LENGTH = 255;

export interface CreateFormParams {
    blockTitle: string;
    confirmButtonText: string;
    name: string;
}

interface Props extends CreateFormParams {
    organizationId: string;
    nameInputHeading: string;
    leftSuggestHeading: string;
    rightSuggestHeading: string;
    suggestItems: SuggestItemParams[];
    leftSuggestItems: SuggestItemParams[];
    rightSuggestItems: SuggestItemParams[];
    rigthSelectValue?: string | number;
    isBlocked?: boolean;
    userCreating: boolean;
    businessAdmin: Partial<UserParams>;
    onChangeBusinessAdminField: (field: string, value: string) => void;
    toggleUserCreating: () => void;
    onNameChange: (value: string) => void;
    onConfirmClick: () => void;
    onCancelClick: () => void;
    onLeftSwitchSelectChange?: (isChecked: boolean, value: string | number) => void;
    onRightSwitchSelectChange?: (isChecked: boolean, value: string | number) => void;
    onLeftSuggestSelect?: (item: SuggestItemParams) => void;
    onRightSuggestSelect?: (item: SuggestItemParams) => void;
}

export function CreateForm({
    organizationId,
    blockTitle,
    confirmButtonText,
    name,
    businessAdmin,
    onChangeBusinessAdminField,
    userCreating,
    toggleUserCreating,
    nameInputHeading,
    leftSuggestHeading,
    rightSuggestHeading,
    suggestItems,
    rightSuggestItems,
    leftSuggestItems,
    onLeftSuggestSelect,
    onRightSuggestSelect,
    isBlocked,
    onNameChange,
    onConfirmClick,
    onCancelClick,
}: Props): JSX.Element {
    return !userCreating ? (
        <div className={style.root}>
            {isBlocked && <Preloader />}

            <div className={style.titleWrapper}>{blockTitle}</div>

            <div className={style.topBlock}>
                <div className={style.inputBlockWrapper}>
                    <LabeledInput
                        title={nameInputHeading}
                        value={name}
                        placeholder={'Введите название'}
                        onValueChange={onNameChange}
                        maxLength={MAX_NAME_LENGTH}
                    />
                </div>

                <div className={style.businessAdminWrapper}>
                    <div className={style.heading}>Бизнес администратор</div>
                    {businessAdmin.firstName && businessAdmin.secondName ? (
                        <div className={style.businessAdminField}>
                            <div className={style.textBlockWrapper}>
                                {`${businessAdmin.firstName} ${businessAdmin.secondName}`}
                            </div>
                            <div className={style.addButton} onClick={toggleUserCreating}>
                                Редактировать
                            </div>
                        </div>
                    ) : (
                        <div className={style.addButton} onClick={toggleUserCreating}>
                            Создать
                        </div>
                    )}
                </div>
            </div>

            {organizationId && (
                <div className={style.middleBlock}>
                    <RolesButton organizationId={organizationId} />
                </div>
            )}

            <div className={style.bottomBlock}>
                <div className={style.selectWrapper}>
                    <OrganizationSelect
                        title={leftSuggestHeading}
                        items={suggestItems}
                        selectedItems={leftSuggestItems}
                        onItemChange={onLeftSuggestSelect}
                    />
                </div>

                <div className={style.selectWrapper}>
                    <OrganizationSelect
                        title={rightSuggestHeading}
                        items={suggestItems}
                        selectedItems={rightSuggestItems}
                        onItemChange={onRightSuggestSelect}
                    />
                </div>
            </div>

            <div className={style.buttonsWrapper}>
                <div className={style.buttonWrapper}>
                    <Button theme={ButtonTheme.Ghost} onClick={onCancelClick}>
                        Отменить
                    </Button>
                </div>

                <div className={style.buttonWrapper}>
                    <Button theme={ButtonTheme.Main} onClick={onConfirmClick} disabled={!name}>
                        {confirmButtonText}
                    </Button>
                </div>
            </div>
        </div>
    ) : (
        <UserForm
            toggleUserCreating={toggleUserCreating}
            businessAdmin={businessAdmin}
            onChangeBusinessAdminField={onChangeBusinessAdminField}
        />
    );
}
