import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { User } from 'sber-marketing-types/system-admin';
import { isNil, pick } from 'lodash';

import { State } from '../../../store/types';
import { getUserById } from '../../../store/filterData';
import { UserInfoCommonProps, MappedState } from './types';
import { UserInfoTemplate } from './UserInfoTemplate';

export const mapStateToProps = (state: State, { id }: UserInfoCommonProps): MappedState => {
    const user: User | undefined = getUserById(state, id);
    const result: MappedState = {
        found: !isNil(user),
    };
    if (!isNil(user)) {
        Object.assign(result, pick(user, ['firstName', 'middleName', 'secondName', 'organizationId', 'email']));
    }
    return result;
};

const withStore = connect(mapStateToProps);

/** "UserInfo" store component */
export const UserInfoStore: ComponentType<UserInfoCommonProps> = withStore(UserInfoTemplate);
