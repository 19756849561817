import { combineReducers } from 'redux';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { omit } from 'lodash';

import * as actions from './actions';
import { UserTable, RoleTable, OrganizationTable, FilterDataState } from './types';
import {
    loadingStatusReducerFactory,
    asyncActionAxiosErrorCodeReducer,
    asyncActionErrorReducerFactory,
} from '../commonReducers';

const loadingStatus = loadingStatusReducerFactory(actions.loadFilterData);

const errorCode = asyncActionAxiosErrorCodeReducer(actions.loadFilterData);

const error = asyncActionErrorReducerFactory(actions.loadFilterData);

const users = reducerWithInitialState<UserTable>({})
    .case(actions.addUser, (state, { id, ...rest }) => ({ ...state, [id]: { ...rest, id } }))
    .case(actions.bulkAddUsers, (state, users) => {
        const usersToAdd: UserTable = {};
        for (const user of users) {
            usersToAdd[user.id] = user;
        }
        return { ...state, ...usersToAdd };
    })
    .case(actions.removeUser, (state, id) => omit(state, [id]))
    .case(actions.bulkRemoveUsers, (state, ids) => omit(state, ids));

const roles = reducerWithInitialState<RoleTable>({})
    .case(actions.addRole, (state, { id, ...rest }) => ({ ...state, [id]: { ...rest, id } }))
    .case(actions.bulkAddRoles, (state, roles) => {
        const rolesToAdd: RoleTable = {};
        for (const role of roles) {
            rolesToAdd[role.id] = role;
        }
        return { ...state, ...rolesToAdd };
    })
    .case(actions.removeRole, (state, id) => omit(state, [id]))
    .case(actions.bulkRemoveRoles, (state, ids) => omit(state, ids));

const organizations = reducerWithInitialState<OrganizationTable>({})
    .case(actions.addOrganization, (state, { id, ...rest }) => ({ ...state, [id]: { ...rest, id } }))
    .case(actions.bulkAddOrganizations, (state, organizations) => {
        const organizationsToAdd: OrganizationTable = {};
        for (const organization of organizations) {
            organizationsToAdd[organization.id] = organization;
        }
        return { ...state, ...organizationsToAdd };
    })
    .case(actions.removeOrganization, (state, id) => omit(state, [id]))
    .case(actions.bulkRemoveOrganizations, (state, ids) => omit(state, ids));

export const filterData = combineReducers<FilterDataState>({
    loadingStatus,
    error,
    errorCode,
    users,
    roles,
    organizations,
});
