import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../../store/types';
import { getCurrentPasswordTimeout, editPasswordTimeout } from '../../../store/configuration';
import { MappedActions, MappedState, PasswordTimeoutSelectStoreProps } from './types';
import { PasswordTimeoutSelectTemplate } from './PasswordTimeoutSelectTemplate';

const mapStateToProps = (state: State): MappedState => ({
    passwordTimeout: getCurrentPasswordTimeout(state),
});

const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    editPasswordTimeout: (value) => dispatch(editPasswordTimeout(value)),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** "PasswordTimeoutSelect" store component */
export const PasswordTimeoutSelectStore: ComponentType<PasswordTimeoutSelectStoreProps> =
    withStore(PasswordTimeoutSelectTemplate);
