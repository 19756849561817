import * as React from 'react';
import * as classnames from 'classnames';
import { Button_redesign } from 'sber-marketing-ui';

import * as styles from './SaveConfigurationButton.scss';
import { SaveConfigurationButtonTemplateProps } from './types';

/** "SaveConfigurationButton" template component */
export const SaveConfigurationButtonTemplate: React.StatelessComponent<SaveConfigurationButtonTemplateProps> = ({
    className,
    canBeSaved,
    updateConfiguration,
}: SaveConfigurationButtonTemplateProps): JSX.Element => (
    <div className={classnames(styles.root, className)}>
        <Button_redesign disabled={!canBeSaved} onClick={updateConfiguration}>
            Сохранить
        </Button_redesign>
    </div>
);
