import * as React from 'react';

import * as style from './OrganizationSelect.scss';

import { Icon, IconType, Suggest, SuggestItemParams } from 'sber-marketing-ui';

export interface OrganizationSelectProps {
    title: string;
    items: SuggestItemParams[];
}

interface Props extends OrganizationSelectProps {
    selectedItems: SuggestItemParams[];
    onItemChange: (item: SuggestItemParams) => void;
}

export function OrganizationSelect({ title, items, selectedItems, onItemChange }: Props): JSX.Element {
    return (
        <div className={style.root}>
            <div className={style.selectWrapper}>
                <Suggest
                    title={title}
                    items={items}
                    selectedItems={selectedItems}
                    onItemSelect={onItemChange}
                    multipleSelect
                />
            </div>

            <div className={style.itemsWrapper}>
                {selectedItems.map((item) => (
                    <div key={item.value}>
                        <SelectedItem item={item} onRemoveClick={onItemChange} />
                    </div>
                ))}
            </div>
        </div>
    );
}

interface SelectedItemProps {
    item: SuggestItemParams;
    onRemoveClick: (item: SuggestItemParams) => void;
}

function SelectedItem({ item, onRemoveClick }: SelectedItemProps): JSX.Element {
    return (
        <div className={style.itemWrapper}>
            <div className={style.name}>{item.label}</div>

            <div className={style.removeIcon} onClick={() => onRemoveClick(item)}>
                <Icon type={IconType.CROSS} />
            </div>
        </div>
    );
}
