import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../../store/types';
import { getLoadingError, getLoadingErrorCode, loadConfiguration } from '../../../store/configuration';
import { ConfigurationLoadingErrorStoreProps, MappedActions, MappedState } from './types';
import { ErrorWithRetry } from '../../common/ErrorWithRetry';

export const mapStateToProps = (state: State): MappedState => ({
    error: getLoadingError(state)!,
    errorCode: getLoadingErrorCode(state),
});

export const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    onRetryButtonClick: () => dispatch(loadConfiguration(null)),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** "ConfigurationLoadingError" store component */
export const ConfigurationLoadingErrorStore: ComponentType<ConfigurationLoadingErrorStoreProps> =
    withStore(ErrorWithRetry);
