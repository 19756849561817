import * as React from 'react';
import { LabeledSelect, SelectItem } from 'sber-marketing-ui';
import { RequestTimeout } from 'sber-marketing-types/system-admin';

import { RequestTimeoutSelectTemplateProps } from './types';

const ITEMS: SelectItem[] = [
    {
        value: RequestTimeout.HALF_HOUR,
        label: 'Полчаса',
    },
    {
        value: RequestTimeout.ONE_HOUR,
        label: 'Один час',
    },
    {
        value: RequestTimeout.TWO_HOURS,
        label: 'Два часа',
    },
];

const TITLE = 'Таймаут сессии';

/** "RequestTimeoutSelect" template component */
export const RequestTimeoutSelectTemplate: React.StatelessComponent<RequestTimeoutSelectTemplateProps> = ({
    className,
    requestTimeout,
    editRequestTimeout,
}: RequestTimeoutSelectTemplateProps): JSX.Element => (
    <div className={className}>
        <LabeledSelect title={TITLE} selectedValue={requestTimeout} items={ITEMS} onSelection={editRequestTimeout} />
    </div>
);
