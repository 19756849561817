import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as moment from 'moment';

import { App } from './layers/view/common/App';

const onLoad = function () {
    moment.locale('ru');
    const root = document.getElementById('root');
    const app = <App />;
    ReactDOM.render(app, root);
};

window.addEventListener('load', onLoad);
