// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-common-ErrorCard-_ErrorCard__root{font-family:monospace}.src-client-layers-view-common-ErrorCard-_ErrorCard__name{font-size:1em;font-weight:bold}.src-client-layers-view-common-ErrorCard-_ErrorCard__message{font-size:0.75em}.src-client-layers-view-common-ErrorCard-_ErrorCard__stack{margin-left:0.75em}.src-client-layers-view-common-ErrorCard-_ErrorCard__name,.src-client-layers-view-common-ErrorCard-_ErrorCard__message{margin-bottom:0.5em}.src-client-layers-view-common-ErrorCard-_ErrorCard__name,.src-client-layers-view-common-ErrorCard-_ErrorCard__message,.src-client-layers-view-common-ErrorCard-_ErrorCard__stackItem{overflow-x:hidden;text-overflow:ellipsis}.src-client-layers-view-common-ErrorCard-_ErrorCard__stackItem{margin-bottom:0.25em;font-size:0.5em}.src-client-layers-view-common-ErrorCard-_ErrorCard__stackItem:last-child{margin-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/common/ErrorCard/ErrorCard.scss"],"names":[],"mappings":"AAAA,0DACI,qBAAsB,CACzB,0DAGG,aAAc,CACd,gBAAiB,CACpB,6DAGG,gBAAiB,CACpB,2DAGG,kBAAmB,CACtB,uHAIG,mBAAoB,CACvB,sLAKG,iBAAkB,CAElB,sBAAuB,CAC1B,+DAGG,oBAAqB,CAErB,eAAgB,CAHpB,0EAKQ,eAAgB","sourcesContent":[".root {\n    font-family: monospace;\n}\n\n.name {\n    font-size: 1em;\n    font-weight: bold;\n}\n\n.message {\n    font-size: 0.75em;\n}\n\n.stack {\n    margin-left: 0.75em;\n}\n\n.name,\n.message {\n    margin-bottom: 0.5em;\n}\n\n.name,\n.message,\n.stackItem {\n    overflow-x: hidden;\n\n    text-overflow: ellipsis;\n}\n\n.stackItem {\n    margin-bottom: 0.25em;\n\n    font-size: 0.5em;\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-common-ErrorCard-_ErrorCard__root",
	"name": "src-client-layers-view-common-ErrorCard-_ErrorCard__name",
	"message": "src-client-layers-view-common-ErrorCard-_ErrorCard__message",
	"stack": "src-client-layers-view-common-ErrorCard-_ErrorCard__stack",
	"stackItem": "src-client-layers-view-common-ErrorCard-_ErrorCard__stackItem"
};
export default ___CSS_LOADER_EXPORT___;
