import { actionCreatorFactory } from 'typescript-fsa';
import { Moment } from 'moment';
import { SortOrder, EventType, EmbedEntities } from 'sber-marketing-types/system-admin';

const creator = actionCreatorFactory('JOURNAL_FILTERS');

export const editDateFrom = creator<Moment>('EDIT_DATE_FROM');

export const editDateTill = creator<Moment>('EDIT_DATE_TILL');

export const editPageLength = creator<number>('EDIT_PAGE_LENGTH');

export const editSort = creator<SortOrder>('EDIT_SORT');

export const addAuthorId = creator<number>('ADD_AUTHOR_ID');

export const removeAuthorId = creator<number>('REMOVE_AUTHOR_ID');

export const addAuthorRole = creator<string>('ADD_AUTHOR_ROLE');

export const removeAuthorRole = creator<string>('REMOVE_AUTHOR_ROLE');

export const addAuthorEmail = creator<string>('ADD_AUTHOR_EMAIL');

export const removeAuthorEmail = creator<string>('REMOVE_AUTHOR_EMAIL');

export const addAuthorOrganizationId = creator<string>('ADD_AUTHOR_ORGANIZATION_ID');

export const removeAuthorOrganizationId = creator<string>('REMOVE_AUTHOR_ORGANIZATION_ID');

export const addEventType = creator<EventType>('ADD_EVENT_TYPE');

export const removeEventType = creator<EventType>('REMOVE_EVENT_TYPE');

export const addEmbed = creator<EmbedEntities>('ADD_EMBED');

export const removeEmbed = creator<EmbedEntities>('REMOVE_EMBED');
