import { ComponentType } from 'react';
import { connect } from 'react-redux';

import { State } from '../../../store/types';
import { getUserById } from '../../../store/filterData';
import { UserLoginStoreProps, MappedState } from './types';
import { UserLoginTemplate } from './UserLoginTemplate';

const mapStateToProps = (state: State, { id }: UserLoginStoreProps): MappedState => {
    const user = getUserById(state, id);
    return {
        login: user ? user.email : null,
    };
};

const withStore = connect(mapStateToProps);

/** "UserLogin" store component */
export const UserLoginStore: ComponentType<UserLoginStoreProps> = withStore(UserLoginTemplate);
