import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AuthErrorPage } from 'sber-marketing-ui';

import { State } from '../../../store';
import { logout } from '../../../store/routing';
import { getType, getUserFirstName, getUserSecondName } from '../../../store/authError';
import { AuthErrorPageMappedActions, AuthErrorPageMappedState } from './types';

const mapStateToProps = (state: State): AuthErrorPageMappedState => ({
    type: getType(state),
    userFirstName: getUserFirstName(state),
    userSecondName: getUserSecondName(state),
});

const mapDispatchToProps = (dispatch: Dispatch<State>): AuthErrorPageMappedActions => ({
    onLogoutClick: () => dispatch(logout()),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** Auth error page */
export const AuthErrorPageStore: ComponentType = withStore(AuthErrorPage);
