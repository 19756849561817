import { isNil } from 'lodash';
import { Configuration, ConfigurationUpdateBody } from 'sber-marketing-types/system-admin';

import { HttpClient } from '../../lib';

/** Configuration data API service class */
export class ConfigurationApi {
    /** Configuration API service instance */
    private static instance: ConfigurationApi | undefined;
    /** Base URL path for requests */
    private static basePath = '/api/configuration';
    /** HTTP Client instance */
    private http: HttpClient;
    /** @constructor */
    private constructor() {
        this.http = HttpClient.getInstance();
    }
    /** Get HTTP client service instance (creates if not exists) */
    public static getInstance(): ConfigurationApi {
        if (isNil(ConfigurationApi.instance)) {
            ConfigurationApi.instance = new ConfigurationApi();
        }
        return ConfigurationApi.instance!;
    }
    /** Get current configuration */
    public getConfiguration(): Promise<Configuration> {
        return this.http.get<{}, Configuration>(`${ConfigurationApi.basePath}/`);
    }
    /** Update configuration */
    public updateConfiguration(body: ConfigurationUpdateBody): Promise<Configuration> {
        return this.http.put<ConfigurationUpdateBody, {}, Configuration>(`${ConfigurationApi.basePath}/`, body);
    }
}
