// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-journal-JournalPagination-_JournalPagination__root{display:flex;align-items:center;justify-content:space-between}.src-client-layers-view-journal-JournalPagination-_JournalPagination__pageNumber{font-weight:bold}.src-client-layers-view-journal-JournalPagination-_JournalPagination__button{width:118px;height:26px;margin-right:10px;font-size:14px}.src-client-layers-view-journal-JournalPagination-_JournalPagination__button:last-child{margin-right:0}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/journal/JournalPagination/JournalPagination.scss"],"names":[],"mappings":"AAAA,2EACI,YAAa,CAEb,kBAAmB,CACnB,6BAA8B,CACjC,iFAGG,gBAAiB,CACpB,6EAGG,WAAY,CACZ,WAAY,CACZ,iBAAkB,CAElB,cAAe,CALnB,wFAOQ,cAAe","sourcesContent":[".root {\n    display: flex;\n\n    align-items: center;\n    justify-content: space-between;\n}\n\n.pageNumber {\n    font-weight: bold;\n}\n\n.button {\n    width: 118px;\n    height: 26px;\n    margin-right: 10px;\n\n    font-size: 14px;\n    &:last-child {\n        margin-right: 0;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-journal-JournalPagination-_JournalPagination__root",
	"pageNumber": "src-client-layers-view-journal-JournalPagination-_JournalPagination__pageNumber",
	"button": "src-client-layers-view-journal-JournalPagination-_JournalPagination__button"
};
export default ___CSS_LOADER_EXPORT___;
