import * as React from 'react';
import reactOnclickoutside from 'react-onclickoutside';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { ScrollbarComponent } from 'sber-marketing-ui';
import type { CellPosition, ColumnName, ColumnWidths, LineHeights, LineId, Point, Size } from '../types';
import { TableEvent, CellEvent } from '../types';

import { TableViewTemplate } from './TableViewTemplate';
import { TableViewModel } from '../TableViewModel';
import { Cursor } from './Cursor';
import { Virtualizer } from './modules';

const DEFAULT_COLUMN_WIDTH = 140;
const DEFAULT_COLUMN_FIXED_WIDTH = 80;
const DEFAULT_LINE_HEIGHT = 60;

const CELLS_SPACING = 1;

interface Props {
    viewModel: TableViewModel;
    columns: ColumnName[];
    leftFixedColumns?: ColumnName[];
    rightFixedColumns?: ColumnName[];
    readOnlyColumns?: ColumnName[];
    lines: LineId[];
    columnWidths?: ColumnWidths;
    onCellEvent?: (eventType: CellEvent, position: CellPosition) => void;
}

interface State {
    cursorPosition: CellPosition;
    editMode: boolean;
    columnWidths: ColumnWidths;
    lineHeights: LineHeights;
    visibleColumnsIndexes: number[];
    visibleLinesIndexes: number[];
}

@(reactOnclickoutside as any)
export class TableViewBehaviour extends React.PureComponent<Props, State> {
    private viewportRef: React.RefObject<HTMLDivElement>;
    private cursor: Cursor;
    private tableHeader: ScrollbarComponent;
    private tableBody: ScrollbarComponent;
    private leftFixedColumns: ScrollbarComponent;
    private rightFixedColumns: ScrollbarComponent;
    private virtualizer: Virtualizer;
    private tableEventsSubscribers: { [key in TableEvent]?: ((eventType: TableEvent) => void)[] } = {};

    public constructor(props: Props) {
        super(props);

        this.state = {
            cursorPosition: null,
            editMode: false,
            columnWidths: {
                ...props.columns.reduce((acc, item) => {
                    acc[item] = DEFAULT_COLUMN_WIDTH;
                    return acc;
                }, {}),
                ...(props.leftFixedColumns || []).reduce((acc, item) => {
                    acc[item] = DEFAULT_COLUMN_FIXED_WIDTH;
                    return acc;
                }, {}),
                ...(props.rightFixedColumns || []).reduce((acc, item) => {
                    acc[item] = DEFAULT_COLUMN_FIXED_WIDTH;
                    return acc;
                }, {}),
                ...(props.columnWidths || {}),
            },
            lineHeights: props.lines.reduce((acc, item) => {
                acc[item] = DEFAULT_LINE_HEIGHT;
                return acc;
            }, {}),
            visibleColumnsIndexes: [],
            visibleLinesIndexes: [],
        };

        this.viewportRef = React.createRef<HTMLDivElement>();

        this.virtualizer = new Virtualizer();
    }

    public async componentDidMount() {
        this.updateViewportSize();
        this.updateVisibleColumns();
        this.updateVisibleLines();

        window.addEventListener('resize', this.onPageResize);
    }

    public async componentWillUnmount() {
        window.removeEventListener('resize', this.onPageResize);
        this.tableEventsSubscribers = null;
    }

    public componentDidUpdate(prevProps: Props) {
        const columnsChanged = this.props.columns !== prevProps.columns;
        const linesChanged = this.props.lines !== prevProps.lines;

        if (columnsChanged) {
            this.updateVisibleColumns();
        }

        if (linesChanged) {
            this.setState(
                {
                    lineHeights: this.props.lines.reduce((acc, item) => {
                        acc[item] = DEFAULT_LINE_HEIGHT;
                        return acc;
                    }, {}),
                },
                () => {
                    this.updateViewportSize();
                    this.updateVisibleLines();
                },
            );
        }
    }

    public render(): JSX.Element {
        const { viewModel, columns, leftFixedColumns, rightFixedColumns, readOnlyColumns, lines } = this.props;
        const { cursorPosition, visibleColumnsIndexes, columnWidths, visibleLinesIndexes, lineHeights } = this.state;
        const { getColumnHeader, getCellParams } = viewModel;

        const allColumns = [...leftFixedColumns, ...columns, ...rightFixedColumns];

        return React.createElement(TableViewTemplate, {
            allColumns,
            columns,
            leftFixedColumns,
            rightFixedColumns,
            lines,
            cursorPosition,
            visibleColumnsIndexes,
            visibleLinesIndexes,
            columnWidths,
            lineHeights,
            columnsSumWidth: this.getColumnsSumWidth(columns),
            leftFixedSumWidth: this.getColumnsSumWidth(leftFixedColumns),
            rightFixedSumWidth: this.getColumnsSumWidth(rightFixedColumns),
            readOnlyColumns,
            sumHeight: this.getSumHeight(),
            viewportRef: this.viewportRef,
            tableHeaderRef: this.tableHeaderRef,
            tableBodyRef: this.tableBodyRef,
            leftFixedColumnsRef: this.leftFixedColumnsRef,
            rightFixedColumnsRef: this.rightFixedColumnsRef,
            cursorRef: this.cursorRef,
            getColumnHeader,
            getCellParams,
            selectCell: this.selectCell,
            onBodyScroll: this.onBodyScroll,
            onLeftFixedColumnsScroll: this.onLeftFixedColumnsScroll,
            onRightFixedColumnsScroll: this.onRightFixedColumnsScroll,
            onCellEvent: this.onCellEvent,
        });
    }

    @autobind
    public subscribeTableEvent(eventType: TableEvent, handler: () => void) {
        if (!this.tableEventsSubscribers[eventType]) {
            this.tableEventsSubscribers[eventType] = [];
        }

        if (!this.tableEventsSubscribers[eventType].includes(handler)) {
            this.tableEventsSubscribers[eventType].push(handler);
        }
    }

    @autobind
    public getBodyScroll(): Point {
        if (!this.tableBody) {
            return null;
        }

        return {
            x: this.tableBody.scrollLeft,
            y: this.tableBody.scrollTop,
        };
    }

    @autobind
    public setBodyScroll(scroll: Point) {
        if (!this.tableBody) {
            return;
        }

        if (this.tableBody.scrollTop !== scroll.y) {
            this.tableBody.scrollTop = scroll.y;
            this.updateVisibleLines();
        }

        if (this.tableBody.scrollLeft !== scroll.x) {
            this.tableBody.scrollLeft = scroll.x;
            this.updateVisibleColumns();
        }
    }

    @autobind
    public getCellEditStatus(position: CellPosition): boolean {
        const { cursorPosition } = this.state;

        const cellIsSelected = lodash.isEqual(position, cursorPosition);

        return cellIsSelected ? this.cursor.getEditStatus() : false;
    }

    @autobind
    public setCursorEditStatus(status: boolean) {
        if (this.cursor) {
            this.cursor.setEditStatus(status);
        }
    }

    @autobind
    public handleClickOutside() {
        const cursorEditEnabled = this.cursor && this.cursor.getEditStatus();

        if (!cursorEditEnabled) {
            this.selectCell(null);
        }
    }

    @autobind
    protected onPageResize() {
        this.updateViewportSize();
        this.updateVisibleColumns();
        this.updateVisibleLines();
        this.emitTableEvent(TableEvent.SizeChange);
    }

    @autobind
    protected onBodyScroll() {
        this.updateHorizontalScroll();
        this.updateVerticalScroll('body');
        this.updateVisibleColumns();
        this.updateVisibleLines();
        this.emitTableEvent(TableEvent.Scroll);
    }

    @autobind
    protected onLeftFixedColumnsScroll() {
        this.updateVerticalScroll('leftFixedColumns');
        this.updateVisibleLines();
    }

    @autobind
    protected onRightFixedColumnsScroll() {
        this.updateVerticalScroll('rightFixedColumns');
        this.updateVisibleLines();
    }

    @autobind
    protected onCellEvent(eventType: CellEvent, position: CellPosition) {
        switch (eventType) {
            case CellEvent.Click:
                this.selectCell(position);
                break;
        }

        if (this.props.onCellEvent) {
            this.props.onCellEvent(eventType, position);
        }
    }

    @autobind
    protected tableHeaderRef(component: ScrollbarComponent) {
        this.tableHeader = component;
    }

    @autobind
    protected tableBodyRef(component: ScrollbarComponent) {
        this.tableBody = component;
    }

    @autobind
    protected leftFixedColumnsRef(component: ScrollbarComponent) {
        this.leftFixedColumns = component;
    }

    @autobind
    protected rightFixedColumnsRef(component: ScrollbarComponent) {
        this.rightFixedColumns = component;
    }

    @autobind
    protected cursorRef(component: Cursor) {
        this.cursor = component;
    }

    private getColumnsSumWidth(columns: ColumnName[]): number {
        if (!columns) {
            return 0;
        }

        return (
            columns.reduce((acc, columnName) => acc + this.state.columnWidths[columnName] + CELLS_SPACING, 0) -
            CELLS_SPACING
        );
    }

    private getSumHeight(): number {
        let height =
            this.props.lines.reduce((acc, lineId) => acc + this.state.lineHeights[lineId] + CELLS_SPACING, 0) -
            CELLS_SPACING;

        if (!height || height < 0) {
            height = 0;
        }

        return height;
    }

    private updateViewportSize() {
        if (this.viewportRef.current) {
            const size = this.getViewportSize();

            this.virtualizer.setViewportSize(size);
        }
    }

    private getViewportSize(): Size {
        const TABLE_BODY_MAX_HEIGHT = 610;

        let height = this.viewportRef.current.offsetHeight;
        const totalHeight = this.getSumHeight();

        if (height < totalHeight) {
            height = totalHeight;
        }

        if (height > TABLE_BODY_MAX_HEIGHT) {
            height = TABLE_BODY_MAX_HEIGHT;
        }

        return {
            width: this.viewportRef.current.offsetWidth,
            height,
        };
    }

    private updateHorizontalScroll() {
        this.tableHeader.scrollLeft = this.tableBody.scrollLeft;
    }

    private updateVerticalScroll(source: 'body' | 'leftFixedColumns' | 'rightFixedColumns') {
        switch (source) {
            case 'body':
                if (this.leftFixedColumns) {
                    this.leftFixedColumns.scrollTop = this.tableBody.scrollTop;
                }

                if (this.rightFixedColumns) {
                    this.rightFixedColumns.scrollTop = this.tableBody.scrollTop;
                }
                break;

            case 'leftFixedColumns':
                if (this.tableBody) {
                    this.tableBody.scrollTop = this.leftFixedColumns.scrollTop;
                }

                if (this.rightFixedColumns) {
                    this.rightFixedColumns.scrollTop = this.leftFixedColumns.scrollTop;
                }
                break;

            case 'rightFixedColumns':
                if (this.leftFixedColumns) {
                    this.leftFixedColumns.scrollTop = this.rightFixedColumns.scrollTop;
                }

                if (this.tableBody) {
                    this.tableBody.scrollTop = this.rightFixedColumns.scrollTop;
                }
                break;
        }
    }

    private updateVisibleColumns() {
        const { columns } = this.props;
        const { columnWidths, visibleColumnsIndexes: visibleColumns } = this.state;

        const newVisibleColumnsIndexes = this.virtualizer.getVisibleColumnsIndexes(
            columns,
            columnWidths,
            this.tableBody.scrollLeft,
        );

        const newVisibleColumnsChanged = !lodash.isEqual(newVisibleColumnsIndexes, visibleColumns);

        if (newVisibleColumnsChanged) {
            this.setState({
                visibleColumnsIndexes: newVisibleColumnsIndexes,
            });
        }
    }

    private updateVisibleLines() {
        const { lines } = this.props;
        const { lineHeights, visibleLinesIndexes: visibleLines } = this.state;

        const newVisibleLines = this.virtualizer.getVisibleLinesIndexes(lines, lineHeights, this.tableBody.scrollTop);

        const newVisibleLinesChanged = !lodash.isEqual(newVisibleLines, visibleLines);

        if (newVisibleLinesChanged) {
            this.setState({
                visibleLinesIndexes: newVisibleLines,
            });
        }
    }

    @autobind
    private selectCell(position: CellPosition) {
        const { cursorPosition } = this.state;

        const cellIsSelected = lodash.isEqual(cursorPosition, position);

        if (cellIsSelected) {
            return;
        }

        this.setState(
            {
                cursorPosition: position,
            },
            () => {
                if (position) {
                    this.props.onCellEvent(CellEvent.Selection, position);
                }

                this.scrollToCell(position);
            },
        );
    }

    private scrollToCell(position: CellPosition) {
        const { lines, columns, leftFixedColumns, rightFixedColumns } = this.props;
        const { visibleColumnsIndexes, visibleLinesIndexes, columnWidths, lineHeights } = this.state;

        if (position == null) {
            return;
        }

        const viewportSize = this.getViewportSize();

        let scrollX = this.tableBody.scrollLeft;
        let scrollY = this.tableBody.scrollTop;

        const cellIndexX = this.getIndexOfColumn(position.columnName);
        const cellIndexY = this.getIndexOfLine(position.lineId);

        const cellBordersViewportLeft = cellIndexX <= lodash.first(visibleColumnsIndexes);
        const cellBordersViewportRight = cellIndexX >= lodash.last(visibleColumnsIndexes);
        const cellBordersViewportTop = cellIndexY <= lodash.first(visibleLinesIndexes);
        const cellBordersViewportBottom = cellIndexY >= lodash.last(visibleLinesIndexes);

        const cellIsFixedLeft = leftFixedColumns.includes(position.columnName);
        const cellIsFixedRight = rightFixedColumns.includes(position.columnName);

        if (cellBordersViewportLeft && !cellIsFixedLeft && !cellIsFixedRight) {
            scrollX = cellIndexX > 0 ? getItemPosition<ColumnName>(cellIndexX - 1, columns, columnWidths) : 0;
        }

        if (cellBordersViewportTop) {
            scrollY = cellIndexY > 0 ? getItemPosition<LineId>(cellIndexY - 1, lines, lineHeights) : 0;
        }

        if (cellBordersViewportRight && !cellIsFixedLeft && !cellIsFixedRight) {
            scrollX =
                cellIndexX < columns.length - 1
                    ? getItemPosition<ColumnName>(cellIndexX + 2, columns, columnWidths) - viewportSize.width
                    : this.getColumnsSumWidth(columns) - viewportSize.width;
        }

        if (cellBordersViewportBottom) {
            scrollY =
                cellIndexY < lines.length - 1
                    ? getItemPosition<LineId>(cellIndexY + 2, lines, lineHeights) - viewportSize.height
                    : this.getSumHeight() - viewportSize.height;
        }

        this.tableBody.scrollTo(scrollY, scrollX);
    }

    private getIndexOfColumn(columnName: ColumnName): number {
        return this.props.columns.indexOf(columnName);
    }

    private getIndexOfLine(lineId: LineId): number {
        return this.props.lines.indexOf(lineId);
    }

    private emitTableEvent(eventType: TableEvent) {
        if (!this.tableEventsSubscribers[eventType]) {
            return;
        }

        this.tableEventsSubscribers[eventType].forEach((handler) => {
            handler(eventType);
        });
    }
}

function getItemPosition<T extends number | string>(index: number, items: T[], itemsSize: Record<T, number>): number {
    return lodash.range(0, index).reduce((acc, item) => {
        const itemName = items[item];

        return acc + itemsSize[itemName] + CELLS_SPACING;
    }, 0);
}
