import { MRMClient } from 'rpc-client';
import { bindActionCreators } from 'redux';
import * as lodash from 'lodash';

import type { RoleLine, Role } from '../../../../api';

import { store } from '../../../../store';
import { loadPageData } from '../../../../store/rolesPage/actions';
import { getPageData } from '../../../../store/rolesPage/selectors';
import { MrmClient, OrganizationApi } from '../../../../api';

interface StoreProps {
    roleLines: RoleLine[];
}

export class Loader {
    private static instance: Loader;
    private client: MRMClient<'budgets' | 'organization' | 'rbac'>;

    private dispatch = bindActionCreators(
        {
            loadPageData,
        },
        store.dispatch,
    );

    public static getInstance(): Loader {
        if (!Loader.instance) {
            Loader.instance = new Loader();
        }
        return Loader.instance;
    }

    public async init(organisationId: string) {
        this.client = await MrmClient.getInstance();

        await Promise.all([this.loadOrganization(organisationId), this.loadRoleLines(), this.loadActions()]);
    }

    private async loadOrganization(organisationId: string) {
        const organization = await OrganizationApi.getOrganization(organisationId);

        this.dispatch.loadPageData({ organization });
    }

    public async loadRoleLines(): Promise<void> {
        const roleLines = await this.client.domain.rbac.listRole();

        const sortedLines = lodash.sortBy(roleLines, (item) => item.model.id);

        this.dispatch.loadPageData({ roleLines: sortedLines });
    }

    public async loadActions(): Promise<void> {
        const actions = await this.client.api.rbac.listAction();

        const filteredActions = actions.filter((item) => !item.parentId);

        this.dispatch.loadPageData({ actions: filteredActions });
    }

    public subscribeRoleLinesChanges(handler: (data: Partial<Role>) => void): void {
        const { roleLines } = this.getStoreProps();

        roleLines.forEach((line) => {
            line.onEvent('updated', handler);
        });
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        const { roleLines } = getPageData(storeState);

        return {
            roleLines,
        };
    }
}
