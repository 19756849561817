import { actionCreatorFactory } from 'typescript-fsa';
import { EventsRecordsListQuery } from 'sber-marketing-types/system-admin';

import { RoutePayload, RouteAuthorizationErrorPagePayload } from '../types';

const factory = actionCreatorFactory('ROUTING');

export const routeAuthorizationErrorPage = factory<RouteAuthorizationErrorPagePayload | null | undefined>(
    'ROUTE_AUTHORIZATION_ERROR_PAGE',
);
export const routeReportPage = factory('ROUTE_REPORT_PAGE');
export const routeOrganizationsPage = factory('ROUTE_ORGANIZATIONS_PAGE');
export const routeRolesPage = factory<{ organizationId: string }>('ROUTE_ROLES_PAGE');
export const routeConfigurationPage = factory('ROUTE_CONFIGURATION_PAGE');
export const routeJournalPage = factory<RoutePayload<EventsRecordsListQuery>>('ROUTE_JOURNAL_PAGE');

/** Set "isAuthChecking" value (for internal usage only)  */
export const setIsAuthChecking = factory<boolean>('SET_IS_AUTH_CHECKING');

/** Set "isSessionExpired" value (for internal usage only) */
export const setIsSessionExpired = factory<boolean>('SET_IS_SESSION_EXPIRED');

/** Set "isSessionWatching" value (for internal usage only) */
export const setIsSessionWatching = factory<boolean>('SET_IS_SESSION_WATCHING');
