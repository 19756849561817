import * as React from 'react';
import * as classnames from 'classnames';
import Link, { To } from 'redux-first-router-link';

import * as styles from './NavigationTabs.scss';

import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';
import { CurrentPage, routeConfigurationPage, routeJournalPage, routeOrganizationsPage } from '../../../store/routing';

interface Props {
    className?: string;
    currentPage: CurrentPage;
    logout(): void;
}

export const NavigationTabsTemplate: React.FunctionComponent<Props> = ({ className, currentPage, logout }: Props) => (
    <div className={classnames(styles.root, className)}>
        <NavigationTab isSelected={currentPage === CurrentPage.ORGANIZATIONS} action={routeOrganizationsPage()}>
            Организации
        </NavigationTab>

        <NavigationTab isSelected={currentPage === CurrentPage.CONFIGURATION} action={routeConfigurationPage()}>
            Конфигурирование
        </NavigationTab>

        <NavigationTab isSelected={currentPage === CurrentPage.JOURNAL} action={routeJournalPage({})}>
            Журнал аудита
        </NavigationTab>

        {/* <NavigationTab isSelected={currentPage === CurrentPage.REPORT} action={routeReportPage()}>
            Отчеты
        </NavigationTab> */}

        <div className={styles.logoutButton}>
            <Button theme={ButtonTheme.MainRounded} onClick={logout}>
                Выход
            </Button>
        </div>
    </div>
);

interface NavigationTabProps {
    isSelected: boolean;
    action: To;
    children?: React.ReactNode;
}

const NavigationTab: React.FunctionComponent<NavigationTabProps> = ({
    isSelected,
    action,
    children,
}: NavigationTabProps) => (
    <Link to={action} className={classnames(styles.item, isSelected && styles.item_selected)}>
        {children}
        <div className={styles.itemUnderline} />
    </Link>
);
