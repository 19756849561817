import * as React from 'react';
import { SelectItem, LabeledSelect } from 'sber-marketing-ui';

import { AVAILABLE_PAGE_LENGTHS } from '../../../store/journalFilters';
import { PageSizeSelectTemplateProps } from './types';

const ITEMS: SelectItem[] = AVAILABLE_PAGE_LENGTHS.map((value) => ({
    value,
    label: `${value} записей`,
}));

/** "PageSizeSelect" component */
export const PageSizeSelectTemplate: React.ComponentType<PageSizeSelectTemplateProps> = ({
    className,
    value,
    onChange,
}: PageSizeSelectTemplateProps): JSX.Element => (
    <div className={className}>
        <LabeledSelect title="Показывать" selectedValue={value} items={ITEMS} onSelection={onChange} />
    </div>
);
