import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { Organization } from 'sber-marketing-types/system-admin';
import { isNil, pick } from 'lodash';

import { State } from '../../../store/types';
import { getOrganizationById } from '../../../store/filterData';
import { OrganizationInfoCommonProps, MappedState } from './types';
import { OrganizationInfoTemplate } from './OrganizationInfoTemplate';

export const mapStateToProps = (state: State, { id }: OrganizationInfoCommonProps): MappedState => {
    const organization: Organization | undefined = getOrganizationById(state, id);
    const result: MappedState = {
        found: !isNil(organization),
    };
    if (!isNil(organization)) {
        Object.assign(result, pick(organization, ['name', 'users', 'activities']));
    }
    return result;
};

const withStore = connect(mapStateToProps);

/** "OrganizationInfo" store component */
export const OrganizationInfoStore: ComponentType<OrganizationInfoCommonProps> = withStore(OrganizationInfoTemplate);
