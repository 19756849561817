// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-layers-view-common-Link-_Link__root{display:inline-block;min-height:26px;text-decoration:none;font-size:8px;font-weight:600}\n", "",{"version":3,"sources":["webpack://./src/client/layers/view/common/Link/Link.scss"],"names":[],"mappings":"AAAA,gDACI,oBAAqB,CAErB,eAAgB,CAEhB,oBAAqB,CAErB,aAAc,CACd,eAAgB","sourcesContent":[".root {\n    display: inline-block;\n\n    min-height: 26px;\n\n    text-decoration: none;\n\n    font-size: 8px;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-layers-view-common-Link-_Link__root"
};
export default ___CSS_LOADER_EXPORT___;
