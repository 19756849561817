import { combineReducers } from 'redux';
import { Success } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { stubTrue, stubFalse, isNil, get } from 'lodash';
import { EventRecord, EventsRecordsListQuery } from 'sber-marketing-types/system-admin';

import { JournalState, LoadJournalPageSuccess } from './types';
import { loadJournalPage } from './actions';
import { routeJournalPage, RoutePayload } from '../routing';
import { DEFAULT_PAGE } from './constants';
import {
    skipReducer,
    asyncActionErrorNameReducerFactory,
    asyncActionErrorMessageReducerFactory,
} from '../commonReducers';

const page = reducerWithInitialState<number>(DEFAULT_PAGE)
    .case(routeJournalPage, (state: number, payload: RoutePayload<EventsRecordsListQuery>) =>
        isNil(get(payload, 'query.page')) ? DEFAULT_PAGE : get(payload, 'query.page'),
    )
    .case(loadJournalPage.started, skipReducer)
    .case(loadJournalPage.failed, skipReducer)
    .case(loadJournalPage.done, (state: number, { result: { page } }: Success<number, LoadJournalPageSuccess>) => page);

const countPages = reducerWithInitialState<number>(1)
    .case(loadJournalPage.started, skipReducer)
    .case(loadJournalPage.failed, skipReducer)
    .case(
        loadJournalPage.done,
        (state: number, { result: { countPages } }: Success<number, LoadJournalPageSuccess>) => countPages,
    );

const records = reducerWithInitialState<EventRecord[]>([])
    .case(loadJournalPage.started, skipReducer)
    .case(loadJournalPage.failed, skipReducer)
    .case(
        loadJournalPage.done,
        (state: EventRecord[], { result: { records } }: Success<number, LoadJournalPageSuccess>) => records,
    );

const isLoading = reducerWithInitialState<boolean>(true)
    .case(loadJournalPage.started, stubTrue)
    .case(loadJournalPage.failed, stubFalse)
    .case(loadJournalPage.done, stubFalse);

const errorMessage = asyncActionErrorMessageReducerFactory(loadJournalPage);

const errorName = asyncActionErrorNameReducerFactory(loadJournalPage);

export const journal = combineReducers<JournalState>({
    page,
    countPages,
    records,
    isLoading,
    errorMessage,
    errorName,
});
