import * as React from 'react';

import * as styles from './OrganizationsPage.scss';

import { OrganizationParams } from 'sber-marketing-types/admin';

import { OpacityTransition, ResponsiveLayout, CentralContent, CardGrid, Popup } from 'sber-marketing-ui';
import { OrganizationCard } from './OrganizationCard';
import { CreateOrganizationForm, EditOrganizationForm } from './OrganizationForm';
import { AdminDashboardPage } from '../../common/AdminDashboardPage';

interface Props {
    organizations: OrganizationParams[];
    isLoading: boolean;
    isFormOpen: boolean;
    currentId?: string;
    onNewOrganizationClick: () => void;
    onCardClick: (id: string) => void;
    onDeleteClick: (id: string) => void;
    onCloseFormClick: () => void;
}

export const OrganizationsPage: React.StatelessComponent<Props> = ({
    organizations,
    isLoading,
    isFormOpen,
    currentId,
    onNewOrganizationClick,
    onCardClick,
    onDeleteClick,
    onCloseFormClick,
}: Props): JSX.Element => {
    const button = renderNewOrganizationButton(onNewOrganizationClick);
    const cards = organizations.map((item) => renderOrganizationCard(item, onCardClick, onDeleteClick));

    return (
        <AdminDashboardPage>
            <OpacityTransition start={!isLoading}>
                <ResponsiveLayout>
                    <CentralContent>
                        <div className={styles.root}>
                            <CardGrid
                                cards={[button, ...cards]}
                                cardStyle={{
                                    width: 240,
                                    height: 102,
                                    marginHorizontal: 60,
                                    marginVertical: 80,
                                }}
                            />
                        </div>
                    </CentralContent>
                </ResponsiveLayout>

                {isFormOpen && (
                    <Popup onOutOfContentClick={onCloseFormClick}>
                        {currentId ? <EditOrganizationForm /> : <CreateOrganizationForm />}
                    </Popup>
                )}
            </OpacityTransition>
        </AdminDashboardPage>
    );
};

function renderNewOrganizationButton(onButtonClick: () => void): JSX.Element {
    return (
        <div className={styles.button} onClick={onButtonClick} key={'new organization button'}>
            Создать <br /> новую компанию
        </div>
    );
}

function renderOrganizationCard(
    organization: OrganizationParams,
    onCardClick: (id: string) => void,
    onDeleteClick: (id: string) => void,
): JSX.Element {
    const { id, name, userCount, activityCount } = organization;

    return (
        <div key={id} className={styles.card} onClick={() => onCardClick(id)}>
            <OrganizationCard
                name={name}
                userCount={userCount}
                activityCount={activityCount}
                onEditClick={() => onCardClick(id)}
                onDeleteClick={() => onDeleteClick(id)}
            />
        </div>
    );
}
