export class Utils {
    /**
     * Titles should be in subjective, genitive and accusative case,
     * example: ['арбуз', 'арбуза', 'арбузов']
     */
    public static getDeclensionByNumber(value: number, titles: string[]) {
        /* tslint:disable:no-magic-numbers */
        const cases = [2, 0, 1, 1, 1, 2];

        const titleIndex = value % 100 > 4 && value % 100 < 20 ? 2 : cases[value % 10 < 5 ? value % 10 : 5];
        /* tslint:enable:no-magic-numbers */

        return titles[titleIndex];
    }
}
