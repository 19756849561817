import * as React from 'react';
import Link from 'redux-first-router-link';

import * as style from './RolesButton.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { routeRolesPage } from '../../../../../../store/routing/actions';

interface Props {
    organizationId: string;
}

export function RolesButton({ organizationId }: Props): JSX.Element {
    return (
        <Link to={routeRolesPage({ organizationId })} className={style.root}>
            <div className={style.icon}>
                <Icon type={IconType.GEAR16} />
            </div>
            Настроить пользовательские роли
        </Link>
    );
}
