import * as PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { Component, createElement } from 'react';

import { MultipleSelectItemContainerProps as Props, MultipleSelectItemContainerState as State } from './types';
import { MultipleSelectItemTemplate } from './MultipleSelectItemTemplate';

/** "MultipleSelectItem" container component */
export class MultipleSelectItemContainer extends Component<Props, State> {
    /** Name to display at React debug tools */
    public static displayName = 'MultipleSelectItem';
    /** Properties types (to runtime checking) */
    public static propTypes: PropTypes.ValidationMap<Props> = {
        className: PropTypes.string,
        value: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        onRemoveClick: PropTypes.func.isRequired,
    };
    /** Component defines as not hovered by default */
    public state: State = {
        isHovered: false,
    };
    /** Rendering method */
    public render(): JSX.Element {
        const { className, name } = this.props;
        const { isHovered } = this.state;
        return createElement(MultipleSelectItemTemplate, {
            className,
            name,
            isHovered,
            onRemoveClick: this.onRemoveClick,
            onMouseOver: this.onMouseOver,
            onMouseLeave: this.onMouseLeave,
        });
    }
    /** Remove click handler */
    @autobind
    protected onRemoveClick(): void {
        const { value, name, onRemoveClick } = this.props;
        onRemoveClick({ value, name });
    }
    /** "mouseover" event handler */
    @autobind
    protected onMouseOver(): void {
        this.setState(() => ({ isHovered: true }));
    }
    /** "mouseleave" event handler */
    @autobind
    protected onMouseLeave(): void {
        this.setState(() => ({ isHovered: false }));
    }
}
