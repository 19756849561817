import * as baseActions from './baseActions';
import { CurrentPage } from '../types';

/** Map of page types */
export const pageTypes = {
    [baseActions.routeAuthorizationErrorPage.type]: CurrentPage.AUTHORIZATION_ERROR,
    [baseActions.routeReportPage.type]: CurrentPage.REPORT,
    [baseActions.routeConfigurationPage.type]: CurrentPage.CONFIGURATION,
    [baseActions.routeOrganizationsPage.type]: CurrentPage.ORGANIZATIONS,
    [baseActions.routeRolesPage.type]: CurrentPage.ROLES,
    [baseActions.routeJournalPage.type]: CurrentPage.JOURNAL,
};
