import * as React from 'react';
import * as classnames from 'classnames';
import * as objectHash from 'object-hash';
import autobind from 'autobind-decorator';
import { SuggestHOCProps, LabeledInput, SuggestHOCPropsKeys } from 'sber-marketing-ui';
import { isNil, omit } from 'lodash';

import * as styles from './SimpleSuggest.scss';
import { SimpleSuggestItem } from '../SimpleSuggestItem';

/** "SimpleSuggest" base properties */
export interface SimpleSuggestBaseProps<I, P extends {}> {
    /** Additonal CSS class for root element */
    rootClassName?: string;
    /** Additional CSS class for item element */
    itemClassName?: string;
    /** Custon title for suggest input */
    title?: string;
    /** Custom message to show when no items found by inputed value */
    notFoundMessage?: string;
    /** Item render property */
    renderItem(item: I, outerProps?: P): React.ReactNode;
    /** Assign new value from input to selected item */
    onValueChangeAssign(value: string, selectedValue: I, outerProps?: P): I;
    /** Default selected value (when selected value not received yet) */
    defaultSelectedValue(outerProps?: P): I;
    /** Transform selected value to string for input component */
    selectedValueToInputString?(selectedValue: I, outerProps?: P): string;
    /** Returns unique identifier for item (optional, use items hash by default) */
    getItemIdentifier?(item: I, outerProps?: P): React.Key;
}

/** Keys of "SimpleSuggestBaseProps<I>" (for omitting) */
const SimpleSuggestBasePropsKeys: (keyof SimpleSuggestBaseProps<any, any>)[] = [
    'rootClassName',
    'itemClassName',
    'title',
    'notFoundMessage',
    'renderItem',
    'onValueChangeAssign',
    'defaultSelectedValue',
    'selectedValueToInputString',
    'getItemIdentifier',
];

/** "SimpleSuggest" properties */
export type SimpleSuggestProps<I, P extends {}> = P & SuggestHOCProps<I> & SimpleSuggestBaseProps<I, P>;

/** "SimpleSuggest" component */
export class SimpleSuggest<I, P extends {} = {}> extends React.Component<SimpleSuggestProps<I, P>> {
    /** Default input title */
    private static DEFAULT_TITLE = 'Найти...';
    /** Default not found message */
    private static DEFAULT_NOT_FOUND_MESSAGE = 'Ничего не найдено';
    /** @override */
    public render() {
        return (
            <div className={classnames(styles.root, this.props.rootClassName)}>
                {this.renderInput()}
                {this.renderItemsContainer()}
            </div>
        );
    }
    /** Render input element */
    private renderInput(): JSX.Element {
        return (
            <div className={styles.inputWrap}>
                <LabeledInput
                    title={this.getInputTitle()}
                    value={this.selectedValueToInputString()}
                    onMouseDown={this.props.onSuggestInputMouseDown}
                    onMouseUp={this.props.onSuggestInputMouseUp}
                    onFocus={this.props.onSuggestInputFocus}
                    onBlur={this.props.onSuggestInputBlur}
                    onKeyUp={this.props.onSuggestInputKeyUp}
                    onValueChange={this.onValueChange}
                />
            </div>
        );
    }
    /** Render items container */
    private renderItemsContainer(): JSX.Element {
        return (
            <div
                tabIndex={-1}
                className={classnames(styles.itemsContainer, !this.props.showSuggest && styles.itemsContainer_hidden)}
                onMouseDown={this.props.onSuggestItemsContainerMouseDown}
                onMouseUp={this.props.onSuggestItemsContainerMouseUp}
                onFocus={this.props.onSuggestItemsContainerFocus}
                onBlur={this.props.onSuggestItemsContainerBlur}
            >
                {this.props.filteredItems.length > 0 ? (
                    this.props.filteredItems.map((item) => (
                        <SimpleSuggestItem
                            key={this.getItemIdentifier(item)}
                            className={this.props.itemClassName}
                            item={item}
                            renderItem={this.renderItem}
                            onSuggestItemSelect={this.props.onSuggestItemSelect}
                        />
                    ))
                ) : (
                    <div className={styles.notFound}>{this.getNotFoundMessage()}</div>
                )}
            </div>
        );
    }
    /** Render item */
    @autobind
    private renderItem(item: I) {
        return this.props.renderItem(item, this.getExternalProps());
    }
    /** Change value handler */
    @autobind
    private onValueChange(value: string) {
        const { onValueChange, onValueChangeAssign } = this.props;
        const selectedValue: I = this.getSelectedValue();
        onValueChange(onValueChangeAssign(value, selectedValue, this.getExternalProps()));
    }
    /** Get labeled input title */
    private getInputTitle(): string {
        return this.props.title || SimpleSuggest.DEFAULT_TITLE;
    }
    /** Get not found message */
    private getNotFoundMessage(): string {
        return this.props.notFoundMessage || SimpleSuggest.DEFAULT_NOT_FOUND_MESSAGE;
    }
    /** Map selected value to input string */
    private selectedValueToInputString(): string {
        const { selectedValueToInputString } = this.props;
        const selectedValue: I = this.getSelectedValue();
        const outerProps: P = this.getExternalProps();
        return isNil(selectedValueToInputString)
            ? String(selectedValue)
            : selectedValueToInputString(selectedValue, outerProps);
    }
    /** Get items unique identifier */
    private getItemIdentifier(item: I): React.Key {
        const { getItemIdentifier } = this.props;
        return isNil(getItemIdentifier) ? objectHash(item) : getItemIdentifier(item, this.getExternalProps());
    }
    /** Get selected value safely */
    private getSelectedValue(): I {
        const { selectedValue, defaultSelectedValue } = this.props;
        return isNil(selectedValue) ? defaultSelectedValue(this.getExternalProps()) : selectedValue;
    }
    /**  */
    private getExternalProps(): P {
        const excludedProps = [...SuggestHOCPropsKeys, ...SimpleSuggestBasePropsKeys, 'children'];
        return omit<P>(this.props as any, excludedProps);
    }
}
