import * as React from 'react';
import * as classnames from 'classnames';
import { Checkbox_redesign } from 'sber-marketing-ui';

import * as styles from './EventTypesMultiSelect.scss';
import { EventTypesMultiSelectTemplateProps } from './types';

/** "EventTypesMultiSelect" template component */
export const EventTypesMultiSelectTemplate: React.StatelessComponent<EventTypesMultiSelectTemplateProps> = ({
    className,
    items,
}: EventTypesMultiSelectTemplateProps) => (
    <div className={classnames(styles.root, className)}>
        {items.map(({ id, props }) => (
            <div className={styles.checkbox} key={id}>
                <Checkbox_redesign {...props} />
            </div>
        ))}
    </div>
);
