import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { To } from 'redux-first-router-link';

import { State } from '../../../store/types';
import { getCountPages, getPage, DEFAULT_PAGE, loadJournalPage } from '../../../store/journal';
import { getEventsRecordsListQuery } from '../../../store/journalFilters';
import { routeJournalPage } from '../../../store/routing';
import { JournalPaginationTemplateProps, JournalPaginationStoreProps, MappedState, MappedActions } from './types';
import { JournalPaginationTemplate } from './JournalPaginationTemplate';

const mapStateToProps = (state: State): MappedState => {
    const currentPage = getPage(state);
    const countPages = getCountPages(state);
    const {} = getEventsRecordsListQuery(state, currentPage);
    const first: To | null =
        currentPage > DEFAULT_PAGE ? routeJournalPage({ query: getEventsRecordsListQuery(state, DEFAULT_PAGE) }) : null;
    const previous: To | null =
        currentPage > DEFAULT_PAGE
            ? routeJournalPage({ query: getEventsRecordsListQuery(state, currentPage - 1) })
            : null;
    const last: To | null =
        currentPage < countPages ? routeJournalPage({ query: getEventsRecordsListQuery(state, countPages) }) : null;
    const next: To | null =
        currentPage < countPages
            ? routeJournalPage({ query: getEventsRecordsListQuery(state, currentPage + 1) })
            : null;
    return {
        currentPage,
        countPages,
        first,
        previous,
        last,
        next,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch<State>): MappedActions => ({
    loadJournalPage: (pageNumber: number) => dispatch(loadJournalPage(pageNumber)),
});

export const mergeProps = (
    { currentPage, countPages, ...restState }: MappedState,
    { loadJournalPage }: MappedActions,
    props: JournalPaginationStoreProps,
): JournalPaginationTemplateProps => ({
    ...restState,
    ...props,
    countPages,
    currentPage,
    loadFirstJournalPage: () => loadJournalPage(DEFAULT_PAGE),
    loadPreviousJournalPage: () => loadJournalPage(currentPage - 1),
    loadNextJournalPage: () => loadJournalPage(currentPage + 1),
    loadLastJournalPage: () => loadJournalPage(countPages),
});

const withStore = connect(mapStateToProps, mapDispatchToProps, mergeProps);

/** "JournalPagination" store component */
export const JournalPaginationStore: ComponentType<JournalPaginationStoreProps> = withStore(JournalPaginationTemplate);
