import * as React from 'react';
import * as moment from 'moment';
import * as classnames from 'classnames';
import { isNil } from 'lodash';

import * as styles from './JournalEventLine.scss';
import { UserInfo } from '../../common/UserInfo';
import { UserLogin } from '../../common/UserLogin';
import { EVENT_TYPE_DISPLAY_NAMES } from '../../../store/journal';
import { JournalEventLineTemplateProps } from './types';

/** "JournalEventLine" template component */
export const JournalEventLineTemplate: React.StatelessComponent<JournalEventLineTemplateProps> = ({
    type,
    authorId,
    ip,
    time,
    data,
    isExpanded,
    onToggleButtonClick,
}: JournalEventLineTemplateProps) => (
    <tr className={styles.root} onClick={onToggleButtonClick}>
        <td className={classnames(styles.cell, styles.timestamp)}>
            {moment(time).format(isExpanded ? 'DD.MM.YYYY hh:mm:ss' : 'DD.MM.YYYY')}
        </td>
        <td className={classnames(styles.cell)}>
            {isNil(authorId) ? '-' : isExpanded ? <UserInfo id={authorId} /> : <UserLogin id={authorId} />}
        </td>
        <td className={classnames(styles.cell)}>{ip}</td>
        <td className={classnames(styles.cell)}>{EVENT_TYPE_DISPLAY_NAMES[type]}</td>
        <td className={classnames(styles.cell)}>
            <pre>{isExpanded ? JSON.stringify(data, null, '  ') : '{...}'}</pre>
        </td>
    </tr>
);
