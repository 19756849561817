import * as React from 'react';
import { LabeledSelect, SelectItem } from 'sber-marketing-ui';
import { TransitPasswordLifetime } from 'sber-marketing-types/system-admin';

import type { TransitPasswordLifetimeSelectTemplateProps as Props } from './types';

const TITLE = 'Время действия транзитного пароля';

const ITEMS: SelectItem[] = [
    {
        value: TransitPasswordLifetime.ONE_HOUR,
        label: 'Один час',
    },
    {
        value: TransitPasswordLifetime.THREE_HOURS,
        label: 'Три часа',
    },
    {
        value: TransitPasswordLifetime.FIVE_HOURS,
        label: 'Пять часов',
    },
];

/** "TransitPasswordLifetimeSelect" template component */
export const TransitPasswordLifetimeSelectTemplate: React.StatelessComponent<Props> = ({
    className,
    transitPasswordLifetime,
    editTransitPasswordLifetime,
}: Props): JSX.Element => (
    <div className={className}>
        <LabeledSelect
            title={TITLE}
            selectedValue={transitPasswordLifetime}
            items={ITEMS}
            onSelection={editTransitPasswordLifetime}
        />
    </div>
);
