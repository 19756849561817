import { create, Client } from '@sbermarketing/mrm-metacom-client';
import type {
    Domain,
    Budget,
    BudgetAccess,
    BudgetAccessGroup,
    BudgetParams as BudgetCreateParams,
    BudgetAccessParams as BudgetAccessCreateParams,
    BudgetAccessEvents,
    BudgetEvents,
    Organization,
    Department,
    Role,
    RoleEvents,
    Action,
    Dictionary,
    DictionaryType,
    CreateRoleParams,
} from '@sbermarketing/mrm-metacom-client';

export type BudgetStatus = 'plan' | 'execution' | 'archive_plan' | 'archive_execution';

export type BudgetAccessLine = Domain<BudgetAccess, BudgetAccessEvents>;
export type BudgetLine = Domain<Budget, BudgetEvents>;
export type RoleLine = Domain<Role, RoleEvents>;

export class MrmClient {
    private static client: Promise<Client<'budgets' | 'organization' | 'rbac'>>;

    public static async getInstance() {
        if (!this.client) {
            this.client = MrmClient.init();
        }

        return await this.client;
    }

    private static async init() {
        return await create({
            url: MRM_CLIENT_URL,
            app: 'system-admin',
            modules: ['budgets', 'organization', 'rbac'],
            debug: true,
            dictionary: true,
        });
    }
}

export type {
    Budget,
    BudgetAccess,
    BudgetAccessGroup,
    BudgetCreateParams,
    BudgetAccessCreateParams,
    Organization,
    Department,
    Role,
    Action,
    CreateRoleParams,
    DictionaryType,
    Dictionary,
};
