import { isNumber, isNaN, uniq, compact } from 'lodash';
import { AxiosError } from 'axios';

import { State } from './types';

export const getFullState = (state: State) => state;

export const getNumberId = (state: State, id: number): number => id;

export const getNumberIds = (state: State, ids: number[]): number[] =>
    uniq(ids.filter((id) => isNumber(id) && !isNaN(id)));

export const getStringId = (state: State, id: string): string => id;

export const getStringIds = (state: State, ids: string[]): string[] => uniq(compact(ids));

export const isAxiosError = (error: Error): error is AxiosError => typeof (<AxiosError>error).config === 'object';
